/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";
import style from "../../styles/RouteStyles/signup.module.scss";
// import { countries } from "../../utils/CountriesJson";
import dialingcodes from "../../utils/JSON/dialing-codes.json";
import * as authActions from "../../redux/actions/authActions";
import * as messageActions from "../../redux/actions/messageActions";
import eyeOpen from "../../assets/icons/eye-open.png";
import eyeClose from "../../assets/icons/eye-close.png";
import {
  country,
  emailValidation as EmailValidation,
  NameValidation,
  phoneNoValidation,
  passwordValidation,
  Terms,
  dialingCode,
} from "../../utils/validation";
import Button from "../Button";
import { useSelector } from "react-redux";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import { FormRowCheckbox } from "../Form-row";

// import * as Yup from "yup";

export default function PersonalInfo({ handleNextBtn, ...props }) {
  // form submit handler

  const notification = useSelector((state) => state.message);
  const isLoading = useSelector((state) => state.auth.isLoading);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;


  const [values, setValues] = React.useState(props.state);
  const [countryData, setCountryData] = React.useState({
    name: "Nigeria",
    code: "NG",
    callingCode: "234",
  });
  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});

  const navigate = useNavigate();

  const handleCountry = (e) => {
    const data = e.target.value;
    setCountryData(JSON.parse(data));
  };

  const state = values.password;

  const validate = {
    firstName: (fname) => NameValidation("firstName", fname),
    lastName: (lname) => NameValidation("lastName", lname),
    email: EmailValidation,
    country: country,
    dialing_code: dialingCode,
    password: passwordValidation,
    phone_no: phoneNoValidation,
    terms: Terms,
  };

  const handleChange = (evt) => {
    notification.error && props.dispatch(messageActions.clearErrorMessage());

    const { name, value: newValue, type, checked } = evt.target;

    // keep number fields as numbers
    const value =
      type === "number" ? +newValue : type === "checkbox" ? checked : newValue;

    const { [name]: removedError, ...rest } = errors;

    const error = validate[name](value, state);

    // save field values
    setValues({
      ...values,
      [name]: value,
    });

    setErrors({ ...rest, ...(error && { [name]: touched[name] && error }) });

    // was the field modified
    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    // validate the form

    const formValidation = Object.keys(values).reduce(
      (acc, key) => {
        const newError = validate[key](values[key], values.password);
        const newTouched = { [key]: true };
        return {
          errors: {
            ...acc.errors,
            ...(newError && { [key]: newError }),
          },
          touched: {
            ...acc.touched,
            ...newTouched,
          },
        };
      },
      {
        errors: { ...errors },
        touched: { ...touched },
      }
    );

    setErrors(formValidation.errors);
    setTouched(formValidation.touched);
    if (
      !Object.values(formValidation.errors).length && // errors object is empty
      Object.values(formValidation.touched).length ===
        Object.values(values).length && // all fields were touched
      Object.values(formValidation.touched).every((t) => t === true) // every touched field is true
    ) {
      const number = `${countryData.callingCode} ${values.phone_no}`;
      const formData = new FormData();
      formData.append("phone_number", number);
      formData.append("email", values.email);
      formData.append("password", values.password);
      formData.append("country", countryData.name);
      formData.append("software_developer", "no");
      formData.append("source", "mygift");
      formData.append(
        "contact_person",
        `${values.firstName} ${values.lastName}`
      );
      formData.append("accepted_terms", values.terms && "yes");

      props.dispatch(authActions.addUser(formData));
    }
  };

  const handleBlur = (evt) => {
    const { name, value } = evt.target;

    // remove whatever error was there previously
    const { [name]: removedError, ...rest } = errors;

    // check for a new error
    const error = validate[name](value, state);

    // // validate the field if the value has been touched
    setErrors({
      ...rest,
      ...(error && { [name]: touched[name] && error }),
    });
  };

  React.useEffect(() => {
    if (notification?.success) {
      navigate("/verify-account");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  React.useEffect(() => {
    return () => {
      props.dispatch(messageActions.clearErrorMessage());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultCountry = { name: "Nigeria", code: "NG", callingCode: "234" };
  const goToLLoginPage = () => {
    props.dispatch(messageActions.clearErrorMessage());
    navigate("/login");
  };

  return (
    <div className={style["container"]}>
      <div className={style["signup"]}>
        <h3>Welcome to MyGift</h3>

        <p>Let’s get you started</p>

        <ul className="signup__errors">
          {notification.error
            ? Object.values(notification.msg.error).map((item, index) => {
                return <li key={index}>{item}</li>;
              })
            : ""}
        </ul>

        <form>
          <div className={style["form--wrapper"]}>
            <div className={style["form__container--row"]}>
              <div>
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  placeholder="e.g Peter"
                  onFocus={(e) => props.handleFocused(e)}
                  onBlur={handleBlur}
                />

                {touched.firstName && errors.firstName && (
                  <div className="error">{errors.firstName}</div>
                )}
              </div>

              <div>
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  placeholder="e.g Pan"
                  onChange={handleChange}
                  onFocus={(e) => props.handleFocused(e)}
                  onBlur={handleBlur}
                />
                {touched.lastName && errors.lastName && (
                  <div className="error">{errors.lastName}</div>
                )}
              </div>
            </div>

            <div className={style["form__container--col"]}>
              <div>
                <label htmlFor="email">Email Address</label>
                <div>
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    placeholder="name@example.com"
                    onFocus={(e) => props.handleFocused(e)}
                    onBlur={handleBlur}
                  />

                  {touched.email && errors.email && (
                    <div className="error">{errors.email}</div>
                  )}
                </div>
              </div>
              <div>
                <label htmlFor="Phone Number">Phone Number</label>
                <div className={style["form__container--custom"]}>
                  <select
                    id=""
                    onChange={handleCountry}
                    defaultValue={JSON.stringify(defaultCountry)}
                  >
                    {dialingcodes.map((code, index) => {
                      return (
                        <option key={index} value={JSON.stringify(code)}>
                          +{code.callingCode}
                        </option>
                      );
                    })}
                  </select>
                  <input
                    type="tel"
                    name="phone_no"
                    onChange={handleChange}
                    onFocus={(e) => props.handleFocused(e)}
                    onBlur={handleBlur}
                    maxLength="10"
                  />
                </div>
                {touched.phone_no && errors.phone_no && (
                  <div className="error">{errors.phone_no}</div>
                )}{" "}
              </div>
            </div>
            <div className={style["form__container--col"]}>
              <div>
                <label htmlFor="Create Password">Password</label>
                <div className={style["form__container--custom"]}>
                  <input
                    type={
                      props.showPassword.id === "password" ? "text" : "password"
                    }
                    name="password"
                    onChange={handleChange}
                    onFocus={(e) => props.handleFocused(e)}
                    placeholder="min. of 8 characters"
                    onBlur={handleBlur}
                    style={{ height: "50px" }}
                  />

                  <span id="password" onClick={(e) => props.handlePassword(e)}>
                    {props.showPassword.id === "password" ? (
                      <img id="password" src={eyeOpen} alt="eyeOpen" />
                    ) : (
                      <img id="password" src={eyeClose} alt="eyeClose" />
                    )}
                  </span>
                </div>
                {touched.password && errors.password && (
                  <div className="error">{errors.password}</div>
                )}{" "}
              </div>
            </div>

            <hr />
            <FormRowCheckbox>
              <input
                type="checkbox"
                onChange={handleChange}
                name="terms"
                id=""
              />
              <div className={style["terms"]}>
                I have read and agreed to the{" "}
                <a href="/privacy-policy">Privacy Policy</a> and
                <a href="/terms-of-service"> Terms and Conditions</a>
              </div>
            </FormRowCheckbox>
            {touched.terms && errors.terms && (
              <div className="error">{errors.terms}</div>
            )}
          </div>
          <div className={style["bottom"]}>
            <Button
              onClick={(e) => handleSubmit(e)}
              className={
                !touched.terms ||
                !touched.firstName ||
                !touched.lastName ||
                !touched.email ||
                !touched.phone_no ||
                !touched.password
                  ? "faint"
                  : "filled"
              }
              type="submit"
              disabled={
                isLoading ||
                !touched.terms ||
                !touched.firstName ||
                !touched.lastName ||
                !touched.email ||
                !touched.phone_no ||
                !touched.password
              }
            >
              {!isLoading ? (
                "Create my account"
              ) : (
                <ScaleLoader
                  css={override}
                  size={80}
                  color={"#fff"}
                  loading={true}
                />
              )}{" "}
            </Button>
            <div className={style["signup__container--links"]}>
              <p>
                Already have an account?
                <span onClick={() => goToLLoginPage()}> Log in</span>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
