import * as types from '../actions/actionTypes';
import * as tokenConfig from './../../utils/tokenConfig'


const initialState = {
    token: tokenConfig.getToken(),
    isAuth: true,
    isLoading: false,
    data: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case types.UPDATE_USER_START:
            return {
                ...state,
                isLoading: true,
            };

        case types.UPDATE_USER_SUCCESFUL:
            return {
                ...state,
                isAuth: true,
                isLoading: false,
            }

        case types.UPDATE_USER_FAIL:
            return {

                isAuth: true,
                isLoading: false,
            }

        case types.PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                data: action.data,
                isLoading: false,
            }

        case types.PASSWORD_RESET_FAIL:

            return {
                ...state,
                isLoading: false,
            }


        default:
            return state;
    }
}