import React from "react";
import style from "./index.module.scss";

const Gifts = () => {
  return (
    <div className={style["gifts--wrapper"]}>
      <div className={style["gifts--container"]}>
        <h3>Gifts you can unlock</h3>

        <div className={style["gift--lists"]}>
          {gifts.map((item, index) => (
            <div
              className={
                index === 1
                  ? style["gift--lists__item-two"]
                  : style["gift--lists__item"]
              }
              key={index}
            >
              <p> {item}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gifts;

const gifts = ["Airtime", "Data", "Charity Donations", "Money"];
