import React from "react";
import mic from "../../../assets/landingpage-icons/mic.svg";
import speaker from "../../../assets/landingpage-icons/speaker.svg";
import wave from "../../../assets/landingpage-icons/wave.svg";
import users from "../../../assets/landingpage-icons/users.svg";
import message from "../../../assets/landingpage-icons/outbox-icon.svg";
import repeat from "../../../assets/landingpage-icons/repeat.svg";
import phonebook from "../../../assets/landingpage-icons/phonebook.svg";
import schedule from "../../../assets/landingpage-icons/schedule.svg";
import donate from "../../../assets/landingpage-icons/donate.svg";
import money from "../../../assets/landingpage-icons/money.svg";
import style from "./index.module.scss";

export const WhyMyGift = () => {
  const lists = [
    {
      icon: mic,
      text: "Record your messages in your own voice right from the app",
    },
    {
      icon: speaker,
      text: "Upload a pre recorded audio message",
    },
    {
      icon: wave,
      text: "Text-to-speech messages to your loved ones",
    },
    {
      icon: users,
      text: "Gift multiple people at the same time",
    },
    {
      icon: message,
      text: "Customised voice messages to go along with the gift",
    },
    {
      icon: repeat,
      text: "Intelligent retries to ensure the receiver gets the call and gift",
    },
    {
      icon: phonebook,
      text: "Phonebook to store family and friend contacts",
    },
    {
      icon: schedule,
      text: "Schedule gifts to be sent later",
    },
    {
      icon: donate,
      text: "Donate to charity organizations from the comfort of your home",
    },
    {
      icon: money,
      text: "Send money gifts to friends and family",
    },
  ];

  const [itemsToShow, setItemsToShow] = React.useState(5);
  const showMore = () => {
    setItemsToShow(lists.length);
  };

  const showLess = () => {
    setItemsToShow(5);
    window.location.href = "#more";
  };

  return (
    <div className={style["why--container"]}>
      <h3>Why MyGift?</h3>
      <div className={style["mobile"]}>
        {lists.slice(0, itemsToShow).map((item, index) => (
          <div className={style["why-box"]} key={index}>
            <div className={style["why-item"]}>
              <img src={item.icon} alt="mic-icon" />
              <p>{item.text}</p>
            </div>
          </div>
        ))}

        <p
          className={style["show--more"]}
          onClick={itemsToShow === 5 ? showMore : showLess}
        >
          {itemsToShow === 5 ? "View More" : "View Less"}
        </p>
      </div>

      {/* for desktop */}

      <div className={style["why--lists"]}>
        {lists.map((item, index) => (
          <div className={style["why-box"]} key={index}>
            <div className={style["why-item"]}>
              <img src={item.icon} alt="mic-icon" />
              <p>{item.text}</p>
            </div>
          </div>
        ))}

        <p
          id="more"
          className={style["show--more"]}
          onClick={itemsToShow === 5 ? showMore : showLess}
        >
          {itemsToShow === 5 ? "View More" : "View Less"}
        </p>
      </div>
    </div>
  );
};
