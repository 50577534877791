import React from "react";
import style from "./index.module.scss";
import MessageLayout from "../../components/Layout/MessageLayout";
import globe from "../../assets/icons/world-globe.svg";
import search from "../../assets/icons/search.svg";
import successIcon from "../../assets/icons/success.svg";
import Button from "../../components/Button";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorMessage } from "../../redux/actions/messageActions";

import editIcon from "../../assets/icons/edit.svg";
import { FormRowGroup } from "../../components/Form-row";
import { ClipLoader, ScaleLoader } from "react-spinners";
import { css } from "@emotion/react";
import axios from "axios";
import AuthLayout from "../../components/Layout/AuthLayout";
import { fetchCharity } from "../../redux/actions/charityActions";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import { sendGift } from "../../redux/actions/sendGiftActions";

const Charity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = React.useState(1);
  const userInfo = useSelector((state) => state.auth.authState);
  const notification = useSelector((state) => state.message);
  const charity = useSelector((state) => state.charity.data);
  const isLoading = useSelector((state) => state.charity.isLoading);
  React.useEffect(() => {
    const data = {
      client_id: userInfo?.id,
      token: userInfo?.token,
    };
    dispatch(fetchCharity(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [senderInfo, setSenderInfo] = React.useState({
    phone: userInfo?.phone_number,
    name: userInfo?.contact_person,
  });
  const [selectedCharity, setSelectedCharity] = React.useState(null);

  const handleNextButton = () => {
    setStep(step + 1);
  };

  const handleBackButton = () => {
    dispatch(clearErrorMessage());
    if (step === 1) {
      navigate(-1);
      return;
    }
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { value, name } = e.target;

    setSenderInfo({
      ...senderInfo,
      [name]: value,
    });
  };

  const handleAnonymousCheck = (e) => {
    const checked = e.target.checked;

    if (checked) {
      setSenderInfo((prev) => ({
        ...prev,
        name: "Anonymous",
      }));

      return;
    }
    setSenderInfo((prev) => ({
      ...prev,
      name: userInfo.contact_person,
    }));
  };

  const initialValues = {
    name: "",
    gift_amount: "",
    message_text: "",
    sender_name: "",
    sender_phone: "",
    charity_id: "",
    token: "",
    client_id: "",
  };
  const [searchField, setSearchField] = React.useState("");
  const filteredCharities = charity?.filter((charity) => {
    return charity.name.toLowerCase().includes(searchField.toLowerCase());
  });
  const handleSearch = (e) => {
    setSearchField(e.target.value);
  };
  const handleMessageCount = (value) => {
    const { message_text } = value;
    let chars = message_text?.length,
      messages = Math.ceil(chars / 160),
      remaining = messages * 160 - (chars % (messages * 160) || messages * 160);

    if (value.message_text?.length < 1) {
      return 160;
    }
    return remaining;
  };

  const sendGiftSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Please fill in a minimum of 3 characters.")
      .required("Required "),
    gift_amount: Yup.number()
      .required("Required")
      .positive("Value cannot be negative")
      .typeError("Amount must be a number")
      // .min(1000, "Minimum amount is N1000")
      .min(50, "Minimum amount is N50")
      .max(50000, "Maximum amount is N50,000"),
  });
  const options = [
    { amount: 50, value: "₦50" },
    { amount: 5000, value: "₦5,000" },
    { amount: 10000, value: "₦10,000" },
    { amount: 20000, value: "₦20,000" },
    { amount: 50000, value: "₦50,000" },
    { amount: 100000, value: "₦100,000" },
  ];
  const [selectedAmount, setSelectedAmount] = React.useState(null);
  return (
    <>
      {notification.id === "SEND_GIFT_SUCCESS" ? (
        <SuccessPage
          amount={`₦${new Intl.NumberFormat().format(selectedAmount)}`}
          organisation={selectedCharity?.name}
        />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={sendGiftSchema}
          onSubmit={(values) => {
            const formData = new FormData();

            formData.append("client_id", userInfo?.id);
            formData.append("token", userInfo?.token);

            formData.append("gift_amount", values.gift_amount);
            formData.append("charity_id", selectedCharity.id);

            formData.append("name", values.name);

            if (values.message_text) {
              formData.append(
                "message_text",
                values.message_text.replace(/\r?\n|\r/g, " ")
              );
            }

            if (senderInfo.name !== "Anonymous") {
              formData.append("sender_name", senderInfo.name);
              formData.append("sender_phone", senderInfo.phone);
            }

            setSelectedAmount(values.gift_amount);
            dispatch(sendGift(formData, "/gift/charity"));
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <MessageLayout
                goBack={handleBackButton}
                bgcolor="#EB2931"
                bgcolorTwo="#EB2931"
                bgcolorThree="#EB2931"
                height={4}
                progress={step >= 2 ? 100 : 50}
                progressTwo={step <= 2 ? 0 : step === 3 ? 50 : 100}
                progressThree={step <= 3 ? 0 : step >= 3 ? 50 : 100}
              >
                <MulitStepForm
                  step={step}
                  values={values}
                  charities={filteredCharities}
                  nextButton={handleNextButton}
                  senderName={senderInfo.name}
                  senderPhone={senderInfo.phone}
                  handleChange={handleChange}
                  handleAnonymousCheck={handleAnonymousCheck}
                  giftType={senderInfo.name}
                  setSelected={setSelectedCharity}
                  selected={selectedCharity}
                  selectCharity={selectedCharity}
                  recipient={selectedCharity?.name}
                  worth={`₦${new Intl.NumberFormat().format(
                    values.gift_amount
                  )}`}
                  costs={
                    values.message_text
                      ? values.gift_amount + 22
                      : values.gift_amount
                  }
                  amount={`₦${new Intl.NumberFormat().format(
                    values.gift_amount
                  )}`}
                  isLoading={isLoading}
                  handleSearch={handleSearch}
                  handleMessageCount={handleMessageCount}
                  options={options}
                  setFieldValue={setFieldValue}
                />
              </MessageLayout>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

const CharityDonation = ({ nextButton, ...props }) => {
  const selectCharity = (charity) => {
    props.setSelected(charity);
  };
  return (
    <div className={style["charity--container"]}>
      <div className={style["charity--container__type"]}>
        <h3>Gift a charity organisation</h3>
        <p>Select from the options below</p>
        <div className={style["search"]}>
          <img src={search} alt="search" />
          <input
            placeholder="Search for an organisation"
            onChange={(e) => props.handleSearch(e)}
          />
        </div>

        {props.isLoading ? (
          <div className={style["loader"]}>
            <ClipLoader size={15} />
            <p>Loading...</p>
          </div>
        ) : (
          <div className={style["charity--list"]}>
            {props.charities?.map((item, index) => (
              <div
                className={style["charity--list__item"]}
                onClick={() => selectCharity(item)}
                key={index}
              >
                <div className={style["details"]}>
                  <div className={style["icon"]}>
                    <img src={item.logo} alt="building" />
                  </div>
                  <p className={style["name"]}>{item.name}</p>
                </div>

                <div
                  className={
                    props.selected?.name === item.name
                      ? style["selected"]
                      : style["radio"]
                  }
                >
                  {props.selected?.name === item.name && (
                    <div className={style["active"]}></div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        {!props.charities && !props.isLoading && (
          <span className="error">Not found</span>
        )}
      </div>

      <div className={style["charity--container__button"]}>
        <Button
          type={"button"}
          className={props.selected?.name ? "filled" : "faint"}
          disabled={!props.selected?.name}
          onClick={nextButton}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

const OrganisationDetails = ({ nextButton, ...props }) => {
  return (
    <div className={style["charity-details--container"]}>
      <h3>{props.selectCharity?.name}</h3>
      <div className={style["location"]}>
        <img src={globe} alt="" />
        <p>{props.selectCharity?.address}</p>
      </div>
      <div className={style["charity-details--container__images"]}>
        {props.selectCharity?.images_array.map((imageUrl, i) => (
          <div className={style["image"]} key={i}>
            <img src={imageUrl} alt={props.selectCharity?.name} />
          </div>
        ))}
      </div>
      <div className={style["charity-details--container__content"]}>
        <p className={style["heading"]}>Description</p>
        <p className={style["text-content"]}>
          {props.selectCharity?.description}
        </p>
      </div>

      <div className={style["charity-details--container__button"]}>
        <Button type={"button"} className={"filled"} onClick={nextButton}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export const Gift = ({ nextButton, setFieldValue, ...props }) => {
  const { errors, values } = useFormikContext();

  return (
    <div className={style["gift--container"]}>
      <div className={style["gift--container__header"]}>
        <h3>How much do you want to gift?</h3>
      </div>

      <div className={style["gift--container__amount"]}>
        <label>Amount</label>
        <div className={style["input"]}>
          <p>NGN</p>
          <Field
            type="number"
            name="gift_amount"
            placeholder="Enter an amount"
          />
        </div>
        <ErrorMessage className="error" name="gift_amount" component="span" />
      </div>
      <p className={style["options"]}>Quick options</p>
      <div className={style["gift--container__items"]}>
        {props.options?.map((option, index) => (
          <div
            name="gift_amount"
            className={
              values.gift_amount === option.amount
                ? style["select-item"]
                : style["item"]
            }
            key={index}
            onClick={() => {
              setFieldValue("gift_amount", option.amount);
            }}
          >
            {option.value}
          </div>
        ))}
      </div>
      <div className={style["gift--container__button"]}>
        <Button
          type="button"
          className={
            values.gift_amount && !errors.gift_amount ? "filled" : "faint"
          }
          disabled={!values.gift_amount || errors.gift_amount}
          onClick={() => nextButton()}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

const Sender = ({
  nextButton,
  name,
  phone,
  handleChange,
  handleAnonymousCheck,
  ...props
}) => {
  const nameRef = React.useRef(null);
  const phoneRef = React.useRef(null);

  const handleEditableValue = (value) => {
    if (value === "name") {
      nameRef.current.focus();
      return;
    }
    phoneRef.current.focus();
  };

  return (
    <div className={style["sender--container"]}>
      <div className={style["sender--container__header"]}>
        <h3>Who is sending this gift?</h3>
        <p>This will show the name the recipient will see</p>
      </div>

      <div className={style["sender--container__infos"]}>
        <FormRowGroup>
          <label>Full Name</label>
          <div className={style["input-group"]}>
            <input
              ref={nameRef}
              type="text"
              value={name}
              name="name"
              onChange={(e) => handleChange(e)}
            />

            <img
              src={editIcon}
              alt=""
              onClick={() => handleEditableValue("name")}
            />
          </div>
        </FormRowGroup>
        {name !== "Anonymous" && (
          <FormRowGroup>
            <label>Phone number</label>
            <div className={style["input-group"]}>
              <input
                ref={phoneRef}
                type="text"
                value={phone}
                name="phone"
                onChange={(e) => handleChange(e)}
              />
              <img
                src={editIcon}
                alt=""
                onClick={() => handleEditableValue("phone")}
              />
            </div>
          </FormRowGroup>
        )}
      </div>
      <div className={style["sender--container__button"]}>
        <div className={style["anonymous"]}>
          <input type="checkbox" onChange={(e) => handleAnonymousCheck(e)} />
          <label htmlFor="anonymous">Send anonymously</label>
        </div>
        <Button className={"filled"} onClick={() => nextButton()}>
          Continue
        </Button>
      </div>
    </div>
  );
};

const MessageContent = ({ nextButton, ...props }) => {
  const { values, errors, dirty } = useFormikContext();

  return (
    <div className={style["sender--container"]}>
      <div className={style["sender--container__header"]}>
        <h3>Send your message</h3>
      </div>

      <div className={style["sender--container__infos"]}>
        <FormRowGroup>
          <label htmlFor="name">Message Title</label>
          <Field name="name" placeholder="e.g Merry Christmas" type="text" />
        </FormRowGroup>
        <FormRowGroup>
          <label htmlFor="message_text">Your Message (Optional)</label>
          <Field
            as="textarea"
            className={style["textarea"]}
            {...props}
            maxLength={160}
            name="message_text"
            placeholder="Your message here"
          />
          <ErrorMessage
            name="message_text"
            className="error"
            component="span"
          />
          <p> {160 - props.handleMessageCount(values)}/ 160 words</p>
        </FormRowGroup>
      </div>
      <div className={style["sender--container__button"]}>
        <Button
          className={!errors.name && dirty ? "filled" : "faint"}
          onClick={() => nextButton()}
          disabled={errors.name || !dirty}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
export const Summary = ({ ...props }) => {
  const userInfo = useSelector((state) => state.auth.authState);
  const notification = useSelector((state) => state.message);
  const isSending = useSelector((state) => state.gifting.isSending);

  const [userData, setUserData] = React.useState(null);

  React.useEffect(() => {
    const getUserInfo = async () => {
      try {
        await axios
          .post("https://crm-api.myserviceagent.net/api/v1/auth/dev/info", {
            client_id: userInfo.id,
            token: userInfo.token,
          })
          .then((res) => {
            setUserData(res.data);
          });
      } catch (error) {
        console.log(error);
      }
    };

    getUserInfo();
  }, [userInfo]);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;
  function truncate(str, n) {
    return str?.length > n ? str.slice(0, n - 1) + "..." : str;
  }
  return (
    <div className={style["summary--container"]}>
      <h3>Gift Summary</h3>

      <div className={style["summary--container__content"]}>
        <div>
          {props.title && (
            <div className={style["summary"]}>
              <p className={style["title"]}>Message Title</p>
              <p className={style["info"]}>{truncate(props.title, 15)}</p>
            </div>
          )}
          <div className={style["summary"]}>
            <p className={style["title"]}>{props.heading ?? "Gifter"}</p>
            <p className={style["info"]}>{props.giftType}</p>
          </div>

          <div className={style["summary"]}>
            <p className={style["title"]}>Worth</p>
            <p className={style["info"]}>{props.worth}</p>
          </div>
        </div>
        <div>
          <div className={style["summary"]}>
            <p className={style["title"]}>Recipient</p>
            <p className={style["info"]}>{props.recipient}</p>
          </div>
          {props.format && (
            <div className={style["summary"]}>
              <p className={style["title"]}>Format</p>
              <p className={style["info"]}>
                {props.format === "tts"
                  ? "Text-to-Speech"
                  : props.format === "upload"
                  ? "Uploaded Message"
                  : "Recorded Message"}
              </p>
            </div>
          )}
          <div className={style["summary"]}>
            <p className={style["title"]}>Cost</p>
            <p className={style["info"]}>{props.costs} units</p>
          </div>
        </div>
      </div>
      <p className={style["available-unit"]}>
        Available Units: {userData?.airtime_balance}
      </p>

      <ul className="signup__errors">
        {notification.error && (
          <li>{Object.values(notification?.msg?.error)}</li>
        )}
      </ul>
      <div className={style["summary--container__button"]}>
        <Button className="filled" type="submit">
          {!isSending ? (
            "Send MyGift"
          ) : (
            <ScaleLoader
              css={override}
              size={80}
              color={"#fff"}
              loading={true}
            />
          )}
        </Button>
      </div>
    </div>
  );
};

export const SuccessPage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigate = () => {
    dispatch(clearErrorMessage());
    navigate("/dashboard");
  };
  return (
    <AuthLayout>
      <div className={style["success--container"]}>
        {" "}
        <div className={style["success--container__icon"]}>
          <img src={successIcon} alt="gift" />
        </div>
        <div className={style["success--container__content"]}>
          <h3>You did it!</h3>
          <p>
            Your {props.type} gift of {props.amount} is on it’s way to{" "}
            {props.organisation}
          </p>
        </div>
        <div className={style["success--container__button"]}>
          <Button className="filled" onClick={() => handleNavigate()}>
            Okay, got it
          </Button>
        </div>
      </div>
    </AuthLayout>
  );
};

const MulitStepForm = ({
  step,
  nextButton,
  senderName,
  senderPhone,
  handleChange,
  handleAnonymousCheck,
  setFieldValue,
  ...props
}) => {
  switch (step) {
    case 1:
      return <CharityDonation nextButton={nextButton} {...props} />;
    case 2:
      return <OrganisationDetails nextButton={nextButton} {...props} />;
    case 3:
      return (
        <Gift
          nextButton={nextButton}
          {...props}
          setFieldValue={setFieldValue}
        />
      );
    case 4:
      return (
        <Sender
          nextButton={nextButton}
          name={senderName}
          phone={senderPhone}
          handleChange={handleChange}
          handleAnonymousCheck={handleAnonymousCheck}
          {...props}
        />
      );
    case 5:
      return <MessageContent {...props} nextButton={nextButton} />;
    case 6:
      return <Summary {...props} />;
    case 7:
      return <SuccessPage {...props} />;
    default:
      <CharityDonation nextButton={nextButton} />;
  }
};
export default Charity;
