/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import AuthLayout from "../../components/Layout/AuthLayout";
import style from "./index.module.scss";
import mailIcon from "../../assets/icons/mail.svg";
import { useNavigate } from "react-router-dom";
const VerifyAccount = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    setTimeout(() => {
      navigate("/login");
    }, 30000);
  }, []);
  return (
    <>
      <AuthLayout>
        <div className={style["verify--container"]}>
          <div className={style["verify--content"]}>
            <div className={style["verify--content__image"]}>
              <img src={mailIcon} alt="mail-icon" />
            </div>
            <div className={style["verify--content__title"]}>
              <h3>Verify your account</h3>
            </div>
            <div className={style["verify--content__text"]}>
              We have sent a link to your email to verify your account.
            </div>
          </div>
          {/* <div className={style["button"]}>
            <Button onClick={() => openEmailApp()}>Open email app</Button>
          </div> */}
        </div>
      </AuthLayout>
    </>
  );
};

export default VerifyAccount;
