import * as types from '../actions/actionTypes';
// import * as tokenConfig from './../../utils/tokenConfig'


const initialState = {
    isLoading: false,
    data: null,
    purchase_history: null,
    purchase_summary:null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case types.MAKE_PAYMENT_START:
        case types.BANK_TRANSFER_UPLOAD_START:
        case types.GET_PURCHASE_HISTORY_START:
        case types.GET_BANK_PAYMENTS_START:
        case types.VERIFY_BANK_PAYMENT_START:
        case types.GET_PURCHASE_SUMMARY_START:
            return {
                ...state,
                isLoading: true,
            };
        case types.MAKE_PAYMENT_SUCCESS:
            return {
                ...state,
                data: action.data,
                isLoading: false,
            }
        case types.GET_BANK_PAYMENTS_SUCCESS:
            return {
                ...state,
                bank_payments: action.data,
                isLoading: false,
            }
        case types.GET_PURCHASE_SUMMARY_SUCCESS:
            return {
                ...state,
                purchase_summary: action.data,
                isLoading: false,
            }
        case types.BANK_TRANSFER_UPLOAD_SUCCESS:
            return {
                ...state,
                bank_payment: action.data,
                isLoading: false,
            }
        case types.VERIFY_BANK_PAYMENT_SUCCESS:
            return {
                ...state,
                data: action.data,
                isLoading: false,
            }
        case types.GET_PURCHASE_HISTORY_SUCCESS:
            return {
                ...state,
                purchase_history: action.data,
                isLoading: false,
            }
        case types.MAKE_PAYMENT_FAIL:
        case types.BANK_TRANSFER_UPLOAD_FAIL:
        case types.GET_PURCHASE_HISTORY_FAIL:
        case types.GET_BANK_PAYMENTS_FAIL:
        case types.VERIFY_BANK_PAYMENT_FAIL:
        case types.GET_PURCHASE_SUMMARY_FAIL:
            return {
                ...state,
                isLoading: false,
            }



        default:
            return state;
    }
}