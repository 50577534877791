/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Layout from "../../components/Layout/DashboardLayout";
import style from "./index.module.scss";
import phonebook from "../../assets/icons/phone-book.svg";
import Button from "../../components/Button";
import rightAngle from "../../assets/icons/rightAngle.svg";
import plus from "../../assets/icons/plus.svg";
import add from "../../assets/icons/add.svg";
import arrowCircleTop from "../../assets/icons/arrowCircleTop.svg";
import close from "../../assets/icons/close.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import { ScaleLoader } from "react-spinners";
import { clearErrorMessage } from "../../redux/actions/messageActions";
import useScrollPagination from "../../utils/useScrollPagination";

const Phonebook = () => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);

  const { data, hasMore, loading, firstLoad } = useScrollPagination(
    "https://crm-api.myserviceagent.net/api/v1/phonebook/dev/client/summary",
    pageNumber
  );

  const observer = React.useRef();

  const lastBookElementRef = React.useCallback(
    (node) => {
      if (loading || firstLoad) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && firstLoad) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const navigate = useNavigate();
  const navigateSinglePhonebook = (id, name) => {
    return navigate(`/phonebook/${id}/${name}`);
  };
  const dispatch = useDispatch();
  const createPhonebook = (type) => {
    dispatch(clearErrorMessage());
    return navigate(`/create-phonebook/${type}`);
  };

  const closeRef = React.useRef();

  React.useEffect(() => {
    const closeOnOutsideClick = (e) => {
      if (
        openDialog &&
        closeRef.current &&
        !closeRef.current.contains(e.target)
      ) {
        setOpenDialog(false);
      }
    };
    document.addEventListener("click", closeOnOutsideClick);

    return () => {
      document.removeEventListener("click", closeOnOutsideClick);
    };
  }, [openDialog]);

  const refreshContent = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 500);
    }).then(setPageNumber(1));
  };

  return (
    <Layout pageTitle={"Phonebook"}>
      <PullToRefresh
        onRefresh={refreshContent}
        refreshingContent={
          <ScaleLoader size={80} color={"#eb2931"} loading={true} />
        }
      >
        {!data && !loading ? (
          <NotFound create={createPhonebook} />
        ) : (
          <div className={style["phonebook--container"]}>
            {
              <>
                <div className={style["phonebook--container__lists"]}>
                  {data &&
                    data?.map((phonebook, index) => {
                      if (data.length === index + 1) {
                        return (
                          <div
                            className={style["list"]}
                            ref={lastBookElementRef}
                            key={index}
                            onClick={() =>
                              navigateSinglePhonebook(
                                phonebook?.id,
                                phonebook?.name
                              )
                            }
                          >
                            <div className={style["right--side"]}>
                              <div className={style["icon"]}>
                                {" "}
                                {phonebook?.name.split(" ")[0][0].toUpperCase()}
                                {phonebook?.name.split(" ")[1]
                                  ? phonebook?.name
                                      .split(" ")[1][0]
                                      .toUpperCase()
                                  : null}
                              </div>
                              <div className={style["details"]}>
                                <p className={style["title"]}>
                                  {phonebook?.name}
                                </p>
                                <p className={style["contacts"]}>
                                  {phonebook?.total} contacts
                                </p>
                              </div>
                            </div>
                            <div>
                              <img src={rightAngle} alt="right-angle" />
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className={style["list"]}
                            key={index}
                            onClick={() =>
                              navigateSinglePhonebook(
                                phonebook?.id,
                                phonebook?.name
                              )
                            }
                          >
                            <div className={style["right--side"]}>
                              <div className={style["icon"]}>
                                {" "}
                                {phonebook?.name.split(" ")[0][0].toUpperCase()}
                                {phonebook?.name.split(" ")[1]
                                  ? phonebook?.name
                                      .split(" ")[1][0]
                                      .toUpperCase()
                                  : null}
                              </div>
                              <div className={style["details"]}>
                                <p className={style["title"]}>
                                  {phonebook?.name}
                                </p>
                                <p className={style["contacts"]}>
                                  {phonebook?.total} contacts
                                </p>
                              </div>
                            </div>
                            <div>
                              <img src={rightAngle} alt="right-angle" />
                            </div>
                          </div>
                        );
                      }
                    })}
                  {loading && (pageNumber === 1 || pageNumber >= 3) && (
                    <div className={style["loader"]}>
                      <ScaleLoader color="red" />
                    </div>
                  )}
                </div>

                {openDialog && (
                  <div className={style["cta--dialog"]}>
                    <Button
                      className="small--ghost"
                      onClick={() => createPhonebook("Create")}
                    >
                      Create
                    </Button>
                    <Button
                      className="small--ghost"
                      onClick={() => createPhonebook("Upload")}
                    >
                      Upload
                    </Button>
                  </div>
                )}

                <div
                  className={style["float--cta"]}
                  onClick={() => setOpenDialog(!openDialog)}
                  ref={closeRef}
                >
                  <img src={openDialog ? close : plus} alt="addd" />
                </div>
              </>
            }
          </div>
        )}
      </PullToRefresh>
    </Layout>
  );
};

const NotFound = ({ create }) => {
  return (
    <div className={style["notfound--container"]}>
      <div className={style["notfound--container__icon"]}>
        <img src={phonebook} alt="phonebook" />
      </div>
      <div className={style["notfound--container__content"]}>
        <h3>You have no contacts yet</h3>
        <p>
          Create or Upload a phonebook from your contact list to see them here.
        </p>
      </div>
      <div className={style["notfound--container__buttons"]}>
        <Button className="medium--filled" onClick={() => create("Create")}>
          <span>
            <img src={add} alt="" style={{ marginTop: "7px" }} />
          </span>{" "}
          <span>Create</span>
        </Button>
        <Button className="small--ghost" onClick={() => create("Upload")}>
          <img src={arrowCircleTop} alt="" /> <span>Upload</span>
        </Button>
      </div>
    </div>
  );
};

export default Phonebook;
