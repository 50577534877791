import * as types from '../actions/actionTypes';
import * as tokenConfig from './../../utils/tokenConfig'


const initialState = {
    token: tokenConfig.getToken(),
    isLoading: false,
    data: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case types.GET_OTP_START:
            return {
                ...state,
                isLoading: true,
            };
        case types.GET_OTP_SUCCESS:
            return {
                ...state,
                data: action.data,
                isLoading: false,
            }
        case types.GET_OTP_FAIL:
            return {
                ...state,
                data: action.data,
                isLoading: false,
            }



        default:
            return state;
    }
}