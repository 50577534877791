import React from "react";
import "./App.css";
import { Route, Routes } from "react-router";
import Login from "./routes/Login";
import Signup from "./routes/Signup";
import ForgotPassword from "./routes/Forgot-Password";
import PasswordReset from "./routes/Reset-Password";
import VerifyAccount from "./routes/Verify-account";
import ProtectedRoute from "./utils/ProtectedRoute";
import Dashboard from "./routes/dashboard";
import Wallet from "./routes/Wallet";
import Settings from "./routes/Settings";
import Phonebook from "./routes/Phonebook";
import SendMessage from "./routes/Send-message";
import VerifyAccountSuccess from "./routes/verify-success";
import TopUpModule from "./routes/Wallet/Top-up";
import ViewPhonebook from "./routes/Phonebook/View-Phonebook";
import CreatePhonebook from "./routes/Phonebook/Create-phonebook";
import ChangePassword from "./routes/Settings/Change-password";
import AddContact from "./routes/Phonebook/Add-contact";
import Notification from "./routes/Notification";
import UpdateProfile from "./routes/Settings/Update-profile";
import Terms from "./routes/Terms";
import PrivacyPolicy from "./routes/Privacy-policy";
import Contact from "./routes/Contact";
import ReactGA from "react-ga";
import LandingPage from "./routes/Landingpage";
import Charity from "./routes/Charity";
import SendGift from "./routes/Send-Gift";
import CashGift from "./routes/Cash-Gift";

function App() {
  ReactGA.initialize("UA-244051217-1", {
    debug: false,
  });
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route index element={<LandingPage />} />
      <Route path="/sign-up" element={<Signup />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<PasswordReset />} />
      <Route path="/verify-account" element={<VerifyAccount />} />
      <Route
        path="/verify-account-success"
        element={<VerifyAccountSuccess />}
      />
      <Route path="/terms-of-service" element={<Terms />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/contact" element={<Contact />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/gift" element={<SendGift />} />
        <Route path="/wallets" element={<Wallet />} />
        <Route path="/wallets/top-up" element={<TopUpModule />} />
        <Route path="settings" element={<Settings />} />
        <Route path="/phonebook" element={<Phonebook />} />
        <Route path="/phonebook/:id/:name" element={<ViewPhonebook />} />
        <Route path="/create-phonebook/:type" element={<CreatePhonebook />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/add-contact/:id" element={<AddContact />} />
        <Route path="/notifications" element={<Notification />} />
        <Route path="/update-profile" element={<UpdateProfile />} />
        <Route path="/donate" element={<Charity />} />
        <Route path="/cash" element={<CashGift />} />
        <Route path="/gift/:giftType" element={<SendMessage />} />
      </Route>
    </Routes>
  );
}

export default App;
