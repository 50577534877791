import * as types from "./actionTypes";
import axios from "../../utils/axios-base";
import { returnErrors, returnSuccess } from "./messageActions";

export const getCharityStart = () => {
  return {
    type: types.GET_CHARITY,
  };
};

export const getCharitySuccess = (data) => {
  return {
    type: types.GET_CHARITY_SUCCESS,
    data,
  };
};

export const getCharityFailed = () => {
  return {
    type: types.GET_CHARITY_FAIL,
  };
};

export const fetchCharity = (data) => (dispatch) => {
  dispatch(getCharityStart());
  axios
    .post(
      `/gift/get-charities
    `,
      data
    )
    .then((res) => {
      dispatch(getCharitySuccess(res.data.data));
      dispatch(returnSuccess(res.data.message, "ok", "charity fetched"));
    })
    .catch((err) => {
      dispatch(getCharityFailed());
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};
