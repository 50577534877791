/* eslint-disable react-hooks/exhaustive-deps */
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import Button from "../../components/Button";
import AuthLayout from "../../components/Layout/AuthLayout";
import ScaleLoader from "react-spinners/ScaleLoader";
import * as authActions from "../../redux/actions/authActions";
import * as messageActions from "../../redux/actions/messageActions";
import { css } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import style from "./index.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { FormRowGroup } from "../../components/Form-row";
import mailIcon from "../../assets/icons/mail.svg";

export default function ForgotPassword() {
  const notification = useSelector((state) => state.message);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const dispatch = useDispatch();

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;

  React.useEffect(() => {
    return () => {
      dispatch(messageActions.clearErrorMessage());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayout>
      {notification.success ? (
        <ResetPasswordLinkSent />
      ) : (
        <FormComponent
          override={override}
          dispatch={dispatch}
          isLoading={isLoading}
          notification={notification}
        />
      )}
    </AuthLayout>
  );
}

const FormComponent = ({ notification, isLoading, override, dispatch }) => {
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .email("Email address is invalid.")
      .required("Email is required."),
  });

  return (
    <div className={style["container"]}>
      <div className={style["forgot--password"]}>
        <h3>Forgot Password</h3>
        <p>
          Enter the email address associated with your account to receive a
          reset link.
        </p>

        <ul className={"signup__errors"}>
          {notification.error &&
            Object.values(notification.msg.error).map((item) => (
              <li>{item}</li>
            ))}
        </ul>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={forgotPasswordSchema}
          onSubmit={({ email }) => {
            const formData = new FormData();
            formData.append("email", email);
            formData.append("source", "mygift");

            dispatch(authActions.forgotPassword(formData));
          }}
        >
          {(formik) => {
            const { errors, touched, isValid, dirty, handleSubmit } = formik;
            return (
              <Form>
                <FormRowGroup>
                  <label htmlFor="email">Email address*</label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter email address"
                    className={
                      errors.email && touched.email ? "input--error" : null
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="span"
                    className="error"
                  />
                </FormRowGroup>

                <div className={style["button"]}>
                  <Button
                    type="submit"
                    className={!(dirty && isValid) ? "faint" : "filled"}
                    disabled={!(dirty && isValid)}
                    onClick={handleSubmit}
                  >
                    {!isLoading ? (
                      "Send Reset Link"
                    ) : (
                      <ScaleLoader
                        css={override}
                        size={80}
                        color="#fff"
                        loading={true}
                      />
                    )}{" "}
                  </Button>
                  <div className={style["bottom--link"]}>
                    <Link to="/login">
                      <p>Back to log in</p>
                    </Link>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const ResetPasswordLinkSent = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    setTimeout(() => {
      navigate("/login");
    }, 30000);
  }, []);
  return (
    <div className={style["verify--container"]}>
      <div className={style["verify--content"]}>
        <div className={style["verify--content__image"]}>
          <img src={mailIcon} alt="mail-icon" />
        </div>
        <div className={style["verify--content__title"]}>
          <h3>Password Reset Link Sent!</h3>
        </div>
        <div className={style["verify--content__text"]}>
          Please check your email for instructions on how you can retrieve your
          password.
        </div>
      </div>
      {/* <div className={style["button"]}>
        <Button onClick={() => openEmailApp()}>Open email app</Button>
      </div> */}
    </div>
  );
};
