import axios from "../../utils/axios-base";
import * as types from './actionTypes'
import * as endpoints from '../../utils/endpoints';
import { returnErrors, returnSuccess } from './messageActions';



//Add business info from sign up page


const makePaymentStart = () => ({
    type: types.MAKE_PAYMENT_START
})

const makePaymentSuccess = (data) => ({
    type: types.MAKE_PAYMENT_SUCCESS,
    data
})

const makePaymentFailed = () => ({
    type: types.MAKE_PAYMENT_FAIL
})



export const makePayment = (formData) => dispatch => {
    dispatch(makePaymentStart())
    const config = {
        headers: {
            "Accept": "application/json, text/plain, */*",
        }
    }
    const endpoint = endpoints.PAYMENTS_URL
    axios
        .post(`${endpoint}/paystack/verify`, formData, config)
        .then(res => {
            dispatch(makePaymentSuccess(res.data))
            dispatch(returnSuccess(res.data.message, 'ok', "PAID"))

        }).catch(err => {
            dispatch(makePaymentFailed())
            dispatch(returnErrors(err.response?.data, err.response?.status))
        });



}
const getPurchaseHistoryStart = () => ({
    type: types.GET_PURCHASE_HISTORY_START
})

const getPurchaseHistorySuccess = (data) => ({
    type: types.GET_PURCHASE_HISTORY_SUCCESS,
    data
})

const getPurchaseHistoryFailed = () => ({
    type: types.GET_PURCHASE_HISTORY_FAIL
})



export const getPurchaseHistory = (formData) => dispatch => {
    dispatch(getPurchaseHistoryStart())
    const config = {
        headers: {
            "Accept": "application/json, text/plain, */*",
        }
    }
    const endpoint = endpoints.PAYMENTS_URL
    axios
        .post(`${endpoint}/history`, formData, config)
        .then(res => {
            dispatch(getPurchaseHistorySuccess(res.data))
            dispatch(returnSuccess(res.data.message))

        }).catch(err => {
            dispatch(getPurchaseHistoryFailed())
            dispatch(returnErrors(err.response?.data, err.response?.status))
        });
}



const bankTransferPaymentStart = () => ({
    type: types.BANK_TRANSFER_UPLOAD_START
})

const bankTransferPaymentSuccess = (data) => ({
    type: types.BANK_TRANSFER_UPLOAD_SUCCESS,
    data
})

const bankTransferPaymentFailed = () => ({
    type: types.BANK_TRANSFER_UPLOAD_FAIL
})



export const bankTransferPayment = (formData) => dispatch => {
    dispatch(bankTransferPaymentStart())
    const config = {
        headers: {
            "Accept": "application/json, text/plain, */*",
        }
    }
    const endpoint = endpoints.PAYMENTS_URL
    axios
        .post(`${endpoint}/bank`, formData, config)
        .then(res => {
            dispatch(bankTransferPaymentSuccess(res.data))
            dispatch(returnSuccess(res.data.message, 'ok', "Transfer"))

        }).catch(err => {
            dispatch(bankTransferPaymentFailed())
            dispatch(returnErrors(err.response?.data, err.response?.status))
        });
}

const getHistoryByDateStart = () => ({
    type: types.GET_PURCHASE_HISTORY_START
})

const getHistoryByDateSuccess = (data) => ({
    type: types.GET_PURCHASE_HISTORY_SUCCESS,
    data
})

const getHistoryByDateFailed = () => ({
    type: types.GET_PURCHASE_HISTORY_FAIL
})



export const getHistoryByDate =     (formData, pageNo) => dispatch => {
    dispatch(getHistoryByDateStart())
    
    const config = {
        headers: {
            "Accept": "application/json, text/plain, */*",
        }
    }
    const endpoint = endpoints.PAYMENTS_URL
    axios
        .post(`${endpoint}/history/bydate`, formData, config)
        .then(res => {
            dispatch(getHistoryByDateSuccess(res.data))
            dispatch(returnSuccess(res.data.message, 'ok', "GET_HISTORY"))

        }).catch(err => {
            dispatch(getHistoryByDateFailed())
            dispatch(returnErrors(err.response?.data, err.response?.status))
        });
}



const getBankPaymentsAdminStart = () => ({
    type: types.GET_BANK_PAYMENTS_START
})

const getBankPaymentsAdminSuccess = (data) => ({
    type: types.GET_BANK_PAYMENTS_SUCCESS,
    data
})

const getBankPaymentsAdminFailed = () => ({
    type: types.GET_BANK_PAYMENTS_FAIL
})



export const getBankPaymentsAdmin = (token, page) => dispatch => {
    dispatch(getBankPaymentsAdminStart())
    const config = {
        headers: {
            "Accept": "application/json, text/plain, */*",
        }
    }

    const endpoint = endpoints.PAYMENTS_URL

    axios

        .get(`${endpoint}/bank`, {
            params: {
                access_token: token,
                page
            }, config
        })
        .then(res => {
            dispatch(getBankPaymentsAdminSuccess(res.data))
            dispatch(returnSuccess(res.data.message))

        }).catch(err => {
            dispatch(getBankPaymentsAdminFailed())
            dispatch(returnErrors(err.response?.data, err.response?.status))
        });
}

const getPurchaseSummaryStart = () => ({
    type: types.GET_PURCHASE_SUMMARY_START
})

const getPurchaseSummarySuccess = (data) => ({
    type: types.GET_PURCHASE_SUMMARY_SUCCESS,
    data
})

const getPurchaseSummaryFailed = () => ({
    type: types.GET_PURCHASE_SUMMARY_FAIL
})



export const getPurchaseSummary = (formData, page) => dispatch => {
    dispatch(getPurchaseSummaryStart())
    const config = {
        headers: {
            "Accept": "application/json, text/plain, */*",
        }
    }

    const endpoint = endpoints.PURCHASE_URL

    axios

        .get(`${endpoint}`, {
            params: {
                access_token: formData,
                page
            }, config
        })
        .then(res => {
            dispatch(getPurchaseSummarySuccess(res.data))
            dispatch(returnSuccess(res.data.message))

        }).catch(err => {
            dispatch(getPurchaseSummaryFailed())
            dispatch(returnErrors(err.response?.data, err.response?.status))
        });
}






export const getPurchaseSummaryCard = (formData, page) => dispatch => {
    dispatch(getPurchaseSummaryStart())
    const config = {
        headers: {
            "Accept": "application/json, text/plain, */*",
        }
    }

    const endpoint = endpoints.PURCHASE_URL

    axios

        .get(`${endpoint}/card`, {
            params: {
                access_token: formData,
                page
            }, config
        })
        .then(res => {
            dispatch(getPurchaseSummarySuccess(res.data))
            dispatch(returnSuccess(res.data.message))

        }).catch(err => {
            dispatch(getPurchaseSummaryFailed())
            dispatch(returnErrors(err.response?.data, err.response?.status))
        });
}

const verifyBankPaymentsStart = () => ({
    type: types.VERIFY_BANK_PAYMENT_START
})

const verifyBankPaymentsSuccess = (data) => ({
    type: types.VERIFY_BANK_PAYMENT_SUCCESS,
    data
})

const verifyBankPaymentsFailed = () => ({
    type: types.VERIFY_BANK_PAYMENT_FAIL
})



export const verifyBankPayments = (formData, id, notification) => dispatch => {
    dispatch(verifyBankPaymentsStart())
    const config = {
        headers: {
            "Accept": "application/json, text/plain, */*",
        }
    }

    const endpoint = endpoints.PAYMENTS_URL

    axios

        .post(`${endpoint}/bank/${id}`, formData, config)
        .then(res => {
            dispatch(verifyBankPaymentsSuccess(res.data))
            dispatch(returnSuccess(res.data.message, "OK", notification))

        }).catch(err => {
            dispatch(verifyBankPaymentsFailed())
            dispatch(returnErrors(err.response?.data, err.response?.status))
        });
}

