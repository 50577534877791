import React from "react";
import style from "./index.module.scss";
import faqImage from "../../../assets/images/faq-image.png";
import giftImage from "../../../assets/images/gift-image.svg";
import minusIcon from "../../../assets/landingpage-icons/minus.svg";
import plusIcon from "../../../assets/landingpage-icons/plus.svg";

const Faqs = () => {
  const [visibility, setVisibility] = React.useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleToggle = (index) => {
    const ansArr = [false, false, false, false, false];
    if (visibility[index]) {
      setVisibility(ansArr);
    } else {
      ansArr[index] = true;
      setVisibility(ansArr);
    }
  };

  return (
    <div className={style["faq--wrapper"]} id="faqs">
      <div className={style["faq--container"]}>
        <div className={style["faq--container__right"]}>
          <img src={faqImage} alt="" className={style["faq--image"]} />
          <img src={giftImage} alt="" className={style["top--icon"]} />
        </div>
        <div className={style["faq--container__left"]}>
          <div className={style["heading"]}>
            <h3>FAQs</h3>
            <p>All your questions answered in one place</p>
          </div>

          <div className={style["faqs"]}>
            {lists.map((item, index) => (
              <div className={style["item"]} key={index}>
                <div
                  className={style["header"]}
                  onClick={() => handleToggle(index)}
                >
                  <p className={style["question"]}>{item.question}</p>
                  <div className={style["icon"]}>
                    {" "}
                    <img
                      src={visibility[index] === true ? minusIcon : plusIcon}
                      alt=""
                    />
                  </div>
                </div>
                {visibility[index] ? (
                  <p
                    className={style["answer"]}
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  ></p>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;

const lists = [
  {
    question: "Who can use Mygift?",
    answer: "Individuals, SMEs, Businesses, Corporate bodies, Startups, etc",
  },
  {
    question: "Can I install Mygift on my phone?",
    answer:
      "Yes, you can but it is not currently available on app store or play store. It is only available on the web.",
  },
  {
    question: "How can I install it on my phone?",
    answer:
      "For IOS, Visit mygift.myserviceagent.net, click the share button, select ''add to home screen'' then select ''add''. <br/>For android, Visit mygift.myserviceagent.net, click on the three dot at the top most of your browser, click on 'install app'' then click ''install'",
  },
  {
    question: "Can I install Mygift on my laptop?",
    answer: " No, It is currently for mobile use only",
  },
  {
    question: "How many people can I gift?",
    answer: "You can gift multiple people at the same time.",
  },
  {
    question:
      "Does the receiver get the gift even when the call is not picked?",
    answer:
      "The receiver does not get the gift and you don't get debited until the user picks. However, the system automatically retries couple of hours later but not after 8pm and resumes again by 9am. Each gift has a maximum of 10 retries.",
  },
];
