import React, { useState } from "react";
import style from "./index.module.scss";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../redux/actions/authActions";
import * as messageActions from "../../redux/actions/messageActions";
import Button from "../../components/Button";
import eyeClose from "../../assets/icons/eye-close.png";
import eyeOpen from "../../assets/icons/eye-open.png";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import AuthLayout from "../../components/Layout/AuthLayout";
import { FormRowGroup } from "../../components/Form-row";

const Login = () => {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.message);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const location = useLocation();
  const historyPath = location.state?.from?.pathname;
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  console.log(historyPath);
  if (auth.isAuth) {
    navigate("/dashboard");
  }

  React.useEffect(() => {
    dispatch(messageActions.clearErrorMessage());

    return () => {
      dispatch(messageActions.clearErrorMessage());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;

  return (
    // <ErrorBoundary>
    <AuthLayout>
      <LoginForm
        dispatch={dispatch}
        notification={notification}
        override={override}
        isLoading={isLoading}
        historyPath={historyPath}
      />
    </AuthLayout>
    // </ErrorBoundary>
  );
};

const LoginForm = ({
  dispatch,
  notification,
  override,
  isLoading,
  historyPath,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const logInSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email is invalid.")
      .required("Email is required."),

    password: Yup.string()
      .required("Password is required.")
      .min(8, "Password too short"),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={style["container"]}>
      <div className={style["form__container"]}>
        <ul className="signup__errors">
          {notification.error && <li>{notification.msg?.error}</li>}
        </ul>
        <h3>Welcome back!</h3>
        <p>Let’s get you started</p>
        <Formik
          initialValues={initialValues}
          validationSchema={logInSchema}
          onSubmit={({ email, password }, actions) => {
            const formData = new FormData();
            formData.append("email", email);
            formData.append("password", password);

            dispatch(authActions.login(formData, historyPath));

            actions.setSubmitting(false);
          }}
        >
          {({ errors, touched, isSubmitting, handleSubmit }) => {
            return (
              <Form>
                <div className={style["form--fields"]}>
                  <FormRowGroup>
                    <label htmlFor="email">Email Address</label>
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter email address"
                      className={
                        errors.email && touched.email ? "input--error" : null
                      }
                    />
                    <ErrorMessage
                      name="email"
                      component="span"
                      className="error"
                    />
                  </FormRowGroup>

                  <FormRowGroup>
                    <label htmlFor="password">Password</label>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder="Enter password"
                      className={
                        errors.password && touched.password
                          ? "input--error"
                          : null
                      }
                    />
                    <span
                      onClick={togglePasswordVisibility}
                      className={style["toggle__password"]}
                    >
                      {showPassword ? (
                        <img src={eyeOpen} alt="eyeOpen" />
                      ) : (
                        <img src={eyeClose} alt="eyeClose" />
                      )}
                    </span>
                    <ErrorMessage
                      name="password"
                      component="span"
                      className="error"
                    />
                  </FormRowGroup>
                  <Link
                    className={style["login__container--forgotpassword"]}
                    to="/forgot-password"
                    state={{ from: historyPath }}
                  >
                    Forgot password?
                  </Link>
                </div>
                <div className={style["button"]}>
                  <Button
                    type="submit"
                    className={
                      errors.password && touched.password ? "faint" : "filled"
                    }
                    disabled={isSubmitting || errors.password}
                    onClick={() => handleSubmit()}
                  >
                    {!isLoading && notification.id !== "Terms" ? (
                      "Log in"
                    ) : (
                      <ScaleLoader
                        css={override}
                        size={80}
                        color={"#fff"}
                        loading={true}
                      />
                    )}{" "}
                  </Button>
                  <div className={style["login__container--links"]}>
                    <p>
                      New to MyGift?
                      <Link to="/sign-up">
                        <span> Sign up</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
