/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import MessageLayout from "../../components/Layout/MessageLayout";
import {
  FormRowGroup,
  FormRowRadio,
  FormRowCheckbox,
} from "../../components/Form-row/index";
import style from "./index.module.scss";
import Button from "../../components/Button";
import TagInput from "../../components/TagInput";
import { Formik, useFormikContext, Form, Field, ErrorMessage } from "formik";
import microphone from "../../assets/icons/microphone.svg";
import upload from "../../assets/icons/upload.svg";
import tts from "../../assets/icons/tts.svg";
import wave from "../../assets/icons/wave.svg";
import gift from "../../assets/icons/gift.svg";
import info from "../../assets/icons/info-solid.svg";
import rightAngle from "../../assets/icons/rightAngle.svg";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { css } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import MicRecorder from "mic-recorder-to-mp3";
import { formatSeconds, formatMinutes } from "../../utils/format-time";
import AudioPlayer from "../../components/Audio-player";
import DatePickerField from "../../components/DatePickerField/index";
import FileUpload from "../../components/File-upload";
import { clearErrorMessage } from "../../redux/actions/messageActions";
import MyLoader from "../../components/Loader";
import AuthLayout from "../../components/Layout/AuthLayout";
import * as giftActions from "../../redux/actions/sendGiftActions";
import { ScaleLoader } from "react-spinners";
import dayjs from "dayjs";
import EmptyState from "../../components/Empty-state";
import useScrollPagination from "../../utils/useScrollPagination";
import ReactGA from "react-ga";
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const SendMessage = () => {
  const { giftType } = useParams();
  const [count, setCount] = React.useState({
    index: 1,
    type: "",
  });
  const [tags, setTags] = React.useState([]);
  const [isRecording, setIsRecording] = React.useState(false);
  const [blobURL, setBlobURL] = React.useState("");
  const [audioFile, setAudioFile] = React.useState(null);
  const [recordingTime, setRecordingTime] = React.useState({
    recordingMinutes: 0,
    recordingSeconds: 0,
  });

  const [selectedGift, setSelectedGift] = useState(null);
  const userInfo = useSelector((state) => state.auth.authState);
  const notification = useSelector((state) => state.message);
  const [phonebookId, setPhoneBookId] = React.useState("");

  const handleMessageCount = (value) => {
    const { message_text } = value;
    let chars = message_text?.length,
      messages = Math.ceil(chars / 160),
      remaining = messages * 160 - (chars % (messages * 160) || messages * 160);

    if (value.message_text?.length < 1) {
      return 160;
    }
    return remaining;
  };

  const navigate = useNavigate();

  const handleNextButton = (type) => {
    setCount({
      ...count,
      index: count.index + 1,
    });
    if (count.index === 3) {
      setCount({
        ...count,
        type: type,
      });
    }

    if (
      count.index === 3 &&
      (count.type === "record" ||
        count.type === "upload" ||
        count.type === "tts")
    ) {
      ReactGA.event({
        category: "Send Gift Module",
        action: `selected${count.type}`,
      });
      setCount({
        ...count,
        index: count.index + 1,
      });
    }
    if (count.index === 4) {
      ReactGA.event({
        category: "Send Gift Module",
        action: `gift type is ${type}`,
      });
      setCount({
        ...count,
        index: count.index + 1,
      });
    }
  };

  const goBack = () => {
    navigate(-1);
  };
  const handleBackButton = () => {
    dispatch(clearErrorMessage());
    setPhoneBookId("");
    setTags([]);
    if (count.index <= 3 && count.type !== "") {
      setCount({
        ...count,
        type: "",
      });

      return;
    }
    setCount({
      ...count,
      index: count.index - 1,
    });
  };

  // Audio recorder starts here

  React.useEffect(() => {
    const MAX_RECORDER_TIME = 5;
    let recordingInterval = null;

    if (isRecording)
      recordingInterval = setInterval(() => {
        setRecordingTime((prevState) => {
          if (
            prevState.recordingMinutes === MAX_RECORDER_TIME &&
            prevState.recordingSeconds === 0
          ) {
            clearInterval(recordingInterval);
            return prevState;
          }

          if (
            prevState.recordingSeconds >= 0 &&
            prevState.recordingSeconds < 59
          )
            return {
              ...prevState,
              recordingSeconds: prevState.recordingSeconds + 1,
            };

          if (prevState.recordingSeconds === 59)
            return {
              ...prevState,
              recordingMinutes: prevState.recordingMinutes + 1,
              recordingSeconds: 0,
            };
        });
      }, 1000);
    else clearInterval(recordingInterval);

    return () => clearInterval(recordingInterval);
  });

  const startRecording = () => {
    navigator?.mediaDevices
      ?.getUserMedia({ audio: true })
      .then(() => {
        Mp3Recorder.start()
          .then(() => {
            setIsRecording(true);
          })
          .catch((e) => console.error(e));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "me-at-thevoice.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
        setAudioFile(file);
        const blobURL = URL.createObjectURL(file);
        setBlobURL(blobURL);
        setIsRecording(false);
      })
      .catch((e) => console.log(e));
  };

  const handlePause = () => {
    // navigator.permissions.query({ name: "microphone" }).then((Permissions) => {
    //   console.log(Permissions);
    // });
    // browser.permissions.remove(microphone).then((PermissionStatus) => {
    //   console.log(PermissionStatus);
    // });
    setRecordingTime({
      recordingMinutes: 0,
      recordingSeconds: 0,
    });
    setBlobURL(null);
    setAudioFile(null);
    setIsRecording(false);
  };
  // Audio recorder ends here

  const dispatch = useDispatch();

  const vcCost =
    recordingTime.recordingMinutes <= 1 && recordingTime.recordingSeconds > 0
      ? 22
      : 44;

  const initialValues = {
    name: "",
    sender_name: "",
    msisdns: "",
    plan_identifier: "",
    gift_type: "",
    message_text: "",
    client_id: "",
    token: "",
    message_audio: "",
    gift_amount: "",
    recipient: "",
    run_time: new Date(),
    schedule: false,
    phonebook: "",
  };

  const sendGiftSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Please fill in a minimum of 3 characters.")
      .required("Required "),
    recipient: Yup.string().required("Required "),
    msisdns: Yup.string().when("recipient", {
      is: "numbers",
      then: () =>
        Yup.string()
          .required("Required")
          .min(11, "Please enter valid 11 digits"),
    }),
    phonebook: Yup.string().when("recipient", {
      is: "phonebook",
      then: () =>
        Yup.string().required("Enter phone numbers separated by comma"),
    }),
  });

  console.log(audioFile);

  return (
    <>
      {notification.id === "SEND_GIFT_SUCCESS" ? (
        <SuccessPage />
      ) : (
        <MessageLayout
          goBack={count.index === 1 ? goBack : handleBackButton}
          bgcolor="#EB2931"
          bgcolorTwo="#EB2931"
          bgcolorThree="#EB2931"
          height={4}
          progress={count.index >= 2 ? 100 : 50}
          progressTwo={count.index <= 2 ? 0 : count.index === 3 ? 50 : 100}
          progressThree={count.index <= 3 ? 0 : count.index >= 3 ? 50 : 100}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={sendGiftSchema}
            onSubmit={(values) => {
              const formData = new FormData();

              formData.append("name", values.name);
              formData.append("gift_type", giftType);

              formData.append("client_id", userInfo?.id);
              formData.append("token", userInfo?.token);

              if (values.recipient === "batch" && phonebookId) {
                formData.append("phonebook_id", phonebookId);
              }

              if (values.recipient === "batch" && !phonebookId) {
                formData.append("msisdns", tags);
              }

              if (values.recipient === "numbers") {
                formData.append("msisdns", values.msisdns);
              }
              if (giftType === "airtime") {
                formData.append("gift_amount", selectedGift?.amount);
              }
              if (giftType === "data") {
                formData.append("plan_identifier", selectedGift?.plan);
              }
              if (count.type === "tts") {
                formData.append(
                  "message_text",
                  values.message_text.replace(/\r?\n|\r/g, " ")
                );
              }
              if (count.type === "upload") {
                formData.append("message_audio", values.message_audio);
              }
              if (count.type === "record") {
                formData.append("message_audio", audioFile);
              }

              if (values.sender_name) {
                formData.append("sender_name", values.sender_name);
              }

              if (values.schedule) {
                formData.append(
                  "run_time",
                  dayjs(values.run_time).format("YYYY-MM-DD HH:mm:DD")
                );
              }
              ReactGA.event({
                category: "Send Gift Module",
                action: `clicked on send MyGift`,
              });

              dispatch(giftActions.sendGift(formData, "/gift"));
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <MultiForm
                  formNum={count}
                  nextButton={handleNextButton}
                  saveRecording={saveRecording}
                  startRecording={startRecording}
                  handlePause={handlePause}
                  isRecording={isRecording}
                  blobURL={blobURL}
                  recordingTime={recordingTime}
                  tagInputs={tags}
                  setTagInputs={setTags}
                  setFieldValue={setFieldValue}
                  values={values}
                  name="message_text"
                  onChange={({ target }) => {
                    setFieldValue("message_text", target.value);
                  }}
                  handleMessageCount={handleMessageCount}
                  gift={giftType}
                  msisdns={values.msisdns || tags[0]}
                  selectedGift={selectedGift}
                  setSelectedGift={setSelectedGift}
                  title={values.name}
                  worth={
                    giftType === "airtime"
                      ? selectedGift?.plan
                      : selectedGift?.plan.split("_")[1]
                  }
                  recipient={
                    values.recipient === "numbers" ? values.msisdns : ""
                  }
                  mulitipleRecipients={values.recipient === "batch" ? tags : []}
                  format={count.type}
                  giftType={giftType}
                  cost={
                    count.type === "record"
                      ? selectedGift?.amount + vcCost
                      : selectedGift?.amount + 22
                  }
                  placeholder="Type something interesting..."
                  setPhoneBookId={setPhoneBookId}
                />
              </Form>
            )}
          </Formik>
        </MessageLayout>
      )}
    </>
  );
};

export const SendGift = ({ nextButton, ...props }) => {
  const { errors, dirty } = useFormikContext();

  return (
    <div className={style["send-gift--container"]}>
      <div className={style["send-gift--container__form"]}>
        <h3>{props.heading ?? "Send Gift"}</h3>
        <FormRowGroup>
          <label htmlFor="name">{props.title ?? "Gift Title"}</label>
          <Field
            placeholder="e.g Happy Birthday"
            type="text"
            name="name"
            required
            minLength="3"
          />
        </FormRowGroup>
        <ErrorMessage component="span" name="name" className="error" />
        <FormRowGroup>
          <label htmlFor="sender_name">
            {props.sender ?? "Preferred Name (Optional)"}
          </label>
          <Field placeholder="e.g Peter Pan" type="text" name="sender_name" />
        </FormRowGroup>
      </div>
      <div className={style["send-gift--container__button"]}>
        <Button
          className={!errors.name && dirty ? "filled" : "faint"}
          onClick={() => nextButton()}
          disabled={errors.name || !dirty}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

const Receipient = ({ nextButton, tagInputs, setTagInputs, ...props }) => {
  const { values, isValid, dirty } = useFormikContext();
  const userInfo = useSelector((state) => state.auth.authState);
  const [openPhonebook, setOpenPhonebook] = React.useState(false);
  const [isPhonebook, setIsPhonebook] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);

  const { data, hasMore, loading } = useScrollPagination(
    "https://crm-api.myserviceagent.net/api/v1/phonebook/dev/client/summary",
    pageNumber
  );

  const observer = React.useRef();

  const lastBookElementRef = React.useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handlePhonebook = () => {
    setOpenPhonebook(true);
    setIsPhonebook(true);
  };

  const getPhonebookDetails = async (id) => {
    props.setPhoneBookId(id);
    try {
      let response = await axios.post(
        `https://crm-api.myserviceagent.net/api/v1/phonebook/dev/client/${id}/items`,
        {
          client_id: userInfo.id,
          token: userInfo.token,
        }
      );

      if (response.status === 200) {
        let contacts = response.data.data;

        for (let index = 0; index < contacts.length; index++) {
          const element = contacts[index];
          setTagInputs((prev) => [...prev, element.msisdn]);
        }
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePhonebookSelection = (id) => {
    getPhonebookDetails(id);
    setOpenPhonebook(false);
  };

  const close = React.useRef();

  React.useEffect(() => {
    const closeOnOutsideClick = (e) => {
      if (openPhonebook && close.current.contains(e.target)) {
        setOpenPhonebook(false);
      }
    };
    document.addEventListener("click", closeOnOutsideClick);

    return () => {
      document.removeEventListener("click", closeOnOutsideClick);
    };
  }, [openPhonebook]);
  return (
    <>
      <div className={style["send-gift--container"]}>
        <div className={style["send-gift--container__form"]}>
          <h3>Send a message</h3>
          <p>Recipient Options</p>
          <FormRowRadio>
            <Field
              type="radio"
              name="recipient"
              value="numbers"
              checked={values.recipient === "numbers"}
              id="numbers"
            />
            <label
              htmlFor="numbers"
              style={
                values.recipient === "numbers"
                  ? { fontWeight: "bold" }
                  : { fontWeight: "normal" }
              }
            >
              Single contact
            </label>
          </FormRowRadio>

          <FormRowRadio>
            <Field
              type="radio"
              name="recipient"
              value="batch"
              checked={values.recipient === "batch"}
              id="batch"
            />
            <label
              htmlFor="batch"
              style={
                values.recipient === "batch"
                  ? { fontWeight: "bold" }
                  : { fontWeight: "normal" }
              }
            >
              Batch contact
            </label>
          </FormRowRadio>
          <ErrorMessage component="span" name="recipient" className="error" />

          {values.recipient === "numbers" && (
            <div className={style["batch --contact"]}>
              <FormRowGroup>
                <label htmlFor="msisdns">Recipient’s Phone Number</label>
                <Field
                  name="msisdns"
                  placeholder="e.g 08102345678"
                  type="tel"
                  maxLength="11"
                  required
                />
                <ErrorMessage
                  component="span"
                  name="msisdns"
                  className="error"
                />
              </FormRowGroup>
            </div>
          )}
          {values.recipient === "batch" && (
            <div className={style["batch--contact"]}>
              <label htmlFor="phonebook">Recipient’s Phone Number</label>
              <TagInput
                tagInputs={tagInputs}
                setTagInputs={setTagInputs}
                inputType="number"
                name="phonebook"
                isPhonebook={isPhonebook}
              />

              <p
                className={style["select--phonebook"]}
                onClick={() => handlePhonebook()}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.8457 18.5852C14.5291 18.5168 15.0594 17.9612 15.1017 17.2757C15.2472 14.9187 15.3247 12.6213 15.3247 9.99988C15.3247 7.37846 15.2472 5.08114 15.1017 2.72414C15.0594 2.03858 14.5291 1.48288 13.8457 1.41447C9.91862 1.02135 6.47756 1.02135 2.55048 1.41447C1.86702 1.48288 1.33676 2.03858 1.29443 2.72414C1.14891 5.08114 1.07153 7.37846 1.07153 9.99988C1.07153 12.6213 1.14891 14.9187 1.29444 17.2757C1.33676 17.9612 1.86702 18.5168 2.55048 18.5852C6.47755 18.9784 9.91862 18.9784 13.8457 18.5852Z"
                    fill="#F4F4F4"
                    stroke="#3B82F6"
                    strokeWidth="1.71429"
                  />
                  <path
                    d="M15.3245 2.87097H17.4196C17.9072 2.87097 18.3773 3.11266 18.5785 3.55674C19.0912 4.68927 19.0152 5.76921 18.5163 7.4345C18.3409 8.02016 17.791 8.40804 17.1797 8.40804H15.3245V2.87097Z"
                    fill="white"
                    stroke="#3B82F6"
                    strokeWidth="1.71429"
                  />
                  <path
                    d="M15.3245 8.40808H17.4196C17.9072 8.40808 18.3773 8.64975 18.5785 9.09385C19.0912 10.2264 19.0152 11.3063 18.5163 12.9716C18.3409 13.5573 17.7912 13.9451 17.1797 13.9451H15.3245V8.40808Z"
                    fill="white"
                    stroke="#3B82F6"
                    strokeWidth="1.71429"
                  />
                  <path
                    d="M10.2499 4.91431H6.14418C5.55245 4.91431 5.07275 5.394 5.07275 5.98574V7.42859C5.07275 8.02033 5.55245 8.50002 6.14418 8.50002H10.2499C10.8416 8.50002 11.3213 8.02033 11.3213 7.42859V5.98574C11.3213 5.394 10.8416 4.91431 10.2499 4.91431Z"
                    fill="white"
                    stroke="#3B82F6"
                    strokeWidth="1.71429"
                  />
                </svg>{" "}
                &nbsp; Select from phonebook
              </p>
            </div>
          )}
        </div>

        <div className={style["send-gift--container__button"]}>
          {values.recipient === "batch" && (
            <ul className={style["batch--info"]}>
              <li>
                <span>
                  {" "}
                  <img src={info} alt="info" />
                </span>
                <span>
                  {" "}
                  The contacts you’re sending data to should be of the
                  <br /> same network provider.
                </span>
              </li>
            </ul>
          )}
          {values.recipient !== "batch" && (
            <Button
              type="button"
              className={dirty && isValid ? "filled" : "faint"}
              onClick={() => nextButton()}
              disabled={!isValid || !dirty}
            >
              Continue
            </Button>
          )}

          {values.recipient === "batch" && (
            <Button
              type="button"
              className={tagInputs.length ? "filled" : "faint"}
              onClick={() => nextButton()}
              disabled={!tagInputs.length}
            >
              Continue
            </Button>
          )}
        </div>
      </div>
      {openPhonebook && (
        <div className={style["phonebook--container"]} ref={close}>
          <div className={style["phonebook--container__list"]}>
            <p className={style["title"]}>Select a Phonebook</p>

            <div style={{ overflowY: "scroll", height: "80%" }}>
              <div className={style["lists"]}>
                {data ? (
                  data.map((phonebook, index) => (
                    <div
                      ref={lastBookElementRef}
                      className={style["item"]}
                      onClick={() => handlePhonebookSelection(phonebook?.id)}
                      key={index}
                    >
                      <div className={style["icon"]}>
                        {phonebook?.name[0]}
                        {phonebook?.name[1]}
                      </div>
                      <div className={style["details"]}>
                        <p className={style["name"]}>{phonebook?.name}</p>
                        <p className={style["total"]}>
                          {phonebook?.total} contacts
                        </p>
                      </div>

                      <div className={style["radio--button"]}></div>
                    </div>
                  ))
                ) : (
                  <EmptyState text={`No phonebook created yet`} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const MessageType = ({ nextButton }) => {
  const messageTypes = [
    {
      text: "Record a message",
      icon: microphone,
      type: "record",
    },
    {
      text: "Upload a message",
      icon: upload,
      type: "upload",
    },
    {
      text: "Text-to-speech",
      icon: tts,
      type: "tts",
    },
  ];

  return (
    <div className={style["message-type--container"]}>
      <div className={style["message-type--container__type"]}>
        <h3>Send your message</h3>
        <p>How would you like to send your message?</p>

        <div className={style["message--types"]}>
          {messageTypes.map((message, index) => (
            <div
              className={style["message--types__item"]}
              key={index}
              onClick={() => nextButton(message.type)}
            >
              <div>
                <div className={style["icon"]}>
                  <img src={message.icon} alt={message.text} />
                </div>
                <p>{message.text}</p>
              </div>

              <div className={style["right--angle"]}>
                <img src={rightAngle} alt="right--angle" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const RecordMessage = ({
  nextButton,
  startRecording,
  saveRecording,
  handlePause,
  recordingTime,
  isRecording,
  blobURL,
}) => {
  return (
    <div className={style["record-message--container"]}>
      <div className={style["record-message--containner__record"]}>
        <h3>Record your message</h3>
        <p>Record the message you want to send</p>
      </div>
      <div className={style["record--section"]}>
        <div className={style["microphone--icon"]}>
          <img src={microphone} alt="microphone-icon" />
        </div>
        <p className={style["rec"]}>REC</p>

        {!blobURL && (
          <p className={style["recording--time"]}>
            {formatMinutes(recordingTime.recordingMinutes)}:
            {formatSeconds(recordingTime.recordingSeconds)} / 05:00
          </p>
        )}
      </div>
      <div
        style={{
          marginTop: "50px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {blobURL && <AudioPlayer audio={blobURL} />}
        {isRecording && (
          <>
            <div
              style={{
                height: "80px",
                display: "grid",
                placeItems: "center",
                width: "378px",
                position: "absolute",
                background: `linear-gradient(to right,rgba(255,255,255,0.3) ${recordingTime.recordingSeconds}px, #f6f7f8 0)`,
              }}
            ></div>
            <img src={wave} alt="wave" />
          </>
        )}
      </div>

      <div className={style["record-message--container__button"]}>
        {isRecording ? (
          <>
            <Button
              type="button"
              className="medium--ghost"
              onClick={() => handlePause()}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="medium--filled"
              disabled={isRecording === 0}
              onClick={saveRecording}
            >
              Done
            </Button>
          </>
        ) : blobURL ? (
          <>
            <Button
              type="button"
              className="medium--ghost"
              onClick={() => handlePause()}
            >
              Rerecord
            </Button>
            <Button
              type="button"
              className="medium--filled"
              onClick={() => nextButton()}
            >
              Continue
            </Button>
          </>
        ) : (
          <div className={style["start--button"]}>
            <Button
              id="start"
              type="button"
              className="filled"
              onClick={() => startRecording()}
            >
              Start Recording
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export const UploadMessage = ({ nextButton, setFieldValue }) => {
  const { values } = useFormikContext();

  return (
    <div className={style["upload-message--section"]}>
      <div className={style["upload-message--section__container"]}>
        <h3>Upload your message</h3>
        <FileUpload
          setFieldValue={setFieldValue}
          values={values}
          name="message_audio"
          accept=".mp3"
          label={"Select an audio file to upload"}
          fileType="Kindly upload as WAV/MP3 file (WAV format preferable)"
        >
          <p>{values.message_audio.name}</p>
        </FileUpload>
      </div>
      <div className={style["upload-message--section__button"]}>
        <Button
          type="button"
          className={!values.message_audio ? "faint" : "filled"}
          disabled={!values.message_audio}
          onClick={() => nextButton()}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export const TTSMessage = ({
  nextButton,
  name,
  placeholder,
  children,
  ...props
}) => {
  const { values } = useFormikContext();

  return (
    <div className={style["tts--section"]}>
      <div className={style["tts--section__container"]}>
        <h3>Type your message</h3>
        <p>Your message will be sent as a voice response</p>
        {/* {errors && <p className="error">{errors.message_text}</p>}
         */}

        <Field
          as="textarea"
          className={style["textarea"]}
          {...props}
          maxLength={160}
          name="message_text"
          placeholder={placeholder}
        />
        <ErrorMessage name="message_text" className="error" component="span" />
        <p> {160 - props.handleMessageCount(values)}/ 160 words</p>
      </div>

      <div className={style["tts--section__button"]}>
        <Button
          type="button"
          className={!values.message_text ? "faint" : "filled"}
          disabled={!values.message_text}
          onClick={() => nextButton()}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

const Gift = ({ nextButton, gift, msisdns, ...props }) => {
  const [dataPlans, setDataPlans] = useState([]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  const airtime = [
    { amount: 50, plan: "₦50" },
    { amount: 100, plan: "₦100" },
    { amount: 200, plan: "₦200" },
    { amount: 500, plan: "₦500" },
    { amount: 1000, plan: "₦1,000" },
    { amount: 1500, plan: "₦1,500" },
    { amount: 2000, plan: "₦2,000" },
    { amount: 2500, plan: "₦2,500" },
    { amount: 5000, plan: "₦5,000" },
  ];

  const userInfo = useSelector((state) => state.auth.authState);
  const fetchDataPlans = async () => {
    setLoader(true);
    try {
      let response = await axios.post(
        `https://crm-api.myserviceagent.net/api/v1/gift/get-plans`,
        {
          client_id: userInfo.id,
          token: userInfo.token,
          msisdn: String(msisdns),
        }
      );
      if (response.status === 200) {
        setLoader(false);
        setDataPlans(response.data.data.plans);
      }
    } catch (error) {
      setLoader(false);
      setError(error.response.data.error);
    }
  };

  useEffect(() => {
    if (gift === "data") {
      fetchDataPlans();
    }
  }, [msisdns]);

  const handleGiftSelection = (gift) => {
    props.setSelectedGift(gift);
  };

  return (
    <div className={style["gift--container"]}>
      <div className={style["gift--container__header"]}>
        <h3>How much worth of {gift}?</h3>
        <p>Select from the options below</p>
      </div>
      <div className={style["gift--container__items"]}>
        {loader ? (
          <MyLoader />
        ) : gift === "data" ? (
          dataPlans.map((plan, index) => (
            <div
              className={
                props.selectedGift?.plan === plan.plan
                  ? style["select-item"]
                  : style["item"]
              }
              key={index}
              onClick={() => handleGiftSelection(plan)}
            >
              {plan.plan.split("_")[1]}
            </div>
          ))
        ) : (
          airtime.map((plan, index) => (
            <div
              className={
                props.selectedGift?.plan === plan.plan
                  ? style["select-item"]
                  : style["item"]
              }
              key={index}
              onClick={() => handleGiftSelection(plan)}
            >
              {plan.plan}
            </div>
          ))
        )}
        {error && !loader && (
          <ul className="signup__errors">
            <li>{error}</li>
          </ul>
        )}
      </div>
      <div className={style["gift--container__button"]}>
        <Button
          disabled={!props.selectedGift}
          className={"filled"}
          onClick={() => nextButton()}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export const MessageSummary = ({ nextButton, ...props }) => {
  const userInfo = useSelector((state) => state.auth.authState);
  const notification = useSelector((state) => state.message);
  const isSending = useSelector((state) => state.gifting.isSending);

  const [userData, setUserData] = React.useState(null);
  console.log(props?.cost);
  React.useEffect(() => {
    const getUserInfo = async () => {
      try {
        await axios
          .post("https://crm-api.myserviceagent.net/api/v1/auth/dev/info", {
            client_id: userInfo.id,
            token: userInfo.token,
          })
          .then((res) => {
            setUserData(res.data);
          });
      } catch (error) {
        console.log(error);
      }
    };

    getUserInfo();
  }, [userInfo]);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;

  const { values } = useFormikContext();

  const filterTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return dayjs(currentDate).add(30, "minute") < dayjs(selectedDate);
  };

  function truncate(str, n) {
    return str?.length > n ? str.slice(0, n - 1) + "..." : str;
  }

  return (
    <div className={style["summary--container"]}>
      <h3>Message Summary</h3>
      <div style={values.schedule ? { height: "320px" } : { height: "420px" }}>
        <div className={style["summary--container__content"]}>
          <div>
            <div className={style["summary"]}>
              <p className={style["title"]}>Message Title</p>
              <p className={style["info"]}>{truncate(props.title, 15)}</p>
            </div>
            <div className={style["summary"]}>
              <p className={style["title"]}>My Gift</p>
              <p className={style["info"]}>{props.giftType}</p>
            </div>
            <div className={style["summary"]}>
              <p className={style["title"]}>Worth</p>
              <p className={style["info"]}>{props.worth}</p>
            </div>
          </div>
          <div>
            <div className={style["summary"]}>
              <p className={style["title"]}>Recipient</p>
              <p className={style["info"]}>{props.recipient}</p>
              {props.mulitipleRecipients?.length > 0 && (
                <p className={style["multiple"]}>
                  <span>
                    {" "}
                    {props.mulitipleRecipients[0]?.length === 13
                      ? `0${props.mulitipleRecipients[0]?.split("234").pop()}`
                      : props.mulitipleRecipients[0]?.split("+234").pop()}
                  </span>
                  {props.mulitipleRecipients?.length > 1 && (
                    <span>+{props.mulitipleRecipients?.length - 1} others</span>
                  )}
                </p>
              )}
            </div>

            <div className={style["summary"]}>
              <p className={style["title"]}>Format</p>
              <p className={style["info"]}>
                {props.format === "tts"
                  ? "Text-to-Speech"
                  : props.format === "upload"
                  ? "Uploaded Message"
                  : "Recorded Message"}
              </p>
            </div>

            <div className={style["summary"]}>
              <p className={style["title"]}>Cost</p>
              <p className={style["info"]}>{props.cost} units</p>
            </div>
          </div>
        </div>
        <p className={style["available-unit"]}>
          Available Units: {userData?.airtime_balance}
        </p>
      </div>
      <ul className="signup__errors">
        {notification.error && (
          <li>{Object.values(notification?.msg?.error)}</li>
        )}
      </ul>
      <div className={style["summary--container__button"]}>
        <div className={style["summary--container__schedule"]}>
          <FormRowCheckbox>
            <Field type="checkbox" name="schedule" id="schedule" />
            <label htmlFor="schedule">Schedule for later</label>
          </FormRowCheckbox>

          {values.schedule && (
            <div className={style["schedule--content"]}>
              <FormRowGroup>
                <label htmlFor="selectdate">Select a date and time</label>
                <ul className={style["requirement--list"]}>
                  <li style={{ paddingLeft: "0.5rem" }}>
                    choose a time <b>at least </b>one hour ahead
                  </li>
                </ul>
                <DatePickerField
                  name="run_time"
                  value={values.run_time}
                  dateFormat="Pp"
                  filterTime={filterTime}
                  minDate={new Date()}
                  showTimeSelect
                  timeIntervals={60}
                />

                <ErrorMessage name="time" component="span" className="error" />
              </FormRowGroup>
            </div>
          )}
        </div>
        <Button className="filled" type="submit">
          {!isSending ? (
            "Send MyGift"
          ) : (
            <ScaleLoader
              css={override}
              size={80}
              color={"#fff"}
              loading={true}
            />
          )}
        </Button>
      </div>
    </div>
  );
};

const MultiForm = ({
  formNum,
  nextButton,
  startRecording,
  saveRecording,
  handlePause,
  recordingTime,
  isRecording,
  blobURL,
  tagInputs,
  setTagInputs,
  setFieldValue,
  values,
  name,
  placeholder,
  children,
  gift,
  msisdns,
  cost,

  ...props
}) => {
  switch (formNum.index) {
    case 1:
      return <SendGift nextButton={nextButton} />;
    case 2:
      return (
        <Receipient
          tagInputs={tagInputs}
          setTagInputs={setTagInputs}
          nextButton={nextButton}
          {...props}
        />
      );
    case 3:
      switch (formNum.type) {
        case "message":
          return <MessageType nextButton={nextButton} />;
        case "record":
          return (
            <RecordMessage
              startRecording={startRecording}
              saveRecording={saveRecording}
              handlePause={handlePause}
              isRecording={isRecording}
              blobURL={blobURL}
              recordingTime={recordingTime}
              nextButton={nextButton}
            />
          );
        case "upload":
          return (
            <UploadMessage
              setFieldValue={setFieldValue}
              values={values}
              nextButton={nextButton}
            />
          );
        case "tts":
          return (
            <TTSMessage
              name={name}
              placeholder={placeholder}
              children={children}
              {...props}
              nextButton={nextButton}
            />
          );
        default:
          return <MessageType nextButton={nextButton} />;
      }
    case 4:
      return (
        <Gift
          gift={gift}
          msisdns={msisdns}
          {...props}
          nextButton={nextButton}
        />
      );
    case 5:
      return <MessageSummary {...props} nextButton={nextButton} cost={cost} />;
    default:
      return <SendGift />;
  }
};

const SuccessPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigate = () => {
    dispatch(clearErrorMessage());
    navigate("/dashboard");
  };
  return (
    <AuthLayout>
      <div className={style["success--container"]}>
        {" "}
        <div className={style["success--container__icon"]}>
          <img src={gift} alt="gift" />
        </div>
        <div className={style["success--container__content"]}>
          <h3>You did it!</h3>
          <p>Your gift is on it’s way to your friend</p>
        </div>
        <div className={style["success--container__button"]}>
          <Button className="filled" onClick={() => handleNavigate()}>
            Go to Dashboard
          </Button>
        </div>
      </div>
    </AuthLayout>
  );
};

export default SendMessage;
