import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import React from "react";
import style from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MessageLayout from "../../components/Layout/MessageLayout";
import { clearErrorMessage } from "../../redux/actions/messageActions";
import { Gift, SuccessPage, Summary } from "../Charity/index";
import ReactGA from "react-ga";
import MicRecorder from "mic-recorder-to-mp3";
import {
  MessageType,
  RecordMessage,
  SendGift,
  TTSMessage,
  UploadMessage,
} from "../Send-message";
import * as Yup from "yup";
import { FormRowCheckbox, FormRowGroup } from "../../components/Form-row";
import { fetchAllBanks } from "../../redux/actions/bankActions";
import Button from "../../components/Button";
import { ClipLoader } from "react-spinners";
import downArrow from "../../assets/icons/arrow.svg";
import axios from "../../utils/axios-base";
import DatePickerField from "../../components/DatePickerField";
import dayjs from "dayjs";
import search from "../../assets/icons/search.svg";
import * as giftActions from "../../redux/actions/sendGiftActions";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const CashGift = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = React.useState({
    count: 1,
    msgType: "",
  });
  const [selectedAmount, setSelectedAmount] = React.useState(null);
  const [isRecording, setIsRecording] = React.useState(false);
  const [blobURL, setBlobURL] = React.useState("");
  const [audioFile, setAudioFile] = React.useState(null);
  const [recordingTime, setRecordingTime] = React.useState({
    recordingMinutes: 0,
    recordingSeconds: 0,
  });

  const handleMessageCount = (value) => {
    const { message_text } = value;
    let chars = message_text?.length,
      messages = Math.ceil(chars / 160),
      remaining = messages * 160 - (chars % (messages * 160) || messages * 160);

    if (value.message_text?.length < 1) {
      return 160;
    }
    return remaining;
  };

  const userInfo = useSelector((state) => state.auth.authState);
  const banks = useSelector((state) => state.bankList.data);
  const [isOpenList, setIsOpenList] = React.useState(false);
  const [isSelected, setIsSelected] = React.useState(null);
  const [isResolving, setIsResolving] = React.useState(false);
  const [resolvedBank, setResolvedBank] = React.useState(null);
  const [errMessage, setErrMessage] = React.useState(null);
  const [accountNumber, setAccoutNumber] = React.useState(null);

  React.useEffect(() => {
    const data = {
      client_id: userInfo?.id,
      token: userInfo?.token,
    };
    dispatch(fetchAllBanks(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBankSelection = (bank) => {
    console.log(bank);
    setIsSelected(bank);
    setIsOpenList(false);
    if (accountNumber) {
      resolveBankAccount({
        client_id: userInfo?.id,
        token: userInfo?.token,
        account_number: accountNumber,
        bank_code: bank.code,
      });
    }
  };

  const resolveBankAccount = async (data) => {
    setIsResolving(true);
    setErrMessage(null);
    setResolvedBank(null);
    try {
      await axios.post(`/gift/resolve-bank-account`, data).then((res) => {
        setIsResolving(false);
        setResolvedBank(res.data.data);
      });
    } catch (error) {
      setIsResolving(false);
      setErrMessage(error.response.data.error);
    }
  };

  const handleAccountNumberInput = (accNumber) => {
    if (accNumber.length === 10 && isSelected) {
      setAccoutNumber(accNumber);
      resolveBankAccount({
        client_id: userInfo?.id,
        token: userInfo?.token,
        account_number: accNumber,
        bank_code: isSelected?.code,
      });
      return;
    }
    setAccoutNumber(accNumber);
  };
  // Audio recorder starts here
  React.useEffect(() => {
    const MAX_RECORDER_TIME = 5;
    let recordingInterval = null;

    if (isRecording)
      recordingInterval = setInterval(() => {
        setRecordingTime((prevState) => {
          if (
            prevState.recordingMinutes === MAX_RECORDER_TIME &&
            prevState.recordingSeconds === 0
          ) {
            clearInterval(recordingInterval);
            return prevState;
          }

          if (
            prevState.recordingSeconds >= 0 &&
            prevState.recordingSeconds < 59
          )
            return {
              ...prevState,
              recordingSeconds: prevState.recordingSeconds + 1,
            };

          if (prevState.recordingSeconds === 59)
            return {
              ...prevState,
              recordingMinutes: prevState.recordingMinutes + 1,
              recordingSeconds: 0,
            };
        });
      }, 1000);
    else clearInterval(recordingInterval);

    return () => clearInterval(recordingInterval);
  });

  const startRecording = () => {
    navigator?.mediaDevices
      ?.getUserMedia({ audio: true })
      .then(() => {
        Mp3Recorder.start()
          .then(() => {
            setIsRecording(true);
          })
          .catch((e) => console.error(e));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "me-at-thevoice.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
        setAudioFile(file);
        const blobURL = URL.createObjectURL(file);
        setBlobURL(blobURL);
        setIsRecording(false);
      })
      .catch((e) => console.log(e));
  };

  const handlePause = () => {
    setRecordingTime({
      recordingMinutes: 0,
      recordingSeconds: 0,
    });
    setBlobURL(null);
    setAudioFile(null);
    setIsRecording(false);
  };

  // Audio recorder ends here

  const handleNextButton = (msgType) => {
    setStep({
      ...step,
      count: step.count + 1,
    });

    if (step.count === 4) {
      setStep({
        ...step,
        msgType: msgType,
      });
    }

    if (
      step.count === 4 &&
      (step.msgType === "record" ||
        step.msgType === "upload" ||
        step.msgType === "tts")
    ) {
      ReactGA.event({
        category: "Send Gift Module",
        action: `selected${step.msgType}`,
      });
      setStep({
        ...step,
        count: step.count + 1,
      });
    }
    if (step.count === 5) {
      setStep({
        ...step,
        count: step.count + 1,
      });
    }
  };

  const handleBackButton = () => {
    dispatch(clearErrorMessage());
    if (step.count === 1) {
      navigate(-1);
    } else if (step.count <= 4 && step.msgType !== "") {
      console.log(step);
      setStep({
        ...step,

        msgType: "",
      });
      return;
    }
    setStep({
      ...step,

      count: step.count - 1,
    });
  };
  const initialValues = {
    name: "",
    msisdns: "",
    message_text: "",
    message_audio: "",
    sender_name: "",
    gift_amount: "",
    gift_type: "",
    bank_code: "",
    account_number: "",
    recipient_name: "",
    client_id: "",
    token: "",
  };

  const sendGiftSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Please fill in a minimum of 3 characters.")
      .required("Required "),
    msisdns: Yup.string()
      .min(11, "Please enter valid 11 digits")
      .required("Required"),
    gift_amount: Yup.number()
      .required("Required")
      .positive("Value cannot be negative")
      .typeError("Amount must be a number")
      .min(500, "Minimum amount is N500")
      .max(50000, "Maximum amount is N50,000"),
  });
  const notification = useSelector((state) => state.message);
  const [searchField, setSearchField] = React.useState("");
  const filteredBanks = banks?.filter((bank) => {
    return bank.name.toLowerCase().includes(searchField.toLowerCase());
  });
  const handleSearch = (e) => {
    setSearchField(e.target.value);
  };

  const options = [
    { amount: 500, value: "₦500" },
    { amount: 1000, value: "₦1,000" },
    { amount: 2000, value: "₦2,000" },
    { amount: 5000, value: "₦5,000" },
    { amount: 10000, value: "₦10,000" },
    { amount: 20000, value: "₦20,000" },
    { amount: 50000, value: "₦50,000" },
  ];
  const vcCost =
    recordingTime.recordingMinutes <= 1 && recordingTime.recordingSeconds > 0
      ? 22
      : 44;
  return (
    <>
      {" "}
      {notification.id === "SEND_GIFT_SUCCESS" ? (
        <SuccessPage
          amount={`₦${new Intl.NumberFormat().format(selectedAmount)}`}
          organisation={resolvedBank?.account_name}
          type="money"
        />
      ) : (
        <MessageLayout goBack={handleBackButton}>
          <Formik
            initialValues={initialValues}
            validationSchema={sendGiftSchema}
            onSubmit={(values) => {
              const formData = new FormData();
              formData.append("name", values.name);
              formData.append("gift_type", "money");
              formData.append("client_id", userInfo?.id);
              formData.append("token", userInfo?.token);
              formData.append("msisdns", values.msisdns);
              formData.append("gift_amount", values.gift_amount);
              formData.append("recipient_name", resolvedBank?.account_name);
              formData.append("account_number", accountNumber);
              formData.append("bank_code", isSelected?.code);
              if (step.msgType === "tts") {
                formData.append(
                  "message_text",
                  values.message_text.replace(/\r?\n|\r/g, " ")
                );
              }
              if (step.msgType === "upload") {
                formData.append("message_audio", values.message_audio);
              }
              if (step.msgType === "record") {
                formData.append("message_audio", audioFile);
              }

              if (values.sender_name) {
                formData.append("sender_name", values.sender_name);
              }

              if (values.schedule) {
                formData.append(
                  "run_time",
                  dayjs(values.run_time).format("YYYY-MM-DD HH:mm:DD")
                );
              }

              setSelectedAmount(values.gift_amount);
              dispatch(giftActions.sendGift(formData, "/gift"));
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <MulitStepForm
                  step={step}
                  nextButton={handleNextButton}
                  saveRecording={saveRecording}
                  startRecording={startRecording}
                  handlePause={handlePause}
                  isRecording={isRecording}
                  blobURL={blobURL}
                  values={values}
                  recordingTime={recordingTime}
                  name="message_text"
                  handleMessageCount={handleMessageCount}
                  onChange={({ target }) => {
                    setFieldValue("message_text", target.value);
                  }}
                  setFieldValue={setFieldValue}
                  worth={`₦${new Intl.NumberFormat().format(
                    values.gift_amount
                  )}`}
                  costs={
                    step.msgType === "record"
                      ? values.gift_amount + vcCost
                      : values.gift_amount + 22
                  }
                  amount={`₦${new Intl.NumberFormat().format(
                    values.gift_amount
                  )}`}
                  banks={filteredBanks}
                  isSelected={isSelected}
                  isOpenList={isOpenList}
                  setIsOpenList={setIsOpenList}
                  handleBankSelection={handleBankSelection}
                  handleAccountNumberInput={handleAccountNumberInput}
                  accountNumber={accountNumber}
                  bankName={isSelected?.name}
                  isResolving={isResolving}
                  accName={resolvedBank?.account_name}
                  error={errMessage}
                  recipient={values.msisdns}
                  giftType="Money"
                  title={values.name}
                  heading="My Gift"
                  format={step.msgType}
                  handleSearch={handleSearch}
                  options={options}
                />
              </Form>
            )}
          </Formik>
        </MessageLayout>
      )}
    </>
  );
};

const BankDetails = ({ nextButton, ...props }) => {
  return (
    <div className={style["bank-details--container"]}>
      <h3>How much do you want to gift?</h3>

      <div className={style["bank-details--container__form"]}>
        <div className={style["bank-list"]}>
          <label>Bank Name</label>
          <div
            className={style["bank-list-header"]}
            onClick={() => props.setIsOpenList(!props.isOpenList)}
          >
            <p>
              {props.isSelected
                ? props.isSelected?.name
                : "Select recipient bank name"}
            </p>
            <img src={downArrow} alt="" />
          </div>

          {props.isOpenList && (
            <>
              <div className={style["search"]}>
                <img src={search} alt="search" />
                <input
                  placeholder="Search for bank"
                  onChange={(e) => props.handleSearch(e)}
                />
              </div>{" "}
              <div className={style["list"]}>
                {props.banks?.map((bank, i) => (
                  <p
                    key={bank?.id}
                    onClick={() => props.handleBankSelection(bank)}
                  >
                    {bank?.name}
                  </p>
                ))}
              </div>
            </>
          )}
          {props.accountNumber && !props.bankName && (
            <p className="error">Please select receiver's bank</p>
          )}
        </div>
        <FormRowGroup>
          <label htmlFor="account_number">Account Number</label>
          <input
            type="tel"
            maxLength={10}
            placeholder="Enter recipient account number"
            value={props.accountNumber}
            onChange={(e) => props.handleAccountNumberInput(e.target.value)}
          />
        </FormRowGroup>

        {props.isResolving && (
          <div className={style["loader"]}>
            <ClipLoader size={15} />
            <p>Loading...</p>
          </div>
        )}
        {props.accountNumber?.length === 10 && <p>{props.accName}</p>}

        {props.error && <p className="error">{props.error}</p>}
      </div>

      <div className={style["bank-details--container__button"]}>
        <Button
          type={"button"}
          className={
            props.accountNumber?.length === 10 && props.accName
              ? "filled"
              : "faint"
          }
          disabled={props.accountNumber?.length !== 10 || !props.accName}
          onClick={nextButton}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
const Recipient = ({ nextButton }) => {
  const { errors, dirty, values } = useFormikContext();

  const filterTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return dayjs(currentDate).add(30, "minute") < dayjs(selectedDate);
  };

  return (
    <div className={style["send-gift--container"]}>
      <h3>Send your message</h3>
      <div className={style["send-gift--container__form"]}>
        <FormRowGroup>
          <label htmlFor="msisdns">Recipient’s Phone Number</label>
          <Field
            name="msisdns"
            placeholder="e.g 08102345678"
            type="tel"
            maxLength="11"
            required
          />
          <ErrorMessage component="span" name="msisdns" className="error" />
        </FormRowGroup>
      </div>

      <div className={style["send-gift--container__button"]}>
        <div className={style["schedule"]}>
          <FormRowCheckbox>
            <Field type="checkbox" name="schedule" id="schedule" />
            <label htmlFor="schedule">Schedule for later</label>
          </FormRowCheckbox>
          {values.schedule && (
            <div className={style["schedule--content"]}>
              <FormRowGroup>
                <label htmlFor="selectdate">Select a date and time</label>
                <ul className={style["requirement--list"]}>
                  <li>
                    choose a time <b>at least </b>one hour ahead
                  </li>
                </ul>
                <DatePickerField
                  name="run_time"
                  value={values.run_time}
                  dateFormat="Pp"
                  filterTime={filterTime}
                  minDate={new Date()}
                  showTimeSelect
                  timeIntervals={60}
                />

                <ErrorMessage name="time" component="span" className="error" />
              </FormRowGroup>
            </div>
          )}{" "}
        </div>
        <Button
          type="button"
          className={dirty && !errors.msisdns ? "filled" : "faint"}
          onClick={() => nextButton()}
          disabled={errors.msisdns || !dirty}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
const MulitStepForm = ({
  step,
  nextButton,
  startRecording,
  saveRecording,
  handlePause,
  recordingTime,
  isRecording,
  blobURL,
  tagInputs,
  setTagInputs,
  setFieldValue,
  values,
  name,
  placeholder,
  children,
  gift,
  cost,
  ...props
}) => {
  switch (step.count) {
    case 1:
      return (
        <Gift
          nextButton={nextButton}
          {...props}
          setFieldValue={setFieldValue}
        />
      );
    case 2:
      return <BankDetails nextButton={nextButton} {...props} />;
    case 3:
      return (
        <SendGift
          nextButton={nextButton}
          {...props}
          heading="What is the title of your message"
          title="Message title"
          sender="Sender name"
        />
      );
    case 4:
      switch (step.msgType) {
        case "message":
          return <MessageType nextButton={nextButton} />;
        case "record":
          return (
            <RecordMessage
              startRecording={startRecording}
              saveRecording={saveRecording}
              handlePause={handlePause}
              isRecording={isRecording}
              blobURL={blobURL}
              recordingTime={recordingTime}
              nextButton={nextButton}
            />
          );
        case "upload":
          return (
            <UploadMessage
              setFieldValue={setFieldValue}
              values={values}
              nextButton={nextButton}
            />
          );
        case "tts":
          return (
            <TTSMessage
              name={name}
              placeholder={placeholder}
              children={children}
              {...props}
              nextButton={nextButton}
            />
          );
        default:
          return <MessageType nextButton={nextButton} />;
      }
    case 5:
      return <Recipient nextButton={nextButton} />;
    case 6:
      return <Summary {...props} />;
    case 7:
      return <SuccessPage {...props} />;

    default:
      return <Gift />;
  }
};

export default CashGift;
