import React from "react";
// import style from "./index.module.scss";
import { passwordValidation } from "../../utils/validation";
import PersonalInfo from "../../components/MultiStep/PersonalInfo";
import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import AuthLayout from "../../components/Layout/AuthLayout";

export default function Signup() {
  const dispatch = useDispatch();

  const [count, setCount] = React.useState({ index: 1 });
  const [formState, setFormState] = React.useState({
    password: "",

    touched: {
      password: false,
    },
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    country: "Nigeria",
    dialing_code: "234",
    phone_no: "",
    password: "",
    terms: "",
  };

  const [focusedState, setFocused] = React.useState({
    phone_no: false,
    email: false,
    password: false,
  });

  const [showPassword, setVisibility] = React.useState({ id: null });

  const navigate = useNavigate();

  const handleNextBtn = () => {
    navigate("/login");
  };
  const handleBackBtn = () => {
    return setCount({ ...count, index: count.index - 1 });
  };

  const handlePasswordVisibility = (e) => {
    let id = e.target.id;
    id === showPassword.id
      ? setVisibility({ ...showPassword, id: "" })
      : setVisibility({ ...showPassword, id });
  };

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleFocused = (e) => {
    setFocused({ [e.target.name]: true });
  };

  const handleBlur = (e) => {
    setFormState({
      ...formState,
      touched: { ...formState.touched, [e.target.name]: true },
    });
  };

  React.useEffect(() => {
    window.addEventListener(
      "beforeunload",
      function (e) {
        // Do something
        e.preventDefault();
      },
      false
    );

    return () => {
      window.removeEventListener(
        "beforeunload",
        function (e) {
          // Do something
          e.preventDefault();
        },
        false
      );
    };
  }, []);

  return (
    <AuthLayout>
      <Multiform
        formNum={1}
        showPassword={showPassword}
        state={initialValues}
        formState={formState}
        handleNextBtn={handleNextBtn}
        handleBackBtn={handleBackBtn}
        handlePassword={handlePasswordVisibility}
        handleChange={handleChange}
        handleFocused={handleFocused}
        handleBlur={handleBlur}
        validate={passwordValidation}
        dispatch={dispatch}
        focusedState={focusedState}
      />
    </AuthLayout>
  );
}

const Multiform = ({ formNum, handleNextBtn, handleBackBtn, ...props }) => {
  switch (formNum.index) {
    case 1:
      return (
        <PersonalInfo
          count={formNum}
          handleNextBtn={handleNextBtn}
          {...props}
        />
      );

    default:
      return (
        <PersonalInfo
          count={formNum}
          handleNextBtn={handleNextBtn}
          {...props}
        />
      );
  }
};
