import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import * as tokenConfig from "../utils/tokenConfig";
import * as authActions from "../redux/actions/authActions";

const ProtectedRoute = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Boolean(tokenConfig.getToken() && tokenConfig.getUserdata())) {
      dispatch(authActions.loadAuthUser());
    } else {
      dispatch(authActions.logout());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return auth.isAuth ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
