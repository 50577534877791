import React from "react";
import empty from "../../assets/icons/empty.svg";
import style from "./index.module.scss";

const EmptyState = ({ text }) => {
  return (
    <div className={style["empty--container"]}>
      <div className={style["empty--container__content"]}>
        <div>
          <img src={empty} alt="empty" />
        </div>
        <div className={style["text"]}>
          {text
            ? text
            : "You have no transactions yet. They’ll appear here when they’re available"}
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
