/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

export default function useScrollPagiation(url, pageNumber) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const userInfo = useSelector((state) => state.auth.authState);

  useEffect(() => {
    setLoading(true);
    setError(false);
    axios
      .post(url, {
        client_id: userInfo.id,
        token: userInfo.token,
        page: pageNumber,
      })
      .then((res) => {
        setData((prevBooks) => {
          return [...new Set([...prevBooks, ...res.data.data.data])];
        });
        setHasMore(res.data.data.data.length > 0);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  }, [url, pageNumber]);

  return { loading, error, data, hasMore };
}
