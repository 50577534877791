import React from "react";
import style from "./index.module.scss";
import PropTypes from "prop-types";

function Button({
  type,
  className,
  children,
  disabled,
  ...props
}) {
  return (
    // <div>
    <button
      type={type}
      disabled={disabled}
      className={`${style[className]}`}
      {...props}
    >
      {children}
    </button>
    // </div>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  props: PropTypes.any,
};

export default Button;

