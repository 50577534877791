import React from "react";
import AppBars from "../../App-bars";
import style from "./index.module.scss";
import notification from "../../../assets/icons/notification.svg";
import { useDispatch } from "react-redux";
import { clearErrorMessage } from "../../../redux/actions/messageActions";
import { useNavigate } from "react-router-dom";

export default function Layout({ children, pageTitle, subText }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  });

  const viewNotification = () => {
    dispatch(clearErrorMessage());
    navigate("/notifications");
  };

  return (
    <div className={style["layout__container"]}>
      <div className={style["layout--container__header"]} ref={ref}>
        <div className={style["header--content"]}>
          {" "}
          <h3 className={style["header--content__title"]}>{pageTitle} </h3>
          {subText && (
            <p className={style["header--content__text"]}>{subText}</p>
          )}
        </div>
        <div
          className={style["notification--icon"]}
          onClick={() => viewNotification()}
        >
          <img src={notification} alt="notification" />
        </div>
      </div>
      <main>{children}</main>

      <AppBars />
    </div>
  );
}
