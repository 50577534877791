import { Field, Formik, Form, useFormikContext, ErrorMessage } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import MessageLayout from "../../../components/Layout/MessageLayout";
import style from "./index.module.scss";
import bank from "../../../assets/icons/bank.svg";
import card from "../../../assets/icons/card.svg";
import walletSuccess from "../../../assets/icons/wallet-success.svg";
import rightAngle from "../../../assets/icons/rightAngle.svg";
import { useDispatch, useSelector } from "react-redux";
import * as paymentActions from "../../../redux/actions/paymentActions";
import { clearErrorMessage } from "../../../redux/actions/messageActions";
import * as Yup from "yup";
import AuthLayout from "../../../components/Layout/AuthLayout";
import { FormRowGroup } from "../../../components/Form-row";
import { ScaleLoader } from "react-spinners";
import { css } from "@emotion/react";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import nigerianBanks from "../../../utils/JSON/nigerian-banks.json";
import DatePickerField from "../../../components/DatePickerField/index";
import { bankTransferPayment } from "../../../redux/actions/paymentActions";
import FileUpload from "../../../components/File-upload";
import copy from "../../../assets/icons/copy.svg";
import useCopyToClipboard from "../../../utils/useCopytoClipboard";

function TopUpModule() {
  const navigate = useNavigate();
  const [step, setStep] = React.useState(1);

  const goBack = () => {
    navigate(-1);
  };

  const handleNextButton = () => {
    setStep(step + 1);
    dispatch(clearErrorMessage());
  };

  const handleBackButton = () => {
    dispatch(clearErrorMessage());
    setStep(step - 1);
  };

  const initialValues = {
    money_value: "",
  };

  const userInfo = useSelector((state) => state.auth.authState);
  const email = userInfo?.email;
  const token = userInfo?.token;
  const id = userInfo?.id;
  const notification = useSelector((state) => state.message);

  const dispatch = useDispatch();
  dayjs.extend(localizedFormat);

  function payWithPaystack(values, actions) {
    // e.preventDefault();
    let handler = window.PaystackPop.setup({
      key: "pk_live_9f46f712bf98af5176a1b850c2bd507b21124301",
      email,
      amount: values * 100,
      ref: "" + Math.floor(Math.random() * 1000000000 + 1),
      onClose: function () {},

      callback: function (response) {
        const formData = new FormData();

        formData.append("client_email", email);
        formData.append("client_id", id);
        formData.append("reference", response.reference);
        formData.append("token", token);

        dispatch(paymentActions.makePayment(formData));
        actions.resetForm();
      },
    });

    handler.openIframe();
  }

  const inputSchema = Yup.object().shape({
    money_value: Yup.number()
      .required("Required")
      .positive("Value cannot be negative")
      .typeError("Amount must be a number")
      .min(1000, "Minimum amount is N1000"),
  });

  const bankInitialValues = {
    client_id: "",
    bank_used: "",
    amount_transferred: "",
    sender_name: "",
    client_email: "",
    reference: "",
    transaction_date: "",
    payment_evidence: null,
    token: sessionStorage.getItem("msa_jwt"),
  };

  // const handleFormReset = (action) => {
  //   console.log(action);

  //   if (notification.id === "Transfer") {
  //     action();
  //   }
  // };
  const paymentEvidenceFormSchema = Yup.object().shape({
    bank_used: Yup.string().required("Required"),
    payment_evidence: Yup.mixed()
      .nullable()
      .required("Please upload a payment evidence"),

    amount_transferred: Yup.number()
      .required("Amount Transferred required.")
      .positive("Amount cannot be negative")
      .typeError("Amount must be a number"),

    sender_name: Yup.string().required("Sender Name is required."),

    transaction_date: Yup.date().required("Transaction date is required."),
  });

  return (
    <>
      {notification.id === "PAID" || notification.id === "Transfer" ? (
        <SuccessPage />
      ) : (
        <MessageLayout goBack={step === 1 ? goBack : handleBackButton}>
          <Formik
            initialValues={step === 1 ? initialValues : bankInitialValues}
            validationSchema={
              step === 1 ? inputSchema : paymentEvidenceFormSchema
            }
            onSubmit={(values) => {
              const formData = new FormData();

              formData.append("client_id", id);
              formData.append("client_email", email);
              formData.append("amount_paid", values.amount_transferred);
              formData.append("name", values.sender_name);
              formData.append("bank", values.bank_used);
              formData.append(
                "date",
                dayjs(values.transaction_date).format("YYYY-MM-DD")
              );
              formData.append("payment_evidence", values.payment_evidence);
              formData.append("token", token);
              dispatch(bankTransferPayment(formData));
            }}
          >
            {({ values }) => (
              <Form>
                <MultiStep
                  step={step}
                  nextButton={() => handleNextButton()}
                  payWithPaystack={() => payWithPaystack(values.money_value)}
                  notification={notification}
                />
              </Form>
            )}
          </Formik>
        </MessageLayout>
      )}
    </>
  );
}

const TopUp = ({ nextButton }) => {
  const { values, errors } = useFormikContext();
  return (
    <div className={style["top-up--container"]}>
      <h3>Top up my units</h3>
      <p>How much units do you want?</p>

      <div className={style["top-up"]}>
        <div className={style["form--group"]}>
          <label>Amount</label>
          <Field name="money_value" type="number" placeholder="5000" />
          <p>NGN</p>
        </div>
        <ErrorMessage name="money_value" component="span" className="error" />
        <div className={style["form--group"]}>
          <label>Receive</label>
          <Field
            name="unit"
            value={values.money_value}
            type="number"
            placeholder="5000"
          />
          <p>Units</p>
        </div>
      </div>
      <div className={style["info"]}>
        <div>N1000 = 1000 units</div>
      </div>
      <div style={{ margin: "6rem 0rem" }}></div>
      <div className={style["top-up--container__button"]}>
        <Button
          type="button"
          disabled={!values.money_value || errors.money_value}
          className={
            values.money_value && !errors.money_value ? "filled" : "faint"
          }
          onClick={() => nextButton()}
        >
          Top up
        </Button>
      </div>
    </div>
  );
};

const PaymentMethod = ({ nextButton, payWithPaystack, notification }) => {
  const methods = [
    { title: "Bank Transfer", icon: bank, type: "bank" },
    {
      title: "Debit Card",
      icon: card,
      type: "card",
    },
  ];
  return (
    <div className={style["payment-method--container"]}>
      <div className={style["payment-method--container__type"]}>
        {/* {notification && (
          <ul className="signup__errors">
            <li>{notification.msg.error}, becuase its a test paymment</li>
          </ul>
        )} */}
        <h3>How would you like to pay?</h3>
        <p>Select from the options below</p>

        <div className={style["method--types"]}>
          {methods.map((method, index) => (
            <div
              className={style["method--types__item"]}
              key={index}
              onClick={
                method.type === "bank"
                  ? () => nextButton(method.type)
                  : () => payWithPaystack()
              }
            >
              <div>
                <div
                  className={
                    index === 0 ? style["first-icon"] : style["last-icon"]
                  }
                >
                  <img src={method.icon} alt={method.title} />
                </div>
                <p>{method.title}</p>
              </div>

              <div className={style["right--angle"]}>
                <img src={rightAngle} alt="right--angle" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const PayWithBank = ({ nextButton }) => {
  const [isCopied, handleCopy] = useCopyToClipboard(500);
  return (
    <div className={style["bank--container"]}>
      <h3>Bank Transfer</h3>
      <p>Top up your wallet with the details provided</p>
      <div className={style["bank--container__content"]}>
        <p className={style["title"]}>Account Details</p>
        <div className={style["account--info"]}>
          <div className={style["sub-title"]}>
            <p>Bank Name</p>
            <p>Acct Name</p>
            <p>Acct Number</p>
          </div>
          <div className={style["content"]}>
            <p>Zenith Bank</p>
            <p>MSA Technologies Ltd</p>
            <p>
              <span onClick={() => handleCopy(" 1221044097")}>
                <img src={copy} alt="copy-icon" />
              </span>
              <span> {isCopied ? "copied" : "1221044097"}</span>
            </p>
          </div>
        </div>
      </div>
      <div className={style["bank--container__button"]}>
        <Button className="filled" type="button" onClick={() => nextButton()}>
          Upload Payment Evidence
        </Button>
      </div>
    </div>
  );
};

const BankForm = () => {
  const { errors, touched, setFieldValue, values } = useFormikContext();

  const isLoading = useSelector((state) => state.payments.isLoading);
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;

  return (
    <div className={style["bank-form--container"]}>
      <h3>Payment Details</h3>
      <p>Enter transaction details done by transfer</p>
      <div className={style["bank-form--container__form"]}>
        <FormRowGroup>
          <label htmlFor="bank_used">Bank name</label>
          <Field as="select" name="bank_used" id="bank_used">
            <option value="">Please Select an option</option>
            {nigerianBanks.map((item, id) => (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            ))}
          </Field>
          {errors.bank_used && <p className="error">{errors.bank_used}</p>}
        </FormRowGroup>

        <FormRowGroup>
          <label htmlFor="amount_transferred">Amount Transferred*</label>
          <Field
            type="tel"
            name="amount_transferred"
            id="amount_transferred"
            placeholder="Enter Amount Transferred"
            className={
              errors.amount_transferred && touched.amount_transferred
                ? "input--error"
                : null
            }
          />
          {errors.amount_transferred && (
            <p className="error">{errors.amount_transferred}</p>
          )}
        </FormRowGroup>

        <FormRowGroup>
          <label htmlFor="sender_name">Account Name*</label>
          <Field
            type="text"
            name="sender_name"
            id="sender_name"
            placeholder="Enter Sender Name*"
            className={
              errors.sender_name && touched.sender_name ? "input--error" : null
            }
          />
          {errors.sender_name && <p className="error">{errors.sender_name}</p>}
        </FormRowGroup>
        <FormRowGroup>
          <label htmlFor="transaction_date">Transaction Date</label>
          <DatePickerField
            name="transaction_date"
            placeholder={"Select a date"}
            value={values.transaction_date}
            className={
              errors.transaction_date && touched.transaction_date
                ? "input--error"
                : null
            }
          />

          {errors.transaction_date && (
            <p className="error">{errors.transaction_date}</p>
          )}
        </FormRowGroup>
        <FormRowGroup>
          <FileUpload
            setFieldValue={setFieldValue}
            values={values}
            name="payment_evidence"
            accept="image/jpg, image/png, pdf"
            label={"Select a file to upload"}
          >
            <p className={style["filename"]}>{values.payment_evidence?.name}</p>
          </FileUpload>

          {errors.payment_evidence && (
            <p className="error">{errors.payment_evidence}</p>
          )}
        </FormRowGroup>

        <Button type="submit" className={values.bank_used ? "filled" : "faint"}>
          {!isLoading ? (
            "Upload Payment Details"
          ) : (
            <ScaleLoader
              css={override}
              size={80}
              color={"#fff"}
              loading={true}
            />
          )}{" "}
        </Button>
      </div>
    </div>
  );
};

const MultiStep = ({ step, nextButton, payWithPaystack, notification }) => {
  switch (step) {
    case 1:
      return <TopUp nextButton={nextButton} />;
    case 2:
      return (
        <PaymentMethod
          nextButton={nextButton}
          payWithPaystack={payWithPaystack}
          notification={notification}
        />
      );
    case 3:
      return <PayWithBank nextButton={nextButton} />;
    case 4:
      return <BankForm />;
    default:
      return <TopUp nextButton={nextButton} />;
  }
};

const SuccessPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = () => {
    dispatch(clearErrorMessage());
    navigate("/wallets");
  };
  return (
    <AuthLayout>
      <div className={style["success--container"]}>
        {" "}
        <div className={style["success--container__icon"]}>
          <img src={walletSuccess} alt="gift" />
        </div>
        <div className={style["success--container__content"]}>
          <h3>Top-up Successful</h3>
          <p>You should receive your purchased units within 5 minutes.</p>
        </div>
        <div className={style["success--container__button"]}>
          <Button className="filled" onClick={() => handleNavigate()}>
            Okay, got it
          </Button>
        </div>
      </div>
    </AuthLayout>
  );
};

export default TopUpModule;
