import * as types from "./actionTypes";
import axios from "../../utils/axios-base";
import { returnErrors, returnSuccess } from "./messageActions";

export const getAllBanksStart = () => {
  return {
    type: types.GET_BANKS,
  };
};

export const getAllBanksSuccess = (data) => {
  return {
    type: types.GET_BANKS_SUCCESS,
    data,
  };
};

export const getAllBanksFailed = () => {
  return {
    type: types.GET_BANKS_FAIL,
  };
};

export const fetchAllBanks = (data) => (dispatch) => {
  dispatch(getAllBanksStart());
  axios
    .post(`/gift/get-banks`, data)
    .then((res) => {
      dispatch(getAllBanksSuccess(res.data.data));
      dispatch(returnSuccess(res.data.message, "ok", "Banks fetched"));
    })
    .catch((err) => {
      dispatch(getAllBanksFailed());
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};
