import React from "react";
import style from "./index.module.scss";
import moment from "moment";
import "moment-duration-format";
import useAudioPlayer from "../../utils/useAudioPlayer";
import play from "../../assets/icons/play.svg";
import pause from "../../assets/icons/pause.svg";

const AudioPlayer = ({ audio }) => {
  const { curTime, duration, playing, setPlaying, setClickedTime } =
    useAudioPlayer();

  return (
    <div className={style["player--container"]}>
      <div className={style["player--container__play-pause"]}>
        {playing ? (
          <img src={pause} alt="pause" onClick={() => setPlaying(false)} />
        ) : (
          <img src={play} alt="play" onClick={() => setPlaying(true)} />
        )}
      </div>
      <audio id="audio" src={audio}>
        <source src={audio} />
        Your browser does not support the <code>audio</code> element.
      </audio>
      <div className={style["player--container__bar"]}>
        <Bar
          curTime={curTime}
          duration={duration}
          onTimeUpdate={(time) => setClickedTime(time)}
        />
      </div>
      <div className={style["player--container__time"]}></div>
    </div>
  );
};

export default AudioPlayer;

function Bar(props) {
  const { duration, curTime, onTimeUpdate } = props;

  const curPercentage = (curTime / duration) * 100;

  function formatDuration(duration) {
    return moment
      .duration(duration, "seconds")
      .format("mm:ss", { trim: false });
  }

  function calcClickedTime(e) {
    const clickPositionInPage = e.pageX;
    const bar = document.querySelector(".bar__progress");
    const barStart = bar.getBoundingClientRect().left + window.scrollX;
    const barWidth = bar.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = duration / barWidth;
    return timePerPixel * clickPositionInBar;
  }

  function handleTimeDrag(e) {
    onTimeUpdate(calcClickedTime(e));

    const updateTimeOnMove = (eMove) => {
      onTimeUpdate(calcClickedTime(eMove));
    };

    document.addEventListener("mousemove", updateTimeOnMove);

    document.addEventListener("mouseup", () => {
      document.removeEventListener("mousemove", updateTimeOnMove);
    });
  }

  return (
    <div className={style["bar"]}>
      <div
        className={style["bar__progress"]}
        style={{
          background: `linear-gradient(to right, #BC0C13 ${curPercentage}%, #D9D9D9 0)`,
        }}
        onMouseDown={(e) => handleTimeDrag(e)}
      >
        <span
          className={style["bar__progress__knob"]}
          style={{ left: `${curPercentage - 2}%` }}
        />
      </div>
      <div className={style["duration"]}>
        <span className={style["bar__time"]}>{formatDuration(curTime)}</span> /
        <span className={style["bar__time"]}>{formatDuration(duration)}</span>
      </div>
    </div>
  );
}
