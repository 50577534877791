import * as types from "../actions/actionTypes";
import * as tokenConfig from "./../../utils/tokenConfig";

const initialState = {
  token: tokenConfig.getToken(),
  authState: JSON.parse(window.localStorage.getItem("msa_user_data")) || null,
  password_reset: null,
  isAuth: localStorage.getItem("msa_jwt") ? true : false,
  isLoading: false,
  data: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_AUTH_USER_START:
    case types.ADD_BUSINESS_INFO_START:
    case types.LOGIN_START:
    case types.ADD_USER_START:
    case types.RESEND_VERIFICATION_START:
    case types.PASSWORD_RECOVERY_START:
    case types.PASSWORD_RESET_START:
    case types.UPDATE_USER_START:
    case types.UPDATE_BUSINESS_INFO_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_CLIENT_INFO_START:
      return {
        ...state,
        isLoading: true,
      };

    case types.VERIFY_USER_START:
      return {
        ...state,
        isLoading: false,
      };

    case types.LOAD_AUTH_USER_SUCCESS:
      return {
        ...state,
        isAuth: true,
        isLoading: false,
      };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        data: action.user,
        authState: JSON.parse(window.localStorage.getItem("msa_user_data")),
        isAuth: true,
        isLoading: false,
      };

    case types.VERIFY_USER_SUCCESS:
      return {
        ...state,
        data: action.user,
        token: tokenConfig.getToken(),
        authState: JSON.parse(window.localStorage.getItem("msa_user_data")),
        isAuth: false,
        isLoading: false,
      };

    case types.UPDATE_BUSINESS_INFO_SUCCESS:
      return {
        ...state,
        data: action.user,
        authState: JSON.parse(window.localStorage.getItem("msa_user_data")),
        isAuth: true,
        isLoading: false,
      };

    case types.PASSWORD_RECOVERY_SUCCESS:
    case types.ADD_BUSINESS_INFO_SUCCESS:
    case types.UPDATE_USER_SUCCESFUL:
      return {
        ...state,
        authState: JSON.parse(window.localStorage.getItem("msa_user_data")),
        data: action.data,
        isAuth: true,
        isLoading: false,
      };
    case types.GET_CLIENT_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.data,
        isAuth: true,
        isLoading: false,
      };
    case types.ADD_USER_SUCCESS:
      return {
        ...state,
        authState: JSON.parse(window.localStorage.getItem("msa_user_data")),
        data: action.data,
        isAuth: false,
        isLoading: false,
      };
    case types.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        password_reset: action.data,
        isLoading: false,
      };

    case types.LOGIN_FAILED:
    case types.LOGOUT_SUCCESS:
      tokenConfig.deleteToken();
      return {
        ...state,
        token: null,
        user: null,
        authState: null,
        isAuth: false,
        isLoading: false,
      };

    case types.ADD_USER_FAILED:
    case types.VERIFY_USER_FAILED:
    case types.RESEND_VERIFICATION_FAILED:
    case types.PASSWORD_RECOVERY_FAIL:
    case types.PASSWORD_RESET_FAIL:
    case types.ADD_BUSINESS_INFO_FAILED:
    case types.UPDATE_USER_FAIL:
    case types.UPDATE_BUSINESS_INFO_FAIL:
    case types.GET_CLIENT_INFO_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
