import React from "react";
import style from "./index.module.scss";
import backArrow from "../../../assets/icons/backArrow.svg";
import help from "../../../assets/icons/help.svg";
import alert from "../../../assets/icons/alert.svg";
import Button from "../../../components/Button";
const MessageLayout = ({
  children,
  goBack,
  bgcolor,
  bgcolorTwo,
  bgcolorThree,
  progressTwo,
  progressThree,
  height,
  progress,
  icon,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const closeRef = React.useRef();
  React.useEffect(() => {
    const closeOnOutsideClick = (e) => {
      if (isOpen && closeRef.current && closeRef.current.contains(e.target)) {
        console.log(closeRef.current.contains);
        setIsOpen(false);
      }
    };
    document.addEventListener("click", closeOnOutsideClick);

    return () => {
      document.removeEventListener("click", closeOnOutsideClick);
    };
  }, [isOpen]);
  return (
    <div className={style["message--layout"]}>
      <div className={style["message--layout__header"]}>
        <div className={style["go--back"]} onClick={() => goBack()}>
          <img src={backArrow} alt="arrow" />
        </div>
        <div className={style["progress"]}>
          <ProgressBarOne
            bgcolor={bgcolor}
            height={height}
            progress={progress}
          />
          <ProgressBarTwo
            bgcolorTwo={bgcolorTwo}
            height={height}
            progressTwo={progressTwo}
          />
          <ProgressBarThree
            bgcolorThree={bgcolorThree}
            height={height}
            progressThree={progressThree}
          />

          <div onClick={() => setIsOpen(!isOpen)}>
            <img src={help} alt="" />
          </div>
        </div>
      </div>
      <main>{children}</main>

      {isOpen && (
        <div ref={closeRef} className={style["report--container"]}>
          <div className={style["wrapper"]}>
            <div className={style["header"]}>
              <img src={alert} alt="" />
              <p>Report an issue</p>
            </div>

            <div className={style["form"]}>
              <div>
                <label>What issue are you facing?</label>
                <textarea placeholder="Type something..."></textarea>
              </div>
              <Button>Send Report</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ProgressBarOne = ({ bgcolor, height, progress }) => {
  const Parentdiv = {
    height: height,
    width: "60px",
    backgroundColor: "whitesmoke",
    borderRadius: 20,
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 20,
    textAlign: "right",
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}></div>
    </div>
  );
};

const ProgressBarTwo = ({ bgcolorTwo, height, progressTwo }) => {
  const Parentdiv = {
    height: height,
    width: "60px",
    backgroundColor: "whitesmoke",
    borderRadius: 20,
  };

  const Childdiv = {
    height: "100%",
    width: `${progressTwo}%`,
    backgroundColor: bgcolorTwo,
    borderRadius: 20,
    textAlign: "right",
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}></div>
    </div>
  );
};
const ProgressBarThree = ({ bgcolorThree, height, progressThree }) => {
  const Parentdiv = {
    height: height,
    width: "60px",
    backgroundColor: "whitesmoke",
    borderRadius: 20,
  };

  const Childdiv = {
    height: "100%",
    width: `${progressThree}%`,
    backgroundColor: bgcolorThree,
    borderRadius: 20,
    textAlign: "right",
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}></div>
    </div>
  );
};

export default MessageLayout;
