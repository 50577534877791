import * as types from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  data: null,
  recent: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_BLOG_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_BLOG_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    case types.GET_BLOG_FAIL:
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    default:
      return state;
  }
}
