/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import MessageLayout from "../../../components/Layout/MessageLayout";
import style from "./index.module.scss";
import elipses from "../../../assets/icons/elipses.svg";
import deleteIcon from "../../../assets/icons/delete.svg";
import addBlack from "../../../assets/icons/add-black.svg";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Button from "../../../components/Button";
import { Dialog } from "../../../components/Modal/index";
import { MoonLoader, ScaleLoader } from "react-spinners";
import { clearErrorMessage } from "../../../redux/actions/messageActions";
import useScrollPagination from "../../../utils/useScrollPagination";

const ViewPhonebook = () => {
  const { name, id } = useParams();
  const userInfo = useSelector((state) => state.auth.authState);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);

  const [type, setType] = React.useState(null);

  const [isDeleting, setIsDeleting] = React.useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDeletePhonebook = async () => {
    setIsDeleting(true);
    setDeleteModalOpen(!deleteModalOpen);
    try {
      let res = await axios.post(
        `https://crm-api.myserviceagent.net/api/v1/phonebook/dev/${id}/delete?token=${userInfo.token}`,
        {
          client_id: userInfo.id,
        }
      );

      if (res.status) {
        setIsDeleting(false);
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteContact = async () => {
    setDeleteModalOpen(!deleteModalOpen);
    try {
      let res = await axios.post(
        `https://crm-api.myserviceagent.net/api/v1/phonebook/dev/${selectedId}/item/delete`,
        {
          client_id: userInfo.id,
          token: userInfo.token,
          phonebook_id: id,
        }
      );

      if (res.status) {
        console.log(selectedId);
      } else {
        console.log(res.data);
      }
      setIsDeleting(false);
    } catch (error) {
      console.log(error);
    }
  };

  const toggling = (id, type) => {
    setView(false);
    setType(type);
    setSelectedId(id);
    setDeleteModalOpen(!deleteModalOpen);
  };

  const addContact = () => {
    dispatch(clearErrorMessage());
    navigate(`/add-contact/${id}`);
  };

  const closeRef = React.useRef();

  React.useEffect(() => {
    const closeOnOutsideClick = (e) => {
      if (view && closeRef.current && !closeRef.current.contains(e.target)) {
        setView(false);
      }
    };
    document.addEventListener("click", closeOnOutsideClick);

    return () => {
      document.removeEventListener("click", closeOnOutsideClick);
    };
  }, [view]);

  const [pageNumber, setPageNumber] = React.useState(1);

  const { data, hasMore, loading } = useScrollPagination(
    `https://crm-api.myserviceagent.net/api/v1/phonebook/mygift/client/${id}/items?page=${pageNumber}&perPage=20`,
    pageNumber
  );
  const observer = React.useRef();

  const lastBookElementRef = React.useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const refinedData = selectedId
    ? data.filter((item) => item.id === selectedId)
    : data;
  return (
    <MessageLayout goBack={() => navigate(-1)}>
      <div className={style["view--container"]}>
        <div className={style["view--container__top"]}>
          <div className={style["phonebook--name"]}>{name}</div>
          <div
            ref={closeRef}
            className={style["icon"]}
            onClick={() => setView(!view)}
          >
            <img src={elipses} alt="view" />
          </div>
          {view && (
            <div className={style["view--box"]}>
              <div className={style["add"]} onClick={() => addContact()}>
                {" "}
                <span>
                  <img src={addBlack} alt="delete-icon" />
                </span>
                <span>Add contact</span>
              </div>
              <div
                className={style["delete"]}
                onClick={() => toggling(null, "phonebook")}
              >
                <span>
                  <img src={deleteIcon} alt="delete-icon" />
                </span>
                <span>Delete phonebook</span>
              </div>
            </div>
          )}
        </div>

        <div className={style["view--container__lists"]}>
          {refinedData.map((contact, index) => {
            if (data.length === index + 1) {
              return (
                <div
                  className={style["list"]}
                  key={index}
                  ref={lastBookElementRef}
                >
                  <div className={style["content"]}>
                    <div className={style["icon"]}>
                      {contact?.name &&
                        contact?.name.split(" ")[0][0]?.toUpperCase()}
                      {contact?.name && contact?.name.split(" ")[1]
                        ? contact?.name.split(" ")[1][0]?.toUpperCase()
                        : null}
                    </div>
                    <div className={style["details"]}>
                      <p className={style["name"]}>{contact?.name}</p>
                      <div className={style["contact"]}>{contact?.msisdn}</div>
                    </div>
                  </div>
                  <div
                    className={style["delete--icon"]}
                    onClick={() => toggling(contact.id, "contact")}
                  >
                    <img src={deleteIcon} alt="delete-icon" />
                  </div>
                </div>
              );
            } else {
              return (
                <div className={style["list"]} key={index}>
                  <div className={style["content"]}>
                    <div className={style["icon"]}>
                      {contact?.name &&
                        contact?.name.split(" ")[0][0]?.toUpperCase()}
                      {contact?.name && contact?.name.split(" ")[1]
                        ? contact?.name.split(" ")[1][0]?.toUpperCase()
                        : null}
                    </div>
                    <div className={style["details"]}>
                      <p className={style["name"]}>{contact?.name}</p>
                      <div className={style["contact"]}>{contact?.msisdn}</div>
                    </div>
                  </div>
                  <div
                    className={style["delete--icon"]}
                    onClick={() => toggling(contact.id, "contact")}
                  >
                    <img src={deleteIcon} alt="delete-icon" />
                  </div>
                </div>
              );
            }
          })}

          {loading && (pageNumber === 1 || pageNumber >= 3) && (
            <div className={style["loader"]}>
              <ScaleLoader color="red" />
            </div>
          )}
        </div>
      </div>
      <Dialog
        isOpen={deleteModalOpen}
        toggleModal={() => setDeleteModalOpen(!deleteModalOpen)}
      >
        {isDeleting ? (
          <MoonLoader />
        ) : (
          <div className={style.deleteModal}>
            <h3>
              {type === "phonebook" ? "Delete Phonebook?" : "Delete Contact?"}
            </h3>
            <p>
              {type === "phonebook"
                ? "You will lose all your saved contacts"
                : "You will lose this contact, proceed?"}
            </p>
            <div className={style.buttons}>
              {" "}
              <div>
                <Button
                  type="submit"
                  className="medium--ghost"
                  onClick={() => {
                    setDeleteModalOpen(!deleteModalOpen);
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  type="submit"
                  className="medium--filled"
                  onClick={
                    type === "phonebook"
                      ? handleDeletePhonebook
                      : handleDeleteContact
                  }
                >
                  Yes, delete
                </Button>
              </div>
            </div>
          </div>
        )}
      </Dialog>
    </MessageLayout>
  );
};

export default ViewPhonebook;
