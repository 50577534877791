import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import Layout from "../../components/Layout/DashboardLayout";
import style from "./index.module.scss";
import creditIcon from "../../assets/icons/credit.svg";
import debitIcon from "../../assets/icons/debit.svg";
import { useNavigate } from "react-router-dom";
import { clearErrorMessage } from "../../redux/actions/messageActions";

import { ScaleLoader } from "react-spinners";
import EmptyState from "../../components/Empty-state";
import useScrollPagiation from "../../utils/useScrollPagination";
import { getClientInfo } from "../../redux/actions/authActions";

const Wallet = () => {
  const hoursOfDay = new Date().getHours();
  const minute = new Date().getMinutes();
  const checkDayTime = (hour, min) => {
    if (hour >= 6 && min >= 59) {
      return true;
    }
    return false;
  };

  const [isDayTime] = React.useState(checkDayTime(hoursOfDay, minute));

  const navigate = useNavigate();

  const userData = useSelector((state) => state.auth.userInfo);
  const userInfo = useSelector((state) => state.auth.authState);

  React.useEffect(() => {
    const payload = {
      client_id: userInfo?.id,
      token: userInfo?.token,
    };
    dispatch(getClientInfo(payload));
    dispatch(clearErrorMessage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const dispatch = useDispatch();

  const topUp = () => {
    dispatch(clearErrorMessage());
    navigate("/wallets/top-up");
  };

  const [pageNumber, setPageNumber] = React.useState(1);

  const { data, hasMore, loading } = useScrollPagiation(
    `https://crm-api.myserviceagent.net/api/v1/purchase/mygift/history?page=${pageNumber}`,
    pageNumber
  );

  const observer = React.useRef();

  const lastBookElementRef = React.useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          console.log("Visible", hasMore, loading);
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <Layout pageTitle={"Wallet"}>
      <div className={style["wallet--container"]}>
        <div
          className={
            isDayTime
              ? style["wallet--container__day"]
              : style["wallet--container__night"]
          }
        >
          <p>Total Units</p>
          <h2>{new Intl.NumberFormat().format(userData?.airtime_balance)}</h2>
          <p>₦ {new Intl.NumberFormat().format(userData?.airtime_balance)}</p>
        </div>
        <div className={style["wallet--container__button"]}>
          <Button className="filled" onClick={() => topUp()}>
            Top up
          </Button>
        </div>

        <div className={style["wallet--container__transaction"]}>
          <p className={style["title"]}>Recent Transactions</p>
          {!loading && !data && <EmptyState />}
          <div className={style["transaction--histories"]}>
            {data.map((history, index) => {
              if (data.length === index + 1) {
                return (
                  <div
                    className={style["history"]}
                    key={index}
                    ref={lastBookElementRef}
                  >
                    <div className={style["icon"]}>
                      <img
                        src={history?.type === "debit" ? debitIcon : creditIcon}
                        alt="credit"
                      />
                    </div>
                    <div className={style["content"]}>
                      <div>
                        <h3>
                          {" "}
                          {history?.purchase_source === "paystack" ||
                          history?.purchase_source === "bank"
                            ? `You topped up your wallet with  ${history?.airtime_units} units`
                            : history?.description}
                        </h3>
                        <p>
                          {/* <span>
                              {new Date(history.created_at).toLocaleDateString(
                                "en-us",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                }
                              )}
                            </span> */}

                          <span>{history.created_at}</span>
                        </p>
                      </div>
                      <div
                        className={style["amount"]}
                        style={{
                          color: history.type === "debit" ? "#000" : "#15803d",
                        }}
                      >{`${history?.type === "debit" ? "-" : "+"} ${
                        history?.amount_paid
                      }`}</div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className={style["history"]} key={index}>
                    <div className={style["icon"]}>
                      <img
                        src={history?.type === "debit" ? debitIcon : creditIcon}
                        alt="credit"
                      />
                    </div>
                    <div className={style["content"]}>
                      <div>
                        <h3>
                          {" "}
                          {history?.purchase_source === "paystack" ||
                          history?.purchase_source === "bank"
                            ? `You topped up your wallet with  ${history?.airtime_units} units`
                            : history?.description}
                        </h3>
                        <p>
                          {/* <span>
                              {new Date(history.created_at).toLocaleDateString(
                                "en-us",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                }
                              )}
                            </span> */}

                          <span>{history.created_at}</span>
                        </p>
                      </div>
                      <div
                        className={style["amount"]}
                        style={{
                          color: history.type === "debit" ? "#000" : "#15803d",
                        }}
                      >{`${history?.type === "debit" ? "-" : "+"} ${
                        history?.amount_paid
                      }`}</div>
                    </div>
                  </div>
                );
              }
            })}
            {loading && (pageNumber === 1 || pageNumber >= 3) && (
              <div className={style["loader"]}>
                <ScaleLoader color="red" />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Wallet;

// const icon = (
//   <svg
//     width="25"
//     height="26"
//     viewBox="0 0 25 26"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M12.5002 4.55939C7.8467 4.55939 4.05957 8.34652 4.05957 13C4.05957 17.6534 7.8467 21.4406 12.5002 21.4406C17.1536 21.4406 20.9408 17.6534 20.9408 13C20.9408 8.34652 17.1536 4.55939 12.5002 4.55939ZM12.5002 20.2525C8.51502 20.2525 5.24769 17.0099 5.24769 13C5.24769 8.99008 8.49026 5.74751 12.5002 5.74751C16.4853 5.74751 19.7526 8.99008 19.7526 13C19.7526 17.0099 16.4853 20.2525 12.5002 20.2525Z"
//       fill="white"
//     />
//     <path
//       d="M16.3859 12.3317H13.0938V9.03962C13.0938 8.71783 12.8215 8.44556 12.4997 8.44556C12.1779 8.44556 11.9057 8.71783 11.9057 9.03962V12.3317H8.61359C8.29181 12.3317 8.01953 12.604 8.01953 12.9258C8.01953 13.2475 8.29181 13.5198 8.61359 13.5198H11.9057V16.8119C11.9057 17.1337 12.1779 17.406 12.4997 17.406C12.8215 17.406 13.0938 17.1337 13.0938 16.8119V13.5198H16.3859C16.7076 13.5198 16.9799 13.2475 16.9799 12.9258C16.9799 12.604 16.7076 12.3317 16.3859 12.3317Z"
//       fill="white"
//     />
//   </svg>
// );
