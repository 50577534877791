import { combineReducers } from "redux";
import * as actionTypes from "../actions/actionTypes";

import authReducer from "./authReducer";
import messageReducer from "./messageReducer";
import paymentReducer from "./paymentReducer";
import otpReducer from "./otpReducer";
import accountReducer from "./accountReducer";
import senGiftReducer from "./sendGiftReducer";
import createPhonebookReducer from "./createPhonebookReducer";
import blogReducer from "./blogReducer";
import bankReducer from "./bankReducer";
import charityReducer from "./charityReducer";

const appReducer = combineReducers({
  auth: authReducer,
  otp: otpReducer,
  payments: paymentReducer,
  message: messageReducer,
  account: accountReducer,
  gifting: senGiftReducer,
  creatingPhonebook: createPhonebookReducer,
  blog: blogReducer,
  bankList: bankReducer,
  charity: charityReducer,
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.LOGOUT_SUCCESS) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
