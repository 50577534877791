import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormRowGroup } from "../../../components/Form-row";
import MessageLayout from "../../../components/Layout/MessageLayout";
import style from "./index.module.scss";
import dialingcodes from "../../../utils/JSON/dialing-codes.json";
import Button from "../../../components/Button";
import { updateUser } from "../../../redux/actions/authActions";
import { ScaleLoader } from "react-spinners";
import AuthLayout from "../../../components/Layout/AuthLayout";
import { clearErrorMessage } from "../../../redux/actions/messageActions";
import success from "../../../assets/icons/success.svg";
import { css } from "@emotion/react";
import * as Yup from "yup";

const UpdateProfile = () => {
  const userInfo = useSelector((state) => state.auth.authState);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.message);
  const isLoading = useSelector((state) => state.auth.isLoading);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;

  const initialValues = {
    firstName: userInfo?.contact_person.split(" ")[0],
    lastName: userInfo?.contact_person.split(" ")[1],
    email: userInfo?.email,
    phone_no: userInfo?.phone_number?.split(" ")[1],
    dialing_code: userInfo?.phone_number?.split(" ")[0],
    software_dev: userInfo?.software_developer,
    country: userInfo?.country,
  };

  const UpdateSchema = Yup.object().shape({
    firstName: Yup.string().required("First name cannot be empty"),
    lastName: Yup.string().required("Last name cannot be empty"),
    phone_no: Yup.string().required("Phone number is required"),
    dialing_code: Yup.string().required("required"),
  });
  return (
    <>
      {notification.success ? (
        <SuccessPage />
      ) : (
        <MessageLayout goBack={() => navigate(-1)}>
          <div className={style["update-profile--container"]}>
            <h3>Edit Profile</h3>
            <Formik
              initialValues={initialValues}
              validationSchema={UpdateSchema}
              onSubmit={({
                firstName,
                lastName,
                email,
                phone_no,
                country,
                dialing_code,
              }) => {
                const formData = new FormData();
                const fullName = `${firstName} ${lastName}`;
                const userPhoneNo = `${dialing_code} ${phone_no}`;
                formData.append("contact_person", fullName);
                formData.append("email", email);
                formData.append("phone_number", userPhoneNo);
                formData.append("software_developer", "no");
                // formData.append("source", "mygift");
                formData.append("country", country);
                formData.append("token", userInfo?.token);
                dispatch(updateUser(formData, userInfo.id, "SUCCESS"));
              }}
            >
              {({ values }) => (
                <Form>
                  <div className={style["form__container--row"]}>
                    <div>
                      <label htmlFor="firstName">First Name</label>
                      <Field
                        name="firstName"
                        type="text"
                        placeholder="e.g Pan"
                      />
                      <ErrorMessage
                        component="span"
                        name="firstName"
                        className="error"
                      />
                    </div>
                    <div>
                      <label htmlFor="lastName">Last Name</label>
                      <Field
                        name="lastName"
                        type="text"
                        placeholder="e.g Pan"
                      />
                      <ErrorMessage
                        component="span"
                        name="lastName"
                        className="error"
                      />
                    </div>
                  </div>

                  <FormRowGroup>
                    <label htmlFor="email">Email Address</label>
                    <Field
                      name="email"
                      type="text"
                      placeholder="e.g Pan"
                      disabled
                    />
                  </FormRowGroup>
                  <FormRowGroup>
                    <label htmlFor="phone_number">Phone Number</label>
                    <div className={style["form__container--custom"]}>
                      <Field
                        name="dialing_code"
                        id=""
                        defaultValue={values?.dialing_code}
                        as="select"
                      >
                        {dialingcodes.map((code, index) => {
                          return (
                            <option value={code.callingCode} key={index}>
                              +{code.callingCode}
                            </option>
                          );
                        })}
                      </Field>
                      <Field type="tel" name="phone_no" />
                    </div>
                    <ErrorMessage
                      component="span"
                      name="phone_no"
                      className="error"
                    />
                  </FormRowGroup>
                  <div style={{ margin: "6rem 0rem" }}></div>

                  <div className={style["button"]}>
                    <Button type="submit" className="filled">
                      {isLoading ? (
                        <ScaleLoader
                          css={override}
                          size={80}
                          color={"#fff"}
                          loading={true}
                        />
                      ) : (
                        " Update Profile"
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </MessageLayout>
      )}
    </>
  );
};
const SuccessPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = () => {
    dispatch(clearErrorMessage());
    navigate("/settings");
  };
  return (
    <AuthLayout>
      <div className={style["success--container"]}>
        {" "}
        <div className={style["success--container__icon"]}>
          <img src={success} alt="gift" />
        </div>
        <div className={style["success--container__content"]}>
          <h3>Profile Updated</h3>
        </div>
        <div className={style["success--container__button"]}>
          <Button className="filled" onClick={() => handleNavigate()}>
            Okay, got it
          </Button>
        </div>
      </div>
    </AuthLayout>
  );
};

export default UpdateProfile;
