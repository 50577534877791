import React from "react";
import style from "./index.module.scss";
import uploadIcon from "../../assets/icons/upload-icon.svg";
import file from "../../assets/icons/file.svg";

const FileUpload = ({
  setFieldValue,
  values,
  name,
  accept,
  children,
  label,
  fileType,
}) => {
  // const fileSelect = React.useRef(null);
  return (
    <div
      className={style["file-upload--container"]}
      // onClick={() => fileSelect.current.click((e) => console.log(e))}
    >
      <div
        className={
          !values[name]?.name ? style["file-upload--container__icon"] : ""
        }
      >
        {!values[name]?.name && <img src={uploadIcon} alt="upload-icon" />}
        {values[name]?.name && <img src={file} alt="upload-icon" />}
      </div>
      {children}
      <div className={style["file-upload--container__none"]}>
        {!values[name]?.name && (
          <>
            <label htmlFor="file-upload">{label}</label>
            <input
              // ref={fileSelect}
              type="file"
              name="file-upload"
              onChange={(event) => {
                setFieldValue(`${name}`, event.target.files[0]);
              }}
              id="file-upload"
              accept={accept}
            />
          </>
        )}
        {/* <p>Kindly upload as WAV/MP3 file (WAV format preferable)</p> */}
      </div>
      <p>{fileType}</p>
      <div className={style["file-upload--container__selected"]}>
        {values[name] && (
          <>
            <label htmlFor="file-upload" className={style["re-upload"]}>
              Reupload
            </label>
            <input
              type="file"
              name="file-upload"
              onChange={(event) =>
                setFieldValue(`${name}`, event.target.files[0])
              }
              id="file-upload"
              accept={accept}
            />
          </>
        )}
      </div>{" "}
    </div>
  );
};

export default FileUpload;
