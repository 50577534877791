import React from "react";
import style from "./index.module.scss";
import phone from "../../../assets/images/phone.svg";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();
  return (
    <div className={style["banner--container"]}>
      <div className={style["banner--container__inner"]}>
        <div className={style["right"]}>
          <h3>Join the league of e-gifters today!</h3>
          <Button
            className="medium--filled"
            type="button"
            onClick={() => navigate("/sign-up")}
          >
            Sign up for free
          </Button>
        </div>
        <div className={style["left"]}>
          <img src={phone} alt="MyGift PWA" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
