import React from "react";
import style from "./index.module.scss";
import { Link } from "react-router-dom";

export default function AuthLayout({ children }) {
  return (
    <section className={style["signup--container"]}>
      <div className={style["signup--container__header"]}>
        <Link to="/">
          <div className={style["login__container--img"]}>
            <p>
              <span>my</span>gift
            </p>
          </div>
        </Link>
      </div>

      <div className={style["signup--container__aside"]}>{children}</div>
    </section>
  );
}
