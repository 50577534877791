import React from "react";
import style from "./index.module.scss";
import heroImage from "../../../assets/images/hero-img.png";
import Button from "../../../components/Button";
import gifters from "../../../assets/images/gifters.svg";
import giftImage from "../../../assets/images/gift-image.svg";
import kits from "../../../assets/landingpage-icons/kit.svg";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  return (
    <div className={style["hero--wrapper"]}>
      <div className={style["hero--container"]}>
        <div className={style["hero--container__right"]}>
          <div className={style["top--icon"]}>
            <img src={kits} alt="drawkit" className={style["icon"]} />
          </div>
          <img src={heroImage} alt="" className={style["hero--image"]} />
          <div className={style["notification"]}>
            <img src={giftImage} alt="" className={style["gift--icon"]} />
            <p>Sammie sent you a gift!</p>
          </div>
        </div>
        <div className={style["hero--container__left"]}>
          <h3 className={style["hero--title"]}>
            Unlock the power of a unique e-gifting experience
          </h3>
          <p className={style["hero--text"]}>
            Providing customers with an all-in-one gifting experience through
            our digital gifting platform.
          </p>

          <Button
            className="small--filled"
            onClick={() => navigate("/sign-up")}
          >
            Try it now
          </Button>
          <div className={style["gifters"]}>
            <img src={gifters} alt="" />
            <p>Join the 100+ gifters who trust us</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
