import * as types from "./actionTypes";
import axios from "../../utils/axios-base";
import { returnErrors, returnSuccess } from "./messageActions";

export const sendGiftStart = () => {
  return {
    type: types.SEND_GIFT_START,
  };
};

export const sendGiftSuccess = (data, status, id) => {
  return {
    type: types.SEND_GIFT_SUCCESS,
    data,
  };
};

const sendGiftFail = () => ({
  type: types.SEND_GIFT_FAIL,
});

const config = {
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "multipart/form-data",
  },
};

export const sendGift = (formData, url) => (dispatch) => {
  dispatch(sendGiftStart());

  axios
    .post(url, formData, config)
    .then((res) => {
      dispatch(sendGiftSuccess());
      dispatch(
        returnSuccess(res.data.message, res.status, "SEND_GIFT_SUCCESS")
      );
    })
    .catch((err) => {
      dispatch(sendGiftFail());
      dispatch(
        returnErrors(err.response?.data, err.response?.status, "SEND_GIFT_FAIL")
      );
    });
};
