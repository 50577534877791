// import moment from 'moment';

export const finishAuthentication = (token, userData, expires_at) => {

    // var cryptoVar = crypto.subtle;
    // const result = crypto.subtle.encrypt( {
    //     name: "AES-GCM "
    //   }, "logUserIn", userData);


    window.localStorage.setItem('msa_jwt', token);
    window.localStorage.setItem('msa_user_data', JSON.stringify(userData));
    // window.localStorage.setItem('msa_jwt_expire', JSON.stringify(expires_at));
    // console.log(userData)
    // localStorage.setItem('access_token', token);
    // localStorage.setItem('id', userData.id);
    // localStorage.setItem('expires_at', expires_at);
    // localStorage.setItem('email', userData.email);
};
export const updateUser = (userData) => {

    window.localStorage.setItem('msa_user_data', JSON.stringify(userData));
};

export const getToken = () => {
    let token = localStorage.getItem('msa_jwt');
    if (token) {
        return token;
    }
    return null;
};

export const getUserdata = () => {
    let userData = localStorage.getItem('msa_user_data');
    if (userData) {
        return JSON.parse(userData);
    }
    return null;
};

export const updateUserData = (userData) => {
    window.localStorage.setItem('msa_user_data', JSON.stringify(userData));
}

export const deleteToken = () => {
    window.localStorage.removeItem('msa_jwt');
    window.localStorage.removeItem('msa_user_data');
};

// export const isSessionExpired = () => {
//     // const jwt = window.localStorage.getItem('msa_jwt_expire');
//     if (!jwt) {
//         return true;
//     } else {
//         // return moment(JSON.parse(jwt)).isBefore(moment());
//     }
// };
