import React from "react";
import Button from "../../components/Button";
import AuthLayout from "../../components/Layout/AuthLayout";
import style from "./index.module.scss";
import success from "../../assets/icons/success.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as authActions from "../../redux/actions/authActions";
import { clearErrorMessage } from "../../redux/actions/messageActions";

const VerifyAccountSuccess = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  React.useEffect(() => {
    const email = query.get("email");
    const code = query.get("code");

    const formData = new FormData();

    formData.append("token", code);
    formData.append("email", email);

    dispatch(authActions.verifyUser(code, email));

    return () => {
      dispatch(clearErrorMessage());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AuthLayout>
        <div className={style["verify--container"]}>
          <div className="verify--content">
            <div className={style["verify--content__image"]}>
              <img src={success} alt="mail-icon" />
            </div>
            <div className={style["verify--content__title"]}>
              <h3>Verification Successful</h3>
            </div>
            <div className={style["verify--content__text"]}>
              Your account is now verified and you can now log in.
            </div>
          </div>
          <div className={style["button"]}>
            <Button onClick={() => navigate("/login")}>Go to Log in</Button>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default VerifyAccountSuccess;
