import React from "react";
import MessageLayout from "../../components/Layout/MessageLayout";
import style from "./index.module.scss";
import email from "../../assets/icons/email.svg";
import call from "../../assets/icons/call.svg";
import rightAngle from "../../assets/icons/rightAngle.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearErrorMessage } from "../../redux/actions/messageActions";
import facebook from "../../assets/icons/facebook.svg";
import tiktok from "../../assets/icons/tiktok.svg";
import instagram from "../../assets/icons/instagram.svg";
import twitter from "../../assets/icons/twitter.svg";
import whatsapp from "../../assets/icons/whatsapp.svg";

const Contact = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleGoBack = () => {
    dispatch(clearErrorMessage());
    navigate(-1);
  };

  const openEmail = () => {
    document.location = "mailto:hello@myserviceagent.net";
  };
  const openCall = () => {
    document.location = "tel:+2348026299730";
  };

  const openWhatsapp = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <MessageLayout goBack={handleGoBack}>
      <div className={style["contact--container"]}>
        <h3>Contact Us</h3>
        <div className={style["contact--container__options"]}>
          <div
            className={style["action"]}
            onClick={() => openWhatsapp("https://wa.link/af1i4i")}
          >
            <div>
              <div className={style["icon"]}>
                <img src={whatsapp} alt="lock-icon" />
              </div>
              <p>Whatsapp</p>
            </div>
            <div className={style["right--angle"]}>
              <img src={rightAngle} alt="right--angle" />
            </div>
          </div>
          <div className={style["action"]} onClick={() => openEmail()}>
            <div>
              <div className={style["icon"]}>
                <img src={email} alt="lock-icon" />
              </div>
              <p>Email</p>
            </div>

            <div className={style["right--angle"]}>
              <img src={rightAngle} alt="right--angle" />
            </div>
          </div>
          <div className={style["action"]} onClick={() => openCall()}>
            <div>
              <div className={style["icon"]}>
                <img src={call} alt="lock-icon" />
              </div>
              <p>Phone number</p>
            </div>

            <div className={style["right--angle"]}>
              <img src={rightAngle} alt="right--angle" />
            </div>
          </div>
        </div>
        <div className={style["contact--container__socials"]}>
          <div className={style["social"]}>
            <a
              href="https://www.facebook.com/profile.php?id=100086185134743"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="facebook-icon" />
            </a>
          </div>
          <div className={style["social"]}>
            <a
              href="https://instagram.com/msamygift"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram} alt="instagram-icon" />
            </a>
          </div>
          <div className={style["social"]}>
            <a
              href="https://www.tiktok.com/@msamygift?_t=8VoMtVCk13O&_r=1"
              target="_blank"
              rel="noreferrer"
            >
              <img src={tiktok} alt="tiktok-icon" />
            </a>
          </div>
          <div className={style["social"]}>
            <a
              href="https://twitter.com/MsaMygift?s=20&t=uVLuDjYrdetQbAZ318maTw"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitter} alt="twitter-icon" />
            </a>
          </div>
        </div>
      </div>
    </MessageLayout>
  );
};

export default Contact;
