import React from "react";
import style from "./index.module.scss";
import femi from "../../../assets/images/how-1.png";
import Amaka1 from "../../../assets/images/how-2.png";
import Amaka2 from "../../../assets/images/how-3.png";

const How = () => {
  return (
    <div className={style["how--wrapper"]} id="how-it-works">
      <div className={style["how--container"]}>
        <h3>How it works</h3>

        <div className={style["how--lists"]}>
          {lists.map((ittem, index) => (
            <div className={style["how--lists__item"]} key={index}>
              <img src={ittem.image} alt="" />
              <p>{ittem.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default How;

const lists = [
  {
    text: "Femi sends an e-gift which automatically triggers a call to Amaka",
    image: femi,
  },
  {
    text: "Amaka picks the call",
    image: Amaka1,
  },
  {
    text: "Amaka receives the gift immediately after the call",
    image: Amaka2,
  },
];
