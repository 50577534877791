import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import React from "react";
import style from "./index.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { FormRowGroup, FormRowRadio } from "../../../components/Form-row";
import MessageLayout from "../../../components/Layout/MessageLayout";
import { clearErrorMessage } from "../../../redux/actions/messageActions";
import * as phonebookActions from "../../../redux/actions/createPhonebookActions";
import { useDispatch, useSelector } from "react-redux";
import { css } from "@emotion/react";
import { ScaleLoader } from "react-spinners";
import Button from "../../../components/Button";
import FileUpload from "../../../components/File-upload";
import AuthLayout from "../../../components/Layout/AuthLayout";
import successIcon from "../../../assets/icons/success.svg";
import TagInput from "../../../components/TagInput";
import {
  CreatePhonebookSchema,
  UploadPhonebookSchema,
} from "../../../utils/ValidationSchemas";
import { Dialog } from "../../../components/Modal";
import sample from "../../../assets/images/sample.png";

const ContactCreateType = ({ nextButton }) => {
  const { values } = useFormikContext();
  return (
    <>
      <div className={style["form--fields"]}>
        <FormRowGroup>
          <label htmlFor="name">Phonebook Name </label>
          <Field id="name" name="name" placeholder="Enter a phonebook name" />
          <ErrorMessage name="name" className="error" component="span" />
        </FormRowGroup>

        <FormRowRadio>
          <Field
            type="radio"
            name="type"
            value="single"
            checked={values.type === "single"}
            id="single"
          />
          <label htmlFor="single">Single Contact</label>
        </FormRowRadio>

        <FormRowRadio>
          <Field
            type="radio"
            name="type"
            value="batch"
            checked={values.type === "batch"}
            id="batch"
          />
          <label htmlFor="batch">Batch Contact</label>
        </FormRowRadio>
      </div>

      <div className={style["button"]}>
        <Button
          type="button"
          className={values.name && values.type ? "filled" : "faint"}
          disabled={!values.name || !values.type}
          onClick={() => nextButton()}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

const CreatePhonebook = () => {
  const { type } = useParams();
  const navigate = useNavigate();
  const notification = useSelector((state) => state.message);
  const [step, setStep] = React.useState(1);
  const [openSample, setOpenSample] = React.useState(false);

  const handleSample = () => {
    setOpenSample(!openSample);
  };

  const goBack = () => {
    navigate(-1);
    dispatch(clearErrorMessage());
  };
  const handleNextButton = () => {
    setStep(step + 1);
    dispatch(clearErrorMessage());
  };

  const handleBackButton = () => {
    dispatch(clearErrorMessage());
    setStep(step - 1);
  };

  const userInfo = useSelector((state) => state.auth.authState);
  const [numbers, setNumbers] = React.useState([]);
  const [names, setNames] = React.useState([]);

  const dispatch = useDispatch();
  const isSending = useSelector((state) => state.creatingPhonebook.isSending);

  const initialValues = {
    type: "",
    name: "",
    msisdns: "",
    msisdns_file: "",
    contact_name: "",
    client_id: userInfo?.key?.client_id,
    token: userInfo?.token,
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;

  return (
    <>
      {notification.success ? (
        <SuccessPage actionType={type === "Create" ? "Created" : "Uploaded"} />
      ) : (
        <MessageLayout goBack={step === 1 ? goBack : handleBackButton}>
          <h3>{type} phonebook</h3>

          <ul className="signup__errors">
            {notification.error && (
              <li>{notification?.msg?.payload?.msg?.error}</li>
            )}
          </ul>
          <Formik
            initialValues={initialValues}
            validationSchema={
              type === "upload" ? UploadPhonebookSchema : CreatePhonebookSchema
            }
            onSubmit={(values, actions) => {
              const formData = new FormData();
              formData.append("name", values.name);
              formData.append("client_id", values.client_id);
              formData.append("token", values.token);

              if (type === "Upload") {
                formData.append("msisdns_file", values.msisdns_file);
              }

              if (type === "Create") {
                formData.append("msisdns", values.msisdns || numbers);
                formData.append("contact_name", values.contact_name || names);
              }

              dispatch(phonebookActions.createSingleOrBatchPhonebook(formData));
            }}
          >
            {({ values, setFieldValue, ...props }) => (
              <Form>
                {type === "Upload" ? (
                  <div style={{ height: "480px", overflowY: "scroll" }}>
                    <FormRowGroup>
                      <label htmlFor="name">Phonebook Name </label>
                      <Field
                        id="name"
                        name="name"
                        placeholder="Enter a phonebook name"
                      />
                      <ErrorMessage
                        name="name"
                        className="error"
                        component="span"
                      />
                    </FormRowGroup>
                    <FileUpload
                      label="Select a CSV file to upload"
                      setFieldValue={setFieldValue}
                      name="msisdns_file"
                      accept=".csv"
                      values={values}
                    >
                      <p className={style["filename"]}>
                        {values.msisdns_file?.name}
                      </p>
                    </FileUpload>
                    <div
                      className={style["sample"]}
                      onClick={() => handleSample()}
                    >
                      <p>See an example</p>
                    </div>
                    <div className={style["button"]}>
                      <Button
                        type="submit"
                        className={
                          values.msisdns_file && values.name
                            ? "filled"
                            : "faint"
                        }
                        disabled={!values.msisdns_file}
                      >
                        {!isSending ? (
                          " Upload Phonebook"
                        ) : (
                          <ScaleLoader
                            css={override}
                            size={80}
                            color={"#fff"}
                            loading={true}
                          />
                        )}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <MultiForm
                    step={step}
                    isSending={isSending}
                    phones={numbers}
                    setPhones={setNumbers}
                    names={names}
                    setNames={setNames}
                    nextButton={() => handleNextButton()}
                  />
                )}
              </Form>
            )}
          </Formik>
        </MessageLayout>
      )}

      <Dialog isOpen={openSample}>
        <div className={style["sample--modal"]}>
          <h5>CSV File format preview</h5>
          <img src={sample} alt="sample-excel" />

          <Button
            className="small--filled"
            type="button"
            onClick={() => handleSample()}
          >
            Okay, got it
          </Button>
        </div>
      </Dialog>
    </>
  );
};

const MultiForm = ({
  step,
  nextButton,
  isSending,
  names,
  setNames,
  phones,
  setPhones,
}) => {
  switch (step) {
    case 1:
      return <ContactCreateType nextButton={() => nextButton()} />;
    case 2:
      return (
        <SingleContact
          isSending={isSending}
          nextButton={nextButton}
          phones={phones}
          setPhones={setPhones}
          names={names}
          setNames={setNames}
        />
      );
    default:
      return <ContactCreateType />;
  }
};

const SingleContact = ({ isSending, names, setNames, phones, setPhones }) => {
  const { values } = useFormikContext();
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;
  return (
    <>
      {values.type === "single" ? (
        <div style={{ height: "380px", overflowY: "scroll" }}>
          <FormRowGroup>
            <label htmlFor="contact_name">Contact name </label>

            <Field
              id="contact_name"
              name="contact_name"
              placeholder="e.g Peter Pan"
            />

            <ErrorMessage
              name="contact_name"
              className="error"
              component="span"
            />
          </FormRowGroup>
          <FormRowGroup>
            <label htmlFor="msisdns">Contact number </label>

            <Field
              id="msisdns"
              name="msisdns"
              placeholder="e.g 0810 234 5678"
              type="tel"
            />

            <ErrorMessage name="msisdns" className="error" component="span" />
          </FormRowGroup>
          <p className={style["info"]}>
            You can always add more recipients later
          </p>
        </div>
      ) : (
        <div style={{ height: "480px", overflowY: "scroll" }}>
          <FormRowGroup>
            <label htmlFor="contact_name">Contact name </label>
            <TagInput
              placeholder="e.g Peter, separated by space bar"
              tagInputs={names}
              setTagInputs={setNames}
              inputType="text"
            />
            <ErrorMessage
              name="contact_name"
              className="error"
              component="span"
            />
          </FormRowGroup>
          <FormRowGroup>
            <label htmlFor="msisdns">Contact number </label>
            <TagInput
              placeholder="e.g 0810 234 5678, separated by space bar"
              tagInputs={phones}
              setTagInputs={setPhones}
              inputType="number"
            />
            <ErrorMessage name="msisdns" className="error" component="span" />
          </FormRowGroup>

          <p> you have entered {names.length} names</p>
        </div>
      )}

      <div className={style["button"]}>
        {values.type === "single" ? (
          <Button
            type="submit"
            className={
              values.contact_name && values.msisdns ? "filled" : "faint"
            }
            disabled={isSending}
          >
            {!isSending ? (
              " Create Phonebook"
            ) : (
              <ScaleLoader
                css={override}
                size={80}
                color={"#fff"}
                loading={true}
              />
            )}
          </Button>
        ) : (
          <Button
            type="submit"
            className={phones.length === names.length ? "filled" : "faint"}
            disabled={
              phones.length !== names.length ||
              (phones.length === 0 && names.length === 0)
            }
          >
            {!isSending ? (
              " Create Phonebook"
            ) : (
              <ScaleLoader
                css={override}
                size={80}
                color={"#fff"}
                loading={true}
              />
            )}
          </Button>
        )}
      </div>
    </>
  );
};

const SuccessPage = ({ actionType }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = () => {
    dispatch(clearErrorMessage());
    navigate("/phonebook");
  };
  return (
    <AuthLayout>
      <div className={style["success--container"]}>
        {" "}
        <div className={style["success--container__icon"]}>
          <img src={successIcon} alt="gift" />
        </div>
        <div className={style["success--container__content"]}>
          <h3>Phonebook {actionType}</h3>
          <p>
            You’ve created your phonebook and your contacts will be available
            now.
          </p>
        </div>
        <div className={style["success--container__button"]}>
          <Button className="filled" onClick={() => handleNavigate()}>
            Okay, got it
          </Button>
        </div>
      </div>
    </AuthLayout>
  );
};

export default CreatePhonebook;
