import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import Layout from "../../components/Layout/DashboardLayout";
import style from "./index.module.scss";
import lock from "../../assets/icons/lock.svg";
import security from "../../assets/icons/security.svg";
import globe from "../../assets/icons/globe.svg";
import off from "../../assets/icons/off.svg";
import infoBlack from "../../assets/icons/info-black.svg";
import rightAngle from "../../assets/icons/rightAngle.svg";
import * as authActions from "../../redux/actions/authActions";
import { clearErrorMessage } from "../../redux/actions/messageActions";
import { useNavigate } from "react-router-dom";
const Settings = () => {
  const userInfo = useSelector((state) => state.auth.authState);
  const dispatch = useDispatch();
  const navgate = useNavigate();

  const logout = () => {
    dispatch(authActions.logout());
    dispatch(clearErrorMessage());
  };
  return (
    <Layout pageTitle={"Settings"}>
      <div className={style["settings--container"]}>
        <div className={style["settings--container__profile"]}>
          <div className={style["icon"]}>
            {userInfo?.contact_person.split(" ")[0][0]}
            {userInfo?.contact_person.split(" ")[1][0]}
          </div>
          <p className={style["name"]}>{userInfo?.contact_person}</p>
          <p className={style["phone"]}>{userInfo?.phone_number}</p>
          <Button
            className="large--filled"
            onClick={() => {
              dispatch(clearErrorMessage());
              navgate("/update-profile");
            }}
          >
            Edit Profile
          </Button>
        </div>

        <div className={style["settings--container__security"]}>
          <p className={style["title"]}>Security</p>
          <div
            className={style["action"]}
            onClick={() => {
              dispatch(clearErrorMessage());
              navgate("/change-password");
            }}
          >
            <div>
              <div className={style["icon"]}>
                <img src={lock} alt="lock-icon" />
              </div>
              <p>Change Password</p>
            </div>

            <div className={style["right--angle"]}>
              <img src={rightAngle} alt="right--angle" />
            </div>
          </div>
        </div>

        <div className={style["settings--container__support"]}>
          <p className={style["title"]}>Contact Us</p>
          <div
            className={style["action"]}
            onClick={() => {
              dispatch(clearErrorMessage());
              navgate("/contact");
            }}
          >
            <div>
              <div className={style["icon"]}>
                <img src={globe} alt="lock-icon" />
              </div>
              <p>Contact Us</p>
            </div>

            <div className={style["right--angle"]}>
              <img src={rightAngle} alt="right--angle" />
            </div>
          </div>
          <div
            className={style["action"]}
            onClick={() => {
              dispatch(clearErrorMessage());
              navgate("/terms-of-service");
            }}
          >
            <div>
              <div className={style["icon"]}>
                <img src={infoBlack} alt="lock-icon" />
              </div>
              <p>Terms of Use</p>
            </div>

            <div className={style["right--angle"]}>
              <img src={rightAngle} alt="right--angle" />
            </div>
          </div>
          <div
            className={style["action"]}
            onClick={() => {
              dispatch(clearErrorMessage());
              navgate("/privacy-policy");
            }}
          >
            <div>
              <div className={style["icon"]}>
                <img src={security} alt="lock-icon" />
              </div>
              <p>Privacy Policy</p>
            </div>

            <div className={style["right--angle"]}>
              <img src={rightAngle} alt="right--angle" />
            </div>
          </div>
        </div>

        <div className={style["settings--container__other"]}>
          <p className={style["title"]}>Other</p>
          <div className={style["action"]} onClick={logout}>
            <div>
              <div className={style["icon"]}>
                <img src={off} alt="lock-icon" />
              </div>
              <p>Sign out</p>
            </div>

            <div className={style["right--angle"]}>
              <img src={rightAngle} alt="right--angle" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Settings;
