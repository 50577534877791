import React from "react";
import style from "./index.module.scss";
import { NavLink } from "react-router-dom";
const AppBars = () => {
  const navs = [
    {
      title: "Home",
      route: "/dashboard",
      icon: (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3483_8)">
            <path
              opacity="0.2"
              d="M14.75 19.5V15C14.75 14.8011 14.671 14.6103 14.5303 14.4697C14.3897 14.329 14.1989 14.25 14 14.25H11C10.8011 14.25 10.6103 14.329 10.4697 14.4697C10.329 14.6103 10.25 14.8011 10.25 15V19.5C10.25 19.6989 10.171 19.8897 10.0303 20.0303C9.88968 20.171 9.69891 20.25 9.5 20.25H5C4.80109 20.25 4.61032 20.171 4.46967 20.0303C4.32902 19.8897 4.25 19.6989 4.25 19.5V10.8281C4.25168 10.7243 4.27411 10.6219 4.31597 10.5269C4.35783 10.432 4.41828 10.3463 4.49375 10.275L11.9937 3.45939C12.132 3.33291 12.3126 3.26276 12.5 3.26276C12.6874 3.26276 12.868 3.33291 13.0062 3.45939L20.5062 10.275C20.5817 10.3463 20.6422 10.432 20.684 10.5269C20.7259 10.6219 20.7483 10.7243 20.75 10.8281V19.5C20.75 19.6989 20.671 19.8897 20.5303 20.0303C20.3897 20.171 20.1989 20.25 20 20.25H15.5C15.3011 20.25 15.1103 20.171 14.9697 20.0303C14.829 19.8897 14.75 19.6989 14.75 19.5Z"
              fill="#EB2931"
              fill-opacity="0.4"
            />
            <path
              d="M14.75 19.5V15C14.75 14.8011 14.671 14.6103 14.5303 14.4697C14.3897 14.329 14.1989 14.25 14 14.25H11C10.8011 14.25 10.6103 14.329 10.4697 14.4697C10.329 14.6103 10.25 14.8011 10.25 15V19.5C10.25 19.6989 10.171 19.8897 10.0303 20.0303C9.88968 20.171 9.69891 20.25 9.5 20.25H5C4.80109 20.25 4.61032 20.171 4.46967 20.0303C4.32902 19.8897 4.25 19.6989 4.25 19.5V10.8281C4.25168 10.7243 4.27411 10.6219 4.31597 10.5269C4.35783 10.432 4.41828 10.3463 4.49375 10.275L11.9937 3.45939C12.132 3.33291 12.3126 3.26276 12.5 3.26276C12.6874 3.26276 12.868 3.33291 13.0062 3.45939L20.5062 10.275C20.5817 10.3463 20.6422 10.432 20.684 10.5269C20.7259 10.6219 20.7483 10.7243 20.75 10.8281V19.5C20.75 19.6989 20.671 19.8897 20.5303 20.0303C20.3897 20.171 20.1989 20.25 20 20.25H15.5C15.3011 20.25 15.1103 20.171 14.9697 20.0303C14.829 19.8897 14.75 19.6989 14.75 19.5Z"
              stroke="#EB2931"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_3483_8">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
    },
    {
      title: "Wallet",
      route: "/wallets",
      icon: (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3483_5525)">
            <path
              opacity="0.2"
              d="M4.25 18C4.25 18.3978 4.40804 18.7794 4.68934 19.0607C4.97064 19.342 5.35218 19.5 5.75 19.5H20.75C20.9489 19.5 21.1397 19.421 21.2803 19.2803C21.421 19.1397 21.5 18.9489 21.5 18.75V8.25C21.5 8.05109 21.421 7.86032 21.2803 7.71967C21.1397 7.57902 20.9489 7.5 20.75 7.5H5.75C5.35218 7.5 4.97064 7.34196 4.68934 7.06066C4.40804 6.77936 4.25 6.39782 4.25 6V18Z"
              fill="#EB2931"
              fill-opacity="0.4"
            />
            <path
              d="M4.25 6V18C4.25 18.3978 4.40804 18.7794 4.68934 19.0607C4.97064 19.342 5.35218 19.5 5.75 19.5H20.75C20.9489 19.5 21.1397 19.421 21.2803 19.2803C21.421 19.1397 21.5 18.9489 21.5 18.75V8.25C21.5 8.05109 21.421 7.86032 21.2803 7.71967C21.1397 7.57902 20.9489 7.5 20.75 7.5H5.75C5.35218 7.5 4.97064 7.34196 4.68934 7.06066C4.40804 6.77936 4.25 6.39782 4.25 6ZM4.25 6C4.25 5.60218 4.40804 5.22064 4.68934 4.93934C4.97064 4.65804 5.35218 4.5 5.75 4.5H18.5"
              stroke="#EB2931"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.375 14.625C17.9963 14.625 18.5 14.1213 18.5 13.5C18.5 12.8787 17.9963 12.375 17.375 12.375C16.7537 12.375 16.25 12.8787 16.25 13.5C16.25 14.1213 16.7537 14.625 17.375 14.625Z"
              fill="#EB2931"
            />
          </g>
          <defs>
            <clipPath id="clip0_3483_5525">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
    },
    {
      title: "Phonebook",
      route: "/phonebook",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3483_1171)">
            <path
              d="M12.75 13.5C14.4069 13.5 15.75 12.1569 15.75 10.5C15.75 8.84315 14.4069 7.5 12.75 7.5C11.0931 7.5 9.75 8.84315 9.75 10.5C9.75 12.1569 11.0931 13.5 12.75 13.5Z"
              stroke="#EB2931"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              opacity="0.2"
              d="M19.5 3H6C5.80109 3 5.61032 3.07902 5.46967 3.21967C5.32902 3.36032 5.25 3.55109 5.25 3.75V20.25C5.25 20.4489 5.32902 20.6397 5.46967 20.7803C5.61032 20.921 5.80109 21 6 21H19.5C19.6989 21 19.8897 20.921 20.0303 20.7803C20.171 20.6397 20.25 20.4489 20.25 20.25V3.75C20.25 3.55109 20.171 3.36032 20.0303 3.21967C19.8897 3.07902 19.6989 3 19.5 3ZM12.75 13.5C12.1567 13.5 11.5766 13.3241 11.0833 12.9944C10.5899 12.6648 10.2054 12.1962 9.97836 11.6481C9.7513 11.0999 9.69189 10.4967 9.80764 9.91473C9.9234 9.33279 10.2091 8.79824 10.6287 8.37868C11.0482 7.95912 11.5828 7.6734 12.1647 7.55764C12.7467 7.44189 13.3499 7.5013 13.8981 7.72836C14.4462 7.95542 14.9148 8.33994 15.2444 8.83329C15.5741 9.32664 15.75 9.90666 15.75 10.5C15.75 11.2956 15.4339 12.0587 14.8713 12.6213C14.3087 13.1839 13.5456 13.5 12.75 13.5Z"
              fill="#EB2931"
              fill-opacity="0.4"
            />
            <path
              d="M3 10.125H5.25"
              stroke="#EB2931"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3 6.375H5.25"
              stroke="#EB2931"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3 13.875H5.25"
              stroke="#EB2931"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3 17.625H5.25"
              stroke="#EB2931"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.25 15.75C8.77395 15.0514 9.45336 14.4844 10.2344 14.0938C11.0155 13.7033 11.8767 13.5 12.75 13.5C13.6233 13.5 14.4845 13.7033 15.2656 14.0938C16.0466 14.4844 16.726 15.0514 17.25 15.75"
              stroke="#EB2931"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.25 20.25V3.75C20.25 3.33579 19.9142 3 19.5 3L6 3C5.58579 3 5.25 3.33579 5.25 3.75L5.25 20.25C5.25 20.6642 5.58579 21 6 21H19.5C19.9142 21 20.25 20.6642 20.25 20.25Z"
              stroke="#EB2931"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_3483_1171">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
    },
    {
      title: "Settings",
      route: "/settings",
      icon: (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3483_923)">
            <path
              opacity="0.2"
              d="M22.0248 9.93748L19.5966 8.58748C19.4293 8.23061 19.2317 7.88873 19.006 7.5656L19.0529 4.78123C18.0227 3.84237 16.8007 3.13863 15.4716 2.71873L13.0904 4.14373C12.6971 4.1156 12.3024 4.1156 11.9091 4.14373L9.51852 2.70935C8.19362 3.13704 6.97569 3.84351 5.94664 4.78123L5.99352 7.5656C5.76782 7.88873 5.57022 8.23061 5.40289 8.58748L2.96539 9.93748C2.67312 11.2973 2.67632 12.704 2.97477 14.0625L5.40289 15.4125C5.57221 15.7713 5.76971 16.1161 5.99352 16.4437L5.94664 19.2187C6.97685 20.1576 8.1988 20.8613 9.52789 21.2812L11.9091 19.8562C12.3024 19.8844 12.6971 19.8844 13.0904 19.8562L15.481 21.2906C16.8059 20.8629 18.0238 20.1564 19.0529 19.2187L19.006 16.4344C19.2317 16.1112 19.4293 15.7693 19.5966 15.4125L22.0341 14.0625C22.3312 12.7032 22.328 11.2954 22.0248 9.93748ZM12.4998 16.5C11.6097 16.5 10.7397 16.2361 9.9997 15.7416C9.25968 15.2471 8.6829 14.5443 8.34231 13.7221C8.00171 12.8998 7.9126 11.995 8.08623 11.1221C8.25987 10.2492 8.68845 9.44733 9.31779 8.818C9.94712 8.18866 10.7489 7.76008 11.6219 7.58644C12.4948 7.41281 13.3996 7.50192 14.2218 7.84252C15.0441 8.18311 15.7469 8.75989 16.2414 9.49991C16.7358 10.2399 16.9998 11.11 16.9998 12C16.9998 13.1934 16.5257 14.338 15.6817 15.182C14.8378 16.0259 13.6932 16.5 12.4998 16.5Z"
              fill="#EB2931"
              fill-opacity="0.4"
            />
            <path
              d="M12.5 16.5C14.9853 16.5 17 14.4853 17 12C17 9.51472 14.9853 7.5 12.5 7.5C10.0147 7.5 8 9.51472 8 12C8 14.4853 10.0147 16.5 12.5 16.5Z"
              stroke="#EB2931"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19.006 7.5656C19.2317 7.88873 19.4293 8.23061 19.5966 8.58748L22.0248 9.93748C22.328 11.2954 22.3312 12.7032 22.0341 14.0625L19.5966 15.4125C19.4293 15.7693 19.2317 16.1112 19.006 16.4344L19.0529 19.2187C18.0238 20.1564 16.8059 20.8629 15.481 21.2906L13.0904 19.8562C12.6971 19.8844 12.3024 19.8844 11.9091 19.8562L9.52789 21.2812C8.1988 20.8613 6.97685 20.1576 5.94664 19.2187L5.99352 16.4437C5.76971 16.1161 5.57221 15.7713 5.40289 15.4125L2.97477 14.0625C2.67632 12.704 2.67312 11.2973 2.96539 9.93748L5.40289 8.58748C5.57022 8.23061 5.76782 7.88873 5.99352 7.5656L5.94664 4.78123C6.97569 3.84351 8.19362 3.13704 9.51852 2.70935L11.9091 4.14373C12.3024 4.1156 12.6971 4.1156 13.0904 4.14373L15.4716 2.71873C16.8007 3.13863 18.0227 3.84237 19.0529 4.78123L19.006 7.5656Z"
              stroke="#EB2931"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_3483_923">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
    },
  ];
  return (
    <div className={style["appbars--container"]}>
      <div className={style["appbars--container__navs"]}>
        {navs.map((nav, index) => (
          <NavLink
            key={index}
            to={nav.route}
            className={({ isActive }) =>
              isActive ? style["active"] : style["not-active"]
            }
          >
            <div className={style["nav"]} key={index}>
              <div className={style["icon"]}>{nav.icon}</div>
              <div className={style["title"]}>{nav.title}</div>
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default AppBars;
