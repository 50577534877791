import * as Yup from "yup";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

const lowCaseRegex = new RegExp(/^(?=.*[a-z])/);
const upperCaseRegex = new RegExp(/^(?=.*[A-Z])/);
const specialCaseRegex = new RegExp(/^(?=.*[!@#$%^&*])/);

export const passwordResetSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required.")
    .matches(
      lowCaseRegex,
      "Password must contain at least one lowercase character."
    )
    .matches(
      upperCaseRegex,
      "Password must contain at least one uppercase character."
    )
    .matches(
      specialCaseRegex,
      "Password must contain at least one special character."
    )
    .min(8, "Please fill in a minimum of 8 characters."),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please confirm your password"),
});

export const sendBusinessMessage = Yup.object().shape({
  recipient: Yup.string().required("Required "),
  name: Yup.string()
    .required("Required ")
    .min(3, "Please fill in a minimum of 3 characters."),

  upload_method: Yup.string().when("channel", {
    is: "voice",
    then: () => Yup.string().required("Please select an option"),
  }),

  message_audio: Yup.string().when("upload_method", {
    is: "audio",
    then: () => Yup.string().required("Required"),
  }),
  msisdns: Yup.string().when("recipient", {
    is: "numbers",
    then: () => Yup.string().required("Required"),
  }),
  // message_text: Yup.string().required("Required"),
  message_text: Yup.string().when(["channel", "upload_method"], {
    is: (channel, upload_method) =>
      channel === "sms" || upload_method === "tts",
    then: () => Yup.string().required("Required"),
  }),
  run_time: Yup.string().when("schedule", {
    is: true,
    then: () => Yup.string().required("Required"),
  }),
});

export const CreatePhonebookSchema = Yup.object().shape({
  name: Yup.string().required("Please enter phonebook name."),
  msisdns: Yup.string().when("type", {
    is: "single",
    then: () => Yup.string().required("Please enter phone number(s)."),
  }),
  contact_name: Yup.string().when("type", {
    is: "single",
    then: () => Yup.string().required("Enter customer's name"),
  }),
});

export const UploadPhonebookSchema = Yup.object().shape({
  name: Yup.string().required("Please enter phonebook name."),
  msisdns_file: Yup.string().required("Please upload a csv file"),
});
