/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import MessageLayout from "../../components/Layout/MessageLayout";
import style from "./index.module.scss";
import favorite from "../../assets/icons/favorite.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearErrorMessage } from "../../redux/actions/messageActions";

import useScrollPagination from "../../utils/useScrollPagination";
import { ScaleLoader } from "react-spinners";

const Notification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(clearErrorMessage());
  }, []);

  const [pageNumber, setPageNumber] = React.useState(1);

  const { data, hasMore, loading } = useScrollPagination(
    `https://crm-api.myserviceagent.net/api/v1/purchase/mygift/history?page=${pageNumber}&perPage=10`,
    pageNumber
  );

  const observer = React.useRef();

  const lastBookElementRef = React.useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          console.log("Visible", hasMore, loading);
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <>
      <MessageLayout goBack={() => navigate(-1)}>
        <div className={style["notification--container"]}>
          <h3>Notifications</h3>

          <div className={style["notification--container__items"]}>
            {data.map((item, index) => {
              if (data.length === index + 1) {
                return (
                  <div
                    className={style["item"]}
                    key={index}
                    ref={lastBookElementRef}
                  >
                    <p className={style["date"]}>
                      {/* <span>
                          {new Date(item.created_at).toLocaleDateString("en-us", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })}
                        </span> */}

                      {/* <span>{item.created_at.split(" ")[1]}</span> */}
                      <span>{item.created_at}</span>
                    </p>
                    <div className={style["content"]}>
                      <div className={style["icon"]}>
                        <img src={favorite} alt="favorite-icon" />
                      </div>
                      <p>
                        {item?.purchase_source === "paystack" ||
                        item?.purchase_source === " bank transfer"
                          ? `You topped up your wallet with  ${item?.airtime_units} units`
                          : item?.description}
                      </p>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className={style["item"]} key={index}>
                    <p className={style["date"]}>
                      {/* <span>
                          {new Date(item.created_at).toLocaleDateString("en-us", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })}
                        </span> */}

                      {/* <span>{item.created_at.split(" ")[1]}</span> */}
                      <span>{item.created_at}</span>
                    </p>
                    <div className={style["content"]}>
                      <div className={style["icon"]}>
                        <img src={favorite} alt="favorite-icon" />
                      </div>
                      <p>
                        {item?.purchase_source === "paystack" ||
                        item?.purchase_source === " bank transfer"
                          ? `You topped up your wallet with  ${item?.airtime_units} units`
                          : item?.description}
                      </p>
                    </div>
                  </div>
                );
              }
            })}
            {loading && (pageNumber === 1 || pageNumber >= 3) && (
              <div className={style["loader"]}>
                <ScaleLoader color="red" />
              </div>
            )}
          </div>
        </div>
      </MessageLayout>
    </>
  );
};

export default Notification;
