import React from "react";
import style from "./index.module.scss";
import quoteIcon from "../../../assets/landingpage-icons/quote.svg";
import david from "../../../assets/david.png";
import jane from "../../../assets/jane.svg";
import victoria from "../../../assets/victoria.png";

const Testimonial = () => {
  return (
    <div className={style["testimonial--container"]}>
      <div className={style["testimonial--container__header"]}>
        <h3>Testimonials</h3>
        <p>Customer reviews? We got ‘em</p>
      </div>

      <div className={style["testimonial--container__lists"]}>
        {items.map((item, index) => (
          <div className={style["item"]} key={index}>
            <div>
              {" "}
              <img src={quoteIcon} alt="quote-icon" />
              <p>{item.text}</p>
            </div>

            <div className={style["customer--info"]}>
              <img
                src={item.image}
                alt={item.name}
                className={style["image"]}
              />
              <div className={style["details"]}>
                <p className={style["name"]}>{item.name}</p>
                <p className={style["role"]}>{item.role}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonial;

const items = [
  {
    text: "The product is really flexible. In cases where there were mistakes, I could easily perform actions again and again. That’s what I like the most about it.",
    name: "David",
    role: "Software Engineer",
    image: david,
  },
  {
    text: "This product is uniquely Nigerian. I was shocked that it pronounced native Nigerian names very well. It even got my difficult nickname.",
    name: "Jane",
    role: "Business Executive",
    image: jane,
  },

  {
    text: "The overall user interface and experience is really good.",
    name: "Victoria",
    role: "Product Manager",
    image: victoria,
  },
];
