import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";

export default function DatePickerField({ name, value, ...props }) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField({ name, value });
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(value && new Date(value)) || null}
      onChange={(val) => {
        setFieldValue(name, val);
      }}
    />
  );
}
