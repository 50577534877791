import { css } from "@emotion/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import Button from "../../../components/Button";
import { FormRowGroup } from "../../../components/Form-row";
import MessageLayout from "../../../components/Layout/MessageLayout";
import style from "./index.module.scss";
import * as phonebookActions from "../../../redux/actions/createPhonebookActions";
import { clearErrorMessage } from "../../../redux/actions/messageActions";
import AuthLayout from "../../../components/Layout/AuthLayout";
import successIcon from "../../../assets/icons/success.svg";
import * as Yup from "yup";

const AddContact = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.authState);
  const isSending = useSelector((state) => state.creatingPhonebook.isSending);
  const notification = useSelector((state) => state.message);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;
  const initialValues = {
    contact_name: "",
    msisdns: "",
    client_id: userInfo?.id,
    token: userInfo?.token,
  };

  const contactSchema = Yup.object().shape({
    contact_name: Yup.string().required("Contact name is required"),
    msisdns: Yup.string().required("Phone number is  required"),
  });

  return (
    <>
      {notification.success ? (
        <SuccessPage />
      ) : (
        <MessageLayout goBack={() => navigate(-1)}>
          <div className={style["add-contact--container"]}>
            <h3>Add a contact</h3>
            <div className={style["add-contact--container__form"]}>
              <ul className="signup__errors">
                {notification.error && (
                  <li>{notification?.msg?.payload?.msg?.error}</li>
                )}
              </ul>
              <Formik
                initialValues={initialValues}
                validationSchema={contactSchema}
                onSubmit={(values) => {
                  const formData = new FormData();
                  //   formData.append("name", values.name || name);
                  formData.append("msisdns", values.msisdns);
                  formData.append("client_id", values.client_id);
                  formData.append("token", values.token);
                  formData.append("contact_name", values.contact_name);

                  dispatch(
                    phonebookActions.updateSingleOrBatchPhonebook(formData, id)
                  );
                }}
              >
                {({ values }) => (
                  <Form>
                    <>
                      <FormRowGroup>
                        <label htmlFor="contact_name">Contact name </label>

                        <Field
                          id="contact_name"
                          name="contact_name"
                          placeholder="e.g Peter Pan"
                        />

                        <ErrorMessage
                          name="contact_name"
                          className="error"
                          component="span"
                        />
                      </FormRowGroup>
                      <FormRowGroup>
                        <label htmlFor="msisdns">Contact number </label>

                        <Field
                          id="msisdns"
                          name="msisdns"
                          placeholder="e.g 0810 234 5678"
                          type="tel"
                        />

                        <ErrorMessage
                          name="msisdns"
                          className="error"
                          component="span"
                        />
                      </FormRowGroup>
                    </>

                    <div className={style["button"]}>
                      <Button
                        type="submit"
                        className={
                          values.contact_name && values.msisdns
                            ? "filled"
                            : "faint"
                        }
                        disabled={isSending}
                      >
                        {!isSending ? (
                          " Add Contact"
                        ) : (
                          <ScaleLoader
                            css={override}
                            size={80}
                            color={"#fff"}
                            loading={true}
                          />
                        )}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </MessageLayout>
      )}
    </>
  );
};

const SuccessPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = () => {
    dispatch(clearErrorMessage());
    navigate(-1);
  };
  return (
    <AuthLayout>
      <div className={style["success--container"]}>
        {" "}
        <div className={style["success--container__icon"]}>
          <img src={successIcon} alt="gift" />
        </div>
        <div className={style["success--container__content"]}>
          <h3>Contact added</h3>
          <p>
            You’ve just added contact to your phonebook and your contact will be
            available now.
          </p>
        </div>
        <div className={style["success--container__button"]}>
          <Button className="filled" onClick={() => handleNavigate()}>
            Okay, got it
          </Button>
        </div>
      </div>
    </AuthLayout>
  );
};

export default AddContact;
