import React from "react";
import style from "./index.module.scss";

const PageLayout = ({ children }) => {
  return (
    <div className={style["layout--container"]}>
      <main>{children}</main>
    </div>
  );
};

export default PageLayout;
