/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Layout from "../../components/Layout/DashboardLayout";
import style from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearErrorMessage } from "../../redux/actions/messageActions";
import dashboard from "../../assets/images/dashboard.svg";
import debitIcon from "../../assets/icons/debit.svg";
import send from "../../assets/icons/send.svg";
import plus from "../../assets/icons/add-red.svg";
import Button from "../../components/Button";
import PullToRefresh from "react-simple-pull-to-refresh";
import { ScaleLoader } from "react-spinners";
import EmptyState from "../../components/Empty-state";
import useScrollPagination from "../../utils/useScrollPagination";
import { getClientInfo } from "../../redux/actions/authActions";
import ReactGA from "react-ga";
import { Dialog } from "../../components/Modal";
import failedCalledIcon from "../../assets/icons/failed-call.svg";
import successCalledIcon from "../../assets/icons/successful-call.svg";
import retryCalledIcon from "../../assets/icons/retry-call.svg";
import giftSentIcon from "../../assets/icons/gift-sent.svg";
import moment from "moment";

const Dashboard = () => {
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.auth.authState);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userInfo);

  React.useEffect(() => {
    dispatch(clearErrorMessage());
  });

  React.useEffect(() => {
    const payload = {
      client_id: userDetails.id,
      token: userDetails.token,
    };
    dispatch(getClientInfo(payload));
  }, []);

  const [current, setCurrent] = React.useState(0);
  const [touchPosition, setTouchPosition] = React.useState(null);
  const [slideSide, setSlideSide] = React.useState("next");
  const [isOpen, setIsOpen] = React.useState(false);
  const [giftInfo, setGiftInfo] = React.useState({});

  const openGiftInfo = (data) => {
    setIsOpen(true);
    setGiftInfo(data);
    console.log(data);
  };

  const closeGiftInfo = () => {
    setIsOpen(false);
    setGiftInfo(null);
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const cards = [
    {
      image: dashboard,
    },
    {
      image: <Card userData={userData} />,
    },
  ];

  const next = () => {
    setSlideSide("prev");
    setCurrent(current === cards.length - 1 ? 0 : current + 1);
  };

  const prev = () => {
    setSlideSide("next");
    setCurrent(current === 0 ? cards.length - 1 : current - 1);
  };

  const delay = 5000;
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => prev(), delay);

    return () => {
      resetTimeout();
    };
  }, [current]);

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      next();
    }

    if (diff < -5) {
      prev();
    }

    setTouchPosition(null);
  };

  const topUp = () => {
    ReactGA.event({
      category: `${userData?.contact_person}`,
      action: "clicked on top-up",
    });
    dispatch(clearErrorMessage());
    navigate("/wallets/top-up");
  };

  const sendMessage = () => {
    ReactGA.event({
      category: `${userData?.contact_person}`,
      action: "clicked on send gift",
    });
    dispatch(clearErrorMessage());
    navigate("/gift");
  };
  const [pageNumber, setPageNumber] = React.useState(1);

  const { data, hasMore, loading } = useScrollPagination(
    `https://crm-api.myserviceagent.net/api/v1/gift/get-gifts?page=${pageNumber}`,
    pageNumber
  );

  const observer = React.useRef();

  const lastBookElementRef = React.useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  const refreshContent = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 500);
    }).then(console.log(data));
  };
  return (
    <Layout
      subText={"Who are you gifting today?"}
      pageTitle={`Welcome ${userDetails?.contact_person.split(" ")[0]}👋`}
    >
      <PullToRefresh
        onRefresh={() => refreshContent()}
        refreshingContent={
          <ScaleLoader size={80} color={"#eb2931"} loading={true} />
        }
      >
        <div className={style["dashboard--container"]}>
          <div className={style["dashboard--container__items"]}>
            <div className={style["item"]}>
              {cards.map((card, index) => (
                <div
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  key={index}
                >
                  <div
                    className={
                      index === current && slideSide === "next"
                        ? style["next"]
                        : index === current && slideSide === "prev"
                        ? style["prev"]
                        : style["inactive"]
                    }
                  >
                    {index === 0 ? (
                      <img src={card.image} alt="dashboard" key={index} />
                    ) : (
                      <>{card.image}</>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={style["dashboard--container__controls"]}>
            {cards.map((testimonial, index) => (
              <div
                // onClick={() => switcher(index)}
                className={
                  index === current
                    ? style["testimonial--control__active"]
                    : style["testimonial--control"]
                }
                key={index}
              ></div>
            ))}
          </div>
          <div className={style["dashboard--container__buttons"]}>
            <Button
              onClick={() => sendMessage()}
              type="button"
              className="medium--filled"
            >
              <span>
                <img src={send} alt="" />
              </span>
              <span> Send a Gift</span>
            </Button>
            <Button
              onClick={() => topUp()}
              type="button"
              className="medium--ghost"
            >
              <img src={plus} alt="" />
              <span> Top up</span>
            </Button>
          </div>
          <div className={style["dashboard--container__recent-activities"]}>
            <p className={style["title"]}>Recent Gifts</p>
            {!loading && !data.length && <EmptyState />}

            <div className={style["transaction--histories"]}>
              {data.map((history, index) => {
                if (data.length === index + 1) {
                  return (
                    <div
                      className={style["history"]}
                      key={index}
                      ref={lastBookElementRef}
                      onClick={() => openGiftInfo(history)}
                    >
                      <div className={style["icon"]}>
                        <img src={debitIcon} alt="credit" />
                      </div>
                      <div className={style["content"]}>
                        <div>
                          <h3>{history?.name}</h3>
                          <p>
                            {/* <span>
                              {" "}
                              {new Date(history.created_at).toLocaleDateString(
                                "en-us",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                }
                              )}
                            </span>
*/}
                            <span>
                              {moment(history.created_at).format("llll")}
                            </span>
                          </p>
                        </div>
                        <div
                          className={style["amount"]}
                          style={{
                            color:
                              history.jobstatus === "success"
                                ? "#000"
                                : "#15803d",
                          }}
                        >
                          {(history?.context_status === "success" ||
                            history?.context_status === "sent") && (
                            <img src={giftSentIcon} alt="status-icon" />
                          )}
                          {history?.context_status === "failed" &&
                            history?.status === "processed" && (
                              <img src={successCalledIcon} alt="status-icon" />
                            )}
                          {history?.context_status === "failed" &&
                            history?.status === "failed" && (
                              <img src={failedCalledIcon} alt="status-icon" />
                            )}
                          {history?.context_status === "new" &&
                            history?.status === "new" && (
                              // <img src={retryCalledIcon} alt="status-icon" />
                              <p>Ok</p>
                            )}
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={style["history"]}
                      key={index}
                      onClick={() => openGiftInfo(history)}
                    >
                      <div className={style["icon"]}>
                        <img src={debitIcon} alt="credit" />
                      </div>
                      <div className={style["content"]}>
                        <div>
                          <h3>{history?.name}</h3>
                          <p>
                            {/* <span>
                              {new Date(history.created_at).toLocaleDateString(
                                "en-us",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                }
                              )}
                            </span> */}

                            <span>
                              {" "}
                              {moment(history.created_at).format("llll")}
                            </span>
                          </p>
                        </div>
                        <div
                          className={style["amount"]}
                          style={{
                            color:
                              history.jobstatus === "success"
                                ? "#000"
                                : "#15803d",
                          }}
                        >
                          {(history?.context_status === "success" ||
                            history?.context_status === "sent") && (
                            <img src={giftSentIcon} alt="status-icon" />
                          )}
                          {history?.context_status === "failed" &&
                            history?.status === "processed" && (
                              <img src={successCalledIcon} alt="status-icon" />
                            )}
                          {history?.context_status === "failed" &&
                            history?.status === "failed" && (
                              <img src={failedCalledIcon} alt="status-icon" />
                            )}
                          {history?.context_status === "new" &&
                            history?.status === "new" && (
                              <img src={retryCalledIcon} alt="status-icon" />
                            )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
              {loading && (pageNumber === 1 || pageNumber >= 3) && (
                <div className={style["loader"]}>
                  <ScaleLoader color="red" />
                </div>
              )}
            </div>
          </div>
        </div>
      </PullToRefresh>

      <Dialog isOpen={isOpen} toggleModal={() => setIsOpen(false)}>
        <div className={style["info--modal"]}>
          <div className={style["info--modal__content"]}>
            <div>
              <div className={style["summary"]}>
                <p className={style["title"]}>Gift Title</p>
                <p className={style["info"]}>{giftInfo?.name}</p>
              </div>
              <div className={style["summary"]}>
                <p className={style["title"]}>My Gift</p>
                <p className={style["info"]}>{giftInfo?.context_type}</p>
              </div>
              <div className={style["summary"]}>
                <p className={style["title"]}>Gift Cost</p>
                {giftInfo?.context_type === "data" ? (
                  <p className={style["info"]}>
                    {giftInfo?.plan_identifier?.split("_")[1] ?? "-"}
                    {` (${giftInfo?.gift_cost ?? "0"} Units)`}
                  </p>
                ) : (
                  <p className={style["info"]}>
                    {giftInfo?.gift_cost === null
                      ? "-"
                      : `₦${giftInfo?.gift_cost}`}{" "}
                    {`(${giftInfo?.gift_cost ?? "0"} Units)`}
                  </p>
                )}
              </div>
              {giftInfo?.context_type !== "charity" && (
                <>
                  <div className={style["summary"]}>
                    <p className={style["title"]}>Duration</p>
                    <p className={style["info"]}>
                      {giftInfo?.duration_in_seconds === null
                        ? 0
                        : giftInfo?.duration_in_seconds}{" "}
                      seconds
                    </p>
                  </div>

                  <div className={style["summary"]}>
                    <p className={style["title"]}>Call Status</p>
                    <p className={style[`${giftInfo?.status}`]}>
                      {giftInfo?.status === "new"
                        ? "Pending"
                        : giftInfo?.status === "processed"
                        ? "Success"
                        : giftInfo?.status}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div>
              <div className={style["summary"]}>
                <p className={style["title"]}>Recipient</p>
                <p className={style["info"]}>{giftInfo?.msisdn}</p>
              </div>
              {giftInfo?.context_type !== "charity" && (
                <>
                  {" "}
                  <div className={style["summary"]}>
                    <p className={style["title"]}>Format</p>
                    <p className={style["info"]}>
                      {giftInfo?.message_audio
                        ? "Voice"
                        : giftInfo?.message_text
                        ? "Text"
                        : ""}
                    </p>
                  </div>
                  <div className={style["summary"]}>
                    <p className={style["title"]}>Voice Call Cost</p>
                    <p className={style["info"]}>
                      {giftInfo?.voice_cost} units
                    </p>
                  </div>{" "}
                </>
              )}
              <div className={style["summary"]}>
                <p className={style["title"]}>Total Units Charged</p>
                <p className={style["info"]}>{giftInfo?.cost} Units</p>
              </div>
              <div className={style["summary"]}>
                <p className={style["title"]}>Gift Status</p>
                <p className={style[`${giftInfo?.context_status}`]}>
                  {giftInfo?.context_status === "new"
                    ? "Pending"
                    : giftInfo?.context_status === "sent"
                    ? "Success"
                    : giftInfo?.context_status}
                </p>
              </div>
            </div>
          </div>
          <Button className="small--filled" onClick={closeGiftInfo}>
            Okay, got it
          </Button>
        </div>
      </Dialog>
    </Layout>
  );
};
const Card = ({ userData }) => {
  const isLoading = useSelector((state) => state.auth.isLoading);
  const hoursOfDay = new Date().getHours();
  const minute = new Date().getMinutes();
  const checkDayTime = (hour, min) => {
    if (hour >= 6 && min >= 59) {
      return true;
    }
    return false;
  };

  const [isDayTime] = React.useState(checkDayTime(hoursOfDay, minute));

  return (
    <div
      className={
        isDayTime ? style["wallet--card-day"] : style["wallet--card-night"]
      }
    >
      {isLoading ? (
        <ScaleLoader color="#fff" />
      ) : (
        <>
          {" "}
          <p>Total Units</p>
          <h2>{new Intl.NumberFormat().format(userData?.airtime_balance)}</h2>
          <p>₦{new Intl.NumberFormat().format(userData?.airtime_balance)}</p>
        </>
      )}
    </div>
  );
};

export default Dashboard;
