import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../Button";
import style from "./index.module.scss";
import burgerIconClose from "../../assets/icons/burger.svg";

const Header = () => {
  const navigate = useNavigate();
  const links = [
    {
      title: "How it works",
      url: "#how-it-works",
    },
    {
      title: "FAQs",
      url: "#faqs",
    },
    {
      title: "Contact Us",
      url: "/contact",
    },
  ];
  const navBarMenu = React.useRef(null);

  function toggleBurgerMenu() {
    if (
      !navBarMenu.current.style.display ||
      navBarMenu.current.style.display === "none"
    ) {
      navBarMenu.current.style.display = "block";
    } else {
      navBarMenu.current.style.display = "none";
    }
  }

  return (
    <div className={style["header--wrapper"]}>
      <div className={style["header--container"]}>
        <div className={style["header--container__logo"]}>
          <Link to={"/"}>
            <p>
              <span>my</span>gift
            </p>
          </Link>
          <img
            src={burgerIconClose}
            alt="burger-icon"
            className={style["burger--icon"]}
            onClick={() => toggleBurgerMenu()}
          />
        </div>
        <div className={style["header--container__navbar"]} ref={navBarMenu}>
          <div className={style["nav--links"]}>
            {links.map((item, index) =>
              item.url === "/contact" ? (
                <Link to={item.url} key={index}>
                  {item.title}
                </Link>
              ) : (
                <a href={item.url} key={index}>
                  {item.title}
                </a>
              )
            )}
          </div>

          <div className={style["auth--links"]}>
            <Link to={"/login"}>Login</Link>
            <Button
              className="medium--filled"
              type="button"
              onClick={() => navigate("/sign-up")}
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
