import React from "react";
import MessageLayout from "../../components/Layout/MessageLayout";
import style from "./index.module.scss";
import phone from "../../assets/icons/phone.svg";
import wifi from "../../assets/icons/wifi.svg";
import charity from "../../assets/icons/charity.svg";
import money from "../../assets/icons/money.svg";
import rightAngle from "../../assets/icons/rightAngle.svg";
import { useNavigate } from "react-router-dom";

const SendGift = () => {
  const giftTypes = [
    {
      text: "Airtime",
      icon: phone,
      type: "airtime",
    },
    {
      text: "Data",
      icon: wifi,
      type: "data",
    },
    {
      text: "Donate to charity organisation",
      icon: charity,
      type: "donate",
    },
    {
      text: "Money",
      icon: money,
      type: "cash",
    },
  ];

  const [showError, setShowError] = React.useState(false);
  const navigate = useNavigate();

  const handleNavigate = (type) => {
    if (type === "donate" || type === "cash") {
      navigate(`/${type}`);
      return;
    } else if (type === "airtime") {
      navigate(`/gift/${type}`);
      return;
    }
    setShowError(true);
  };
  return (
    <MessageLayout goBack={() => navigate(-1)}>
      <div className={style["gift-type--container"]}>
        <div className={style["gift-type--container__type"]}>
          <h3>What do you want to gift?</h3>
          <p>Select from the options below</p>

          <div className={style["gift--types"]}>
            {giftTypes
              .filter((item) => item.type !== "donate")
              .map((gift, index) => (
                <div
                  className={style["gift--types__item"]}
                  key={index}
                  onClick={() => handleNavigate(gift.type)}
                >
                  <div>
                    <div
                      className={
                        index === 0 || index === 3
                          ? style["first-icon"]
                          : style["last-icon"]
                      }
                    >
                      <img src={gift.icon} alt={gift.text} />
                    </div>
                    <p>{gift.text}</p>
                  </div>

                  <div className={style["right--angle"]}>
                    <img src={rightAngle} alt="right--angle" />
                  </div>
                </div>
              ))}

            <p className={style["divider"]}>or</p>
            {giftTypes
              .filter((item) => item.type === "donate")
              .map((gift, index) => (
                <div
                  className={style["gift--types__item"]}
                  key={index}
                  onClick={() => handleNavigate(gift.type)}
                >
                  <div>
                    <div
                      className={
                        index === 0 || index === 3
                          ? style["first-icon"]
                          : style["last-icon"]
                      }
                    >
                      <img src={gift.icon} alt={gift.text} />
                    </div>
                    <p>{gift.text}</p>
                  </div>

                  <div className={style["right--angle"]}>
                    <img src={rightAngle} alt="right--angle" />
                  </div>
                </div>
              ))}
          </div>

          {showError && (
            <ul className={"signup__errors"}>
              <li>Data will be available soon</li>
            </ul>
          )}
        </div>
      </div>
    </MessageLayout>
  );
};

export default SendGift;
