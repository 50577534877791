// AUTH ACTIONS

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const LOAD_AUTH_USER_START = "LOAD_AUTH_USER_START";
export const LOAD_AUTH_USER_SUCCESS = "LOAD_AUTH_USER_SUCCESS";

export const ADD_USER = "ADD_USER";
export const ADD_USER_START = "ADD_USER_START";
export const ADD_USER_FAILED = "ADD_USER_FAILED";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";

export const VERIFY_USER_START = "VERIFY_USER_START";
export const VERIFY_USER_FAILED = "VERIFY_USER_FAILED";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";

export const ADD_BUSINESS_INFO_START = "ADD_BUSINESS_INFO_START";
export const ADD_BUSINESS_INFO_FAILED = "ADD_BUSINESS_INFO_FAILED";
export const ADD_BUSINESS_INFO_SUCCESS = "ADD_BUSINESS_INFO_SUCCESS";

export const UPDATE_BUSINESS_INFO_START = "UPDATE_BUSINESS_INFO_START";
export const UPDATE_BUSINESS_INFO_FAIL = "UPDATE_BUSINESS_INFO_FAIL";
export const UPDATE_BUSINESS_INFO_SUCCESS = "UPDATE_BUSINESS_INFO_SUCCESS";

export const RESEND_VERIFICATION_START = "RESEND_VERIFICATION_START";
export const RESEND_VERIFICATION_SUCCESFUL = "RESEND_VERIFICATION_SUCCESFUL";
export const RESEND_VERIFICATION_FAILED = "RESEND_VERIFICATION_FAILED";

export const PASSWORD_RESET_START = "PASSWORD_RESET_START";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";

export const GET_CLIENT_INFO_START = "GET_CLIENT_INFO_START";
export const GET_CLIENT_INFO_SUCCESS = "GET_CLIENT_INFO_SUCCESS";
export const GET_CLIENT_INFO_FAIL = "GET_CLIENT_INFO_FAIL";

//USER ACTIONS

export const USER_START = "USER_START";
export const USER_FAILED = "USER_FAILED";
export const USER_SUCCESS = "USER_SUCCESS";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESFUL = "UPDATE_USER_SUCCESFUL";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

//PASSWORD RECOVERY
export const PASSWORD_RECOVERY_START = "PASSWORD_RECOVERY_START";
export const PASSWORD_RECOVERY_FAIL = "PASSWORD_RECOVERY_FAIL";
export const PASSWORD_RECOVERY_SUCCESS = "PASSWORD_RECOVERY_SUCCESS";

//OTP ACTIONS

export const GET_OTP_START = "GET_OTP_START";
export const GET_OTP_SUCCESS = "GET_OTP_SUCCESS";
export const GET_OTP_FAIL = "GET_OTP_FAIL";

//PAYMENT ACTIONS

export const MAKE_PAYMENT_START = "MAKE_PAYMENT_START";
export const MAKE_PAYMENT_SUCCESS = "MAKE_PAYMENT_SUCCESS";
export const MAKE_PAYMENT_FAIL = "MAKE_PAYMENT_FAIL";

export const BANK_TRANSFER_UPLOAD_START = "BANK_TRANSFER_UPLOAD_START";
export const BANK_TRANSFER_UPLOAD_SUCCESS = "BANK_TRANSFER_UPLOAD_SUCCESS";
export const BANK_TRANSFER_UPLOAD_FAIL = "BANK_TRANSFER_UPLOAD_FAIL";

export const GET_PURCHASE_HISTORY_START = "GET_PURCHASE_HISTORY_START";
export const GET_PURCHASE_HISTORY_SUCCESS = "GET_PURCHASE_HISTORY_SUCCESS";
export const GET_PURCHASE_HISTORY_FAIL = "GET_PURCHASE_HISTORY_FAIL";

export const GET_HISTORY_BY_DATE_START = "GET_HISTORY_BY_DATE_START";
export const GET_HISTORY_BY_DATE_SUCCESS = "GET_HISTORY_BY_DATE_SUCCESS";
export const GET_HISTORY_BY_DATE_FAIL = "GET_HISTORY_BY_DATE_FAIL";

export const GET_BANK_PAYMENTS_START = "GET_BANK_PAYMENTS_START";
export const GET_BANK_PAYMENTS_SUCCESS = "GET_BANK_PAYMENTS_SUCCESS";
export const GET_BANK_PAYMENTS_FAIL = "GET_BANK_PAYMENTS_FAIL";

export const GET_PURCHASE_SUMMARY_START = "GET_PURCHASE_SUMMARY_START";
export const GET_PURCHASE_SUMMARY_SUCCESS = "GET_PURCHASE_SUMMARY_SUCCESS";
export const GET_PURCHASE_SUMMARY_FAIL = "GET_PURCHASE_SUMMARY_FAIL";

export const VERIFY_BANK_PAYMENT_START = "VERIFY_BANK_PAYMENT_START";
export const VERIFY_BANK_PAYMENT_SUCCESS = "VERIFY_BANK_PAYMENT_SUCCESS";
export const VERIFY_BANK_PAYMENT_FAIL = "VERIFY_BANK_PAYMENT_FAIL";

//MESSAGE ACTIONS
export const SEND_GIFT_START = "SEND_GIFT_START";
export const SEND_GIFT_SUCCESS = "SEND_GIFT_SUCCESS";
export const SEND_GIFT_FAIL = "SEND_GIFT_FAIL";

export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_SUCCESS = "GET_SUCCESS";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const CREATE_PHONEBOOK_START = "CREATE_PHONEBOOK_START ";
export const CREATE_PHONEBOOK_SUCCESS = "CREATE_PHONEBOOK_SUCCESS";
export const CREATE_PHONEBOOK_FAIL = "CREATE_PHONEBOOK_FAIL";

export const GET_BLOG_START = "GET_BLOG_START";
export const GET_BLOG_SUCCESS = "GET_BLOG_SUCCESS";
export const GET_BLOG_FAIL = "GET_BLOG_FAIL";

export const GET_BANKS = "GET_BANKS";
export const GET_BANKS_SUCCESS = "GET_BANKS_SUCCESS";
export const GET_BANKS_FAIL = "GET_BANKS_FAIL";

export const GET_CHARITY = "GET_CHARITY";
export const GET_CHARITY_SUCCESS = "GET_CHARITY_SUCCESS";
export const GET_CHARITY_FAIL = "GET_CHARITY_FAIL";
