export const AUTH_URL = '/auth/dev'
export const ADMIN_AUTH_URL = '/auth'
export const API_URL = '/api-key'
export const PASSWORD_RESET = '/auth/dev/password'
export const PASSWORD_UPDATE = '/auth/dev/password'
export const RESEND_VERIFICATION = 'https://crm-api.myserviceagent.net/api/v1/auth/dev/verification'
export const PAYMENTS_URL = '/purchase/dev'
export const PURCHASE_URL = '/purchase'

// export const LOGIN_URL = '/auth/dev'
// export const LOGOUT_URL = '/au/dev'
