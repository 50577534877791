import React from "react";
import PageLayout from "../../components/Layout/PageLayout";
import Banner from "./Components/Banner";
import Faqs from "./Components/Faqs";
import Gifts from "./Components/Gifts";
import Hero from "./Components/Hero";
import How from "./Components/How";
import Testimonial from "./Components/Testimonial";
import { WhyMyGift } from "./Components/WhyMyGift";
import style from "./index.module.scss";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const LandingPage = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  React.useEffect(() => {
    if (auth.isAuth) {
      navigate("/dashboard");
    }
  }, [auth.isAuth, navigate]);

  return (
    <PageLayout>
      <Header />
      <div className={style["landingpage--container"]}>
        <Hero />
        <How />
        <Gifts />
        <WhyMyGift />
        <Faqs />
        <Testimonial />
        <Banner />
      </div>
      <Footer />
    </PageLayout>
  );
};

export default LandingPage;
